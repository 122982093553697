import React, { Component } from 'react';
import {inject, observer} from 'mobx-react/index';

import ChatBot from 'react-simple-chatbot';
import COLOURS from '../../constants/colors'

import './styles.scss';

@inject('store')
@observer
class ChatComponent extends Component {
	render () {
		const botBubbleOptions = {
			background: COLOURS.red,
			color: 'white',
			fontFamily: 'Radikal Bold, sans-serif',
			fontWeight: 'bold',
			fontSize: '110%'
		}
		
		return (
			<ChatBot
				className={'chat-component'}
				width={'100%'}
				height={window.innerWidth / 16 * 9 + 'px'}
				userAvatar={require('./imgs/avatar.svg')}
				hideUserAvatar
				headerTitle={'Probleme im globalen Agrarhandel'}
				botAvatar={require('./imgs/chatbot.svg')}
				bubbleStyle={{
					...botBubbleOptions,
					fontFamily: 'Radikal Bold, sans-serif',
					fontWeight: 'bold',
				}}
				userBubbleColor={COLOURS.blue}
				botDealy={1500}
				bubbleOptionStyle={{
					background: COLOURS.blue
				}}
				hideSubmitButton
				enableSmoothScroll
				enableMobileAutoFocus
				footerStyle={{
					display: 'none'
				}}
				hideHeader
				steps={[
					{
						id: '1',
						message: 'Durch die vertikale Integration von den grossen globalen Agrarhändlern entstehen verschiedene Probleme. Worüber möchten Sie mehr erfahren?',
						trigger: '2',
					},
					{
						id: '2',
						options: [
							{ value: 1, label: 'Existenzlohn', trigger: '10' },
							{ value: 2, label: 'Kinderarbeit', trigger: '20' },
							{ value: 3, label: 'Zwangsarbeit', trigger: '30' },
						],
					},
					{
						/* EXISTENZLOHN */
						id: '10',
						message: 'Ein existenzsichernder Lohn ist ein international anerkanntes Menschenrecht.',
						trigger: '11'
					},
					{
						id: '11',
						asMessage: true,
						component: (
							<ImageBubble photo={'existenzlohn.jpg'} />
						),
						trigger: '12'
					},
					{
						id: '12',
						message: 'Viele Millionen Menschen, die in der Landwirtschaft arbeiten, verdienen nicht genug, um der eigenen Familie die Existenz zu sichern. In vielen Ländern der Welt gehören Landarbeitende zu den Gruppen mit der höchsten Armutsrate.',
						trigger: '100'
					},
					{
						/* KINDERARBEIT */
						id: '20',
						message: 'Kinderarbeit ist ein weit verbreiteter Verstoss, der regelmässig in der Landwirtschaft zu beobachten ist.',
						trigger: '21',
					},
					{
						id: '21',
						asMessage: true,
						component: (
							<ImageBubble photo={'kinderarbeit.jpg'} />
						),
						trigger: '22'
					},
					{
						id: '22',
						message: 'Kinderarbeit bezeichnet nicht – wie manchmal falsch dargestellt – wenn Kinder ihren Eltern gelegentlich in Familienbetrieben helfen, sondern schädliche Formen der Kinderarbeit, die nach den internationalen Menschenrechtsnormen verboten sind.',
						trigger: '23',
					},
					{
						id: '23',
						message: 'Mit fast 108 Millionen arbeitenden Kindern zeichnet der Agrarsektor für 71% der Kinderarbeit weltweit verantwortlich.',
						trigger: '100',
					},
					{
						/* ZWANGSARBEIT */
						id: '30',
						message: 'Zwangsarbeit ist die häufigste Form der modernen Sklaverei.',
						trigger: '31',
					},
					{
						id: '31',
						asMessage: true,
						component: (
							<ImageBubble photo={'zwangsarbeit.jpg'} />
						),
						trigger: '32'
					},
					{
						id: '32',
						message: 'In der Land-, und Forstwirtschaft sowie der Fischerei arbeiten weltweit über 3,5 Millionen Menschen unter sklavenähnlichen Bedingungen.',
						trigger: '100',
					},
					{
						id: '100',
						message: 'Darf ich Ihnen sonst noch etwas erzählen?',
						trigger: '2'
					},
				]}
			/>
		)
	}
}

class ImageBubble extends Component {
	render () {
		return (
			/*<div className="rsc-ts rsc-ts-bot">
				<div className="rsc-ts-image-container sc-htoDjs vmYlS">
					<img className="rsc-ts-image sc-gzVnrw cwuCQv"
					     src="/static/media/chatbot.a6242f9e.svg"
					     alt="avatar" />
				</div>
				<div className="rsc-ts-bubble sc-bZQynM hQsUiY"
				     style={{background: 'rgb(255, 0, 64)', color: 'white', fontFamily: 'Radikal Bold, sans-serif', fontWeight: 'bold', fontSize: '110%'}}>
					<img alt='icon' width={'100%'} src="https://embed.publiceye.ch/photos/existenzlohn.jpg" />
				</div>
			</div>*/
			<a href={process.env.PUBLIC_URL + '/photos/' + this.props.photo} target={'_blank'}><img width={'100%'} alt='Antwort mit Bild' src={process.env.PUBLIC_URL + '/photos/' + this.props.photo} /></a>
		)
	}
}

export default ChatComponent
