import React, { useState } from 'react'
import {useSpring, animated} from 'react-spring'

import '../../scss/main.scss'
import './styles.scss'

const AutosizeComponent = () => {
	const [value, setValue] =   useState(50)
	const props = useSpring({ to: { height: value } } )
	const heightPlus = () => setValue(value + 50)
	const heightMinus = () => setValue(value >= 100 ? value - 50 : 50)
	const heightRandom = () => setValue(Math.floor(Math.random() * (800 - 50)) + 50 )
	
	return (
		<div id={'autosize'} data-iframe-height>
			<div className={'ui container'}>
				<div className={'buttons'}>
					<button
						className={'Button white'}
						onClick={heightMinus}
					>
						<i className='icon minus' />50px
					</button>
					<button
						className={'Button white'}
						onClick={heightPlus}
					>
						<i className='icon plus' />50px
					</button>
					<button
						className={'Button'}
						onClick={heightRandom}
					>
						<i className={'icon random'} />Zufall
					</button>
				</div>
				<animated.div
					className={'resizeable'}
					style={{
						height: props.height
					}}
				>
					<p className={'size-teller'}><i className={'icon arrows alternate vertical\n'} />{ value }px</p>
				</animated.div>
			</div>
		</div>
	)
}
export default AutosizeComponent;

