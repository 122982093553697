import StartComponent from '../components/start'
import StorylineComponent from '../components/storyline'
import AutosizeComponent from '../components/autosize'
import MapComponent from '../components/map'
import ChatComponent from '../components/chat'
import FlipcardsComponent from '../components/flipcards'
import RedirectComponent from '../components/redirect'
// import RedboxComponent from '../components/redbox'
// import GlobeComponent from '../components/globe'
// import ExampleComponent from '../components/example'
// import Box2dComponent from '../components/box2d'

const ROUTES = [
	{
		path: '/start',
		component: StartComponent,
		title: 'Start',
		link: '/',
		isExact: true,
		isVisible: true
	},
	{
		path: '/autosize',
		component: AutosizeComponent,
		link: '/autosize',
		title: 'Autosize Test',
		isExact: true,
		isVisible: true
	},
	{
		path: '/storyline/:language/:chapter?/:page?/:element?',
		link: '/storyline/de',
		component: StorylineComponent,
		title: 'Story',
		isExact: true,
		isVisible: true
	},
	{
		path: '/agroquiz/:language/:chapter?/:page?/:element?',
		link: '/agroquiz/de',
		component: StorylineComponent,
		title: 'Agro Quiz',
		isExact: true,
		isVisible: true
	},
	{
		path: '/map',
		link: '/map',
		component: MapComponent,
		title: 'Map',
		isExact: true,
		isVisible: true
	},
	{
		path: '/chat',
		link: '/chat',
		component: ChatComponent,
		title: 'Chat',
		isExact: false,
		isVisible: true
	},
	{
		path: '/flipcards',
		link: '/flipcards',
		component: FlipcardsComponent,
		title: 'Flipcards',
		isExact: true,
		isVisible: true
	},
	{
		path: '/:commodity?/:language?',
		link: '/redirect',
		component: RedirectComponent,
		title: 'Redirect',
		isExact: true,
		isVisible: true
	}
]

export default ROUTES
