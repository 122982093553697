// CONFIG FOR SOCIAL MEDIA SHARING
const SOCIAL_MEDIA_CONFIG = {
	og: {
		url: {
			de: 'https://embed.publiceye.ch/agroquiz-de',
			fr: 'https://embed.publiceye.ch/agroquiz-fr',
			en: 'https://embed.publiceye.ch/agroquiz-en',
		},
		title: {
			de: 'Public Eye | Agroquiz: Die Schweiz und der Agrarhandel',
			fr: 'Public Eye | Agroquiz: la Suisse et le négoce agricole',
			en: 'Public Eye | Agroquiz: Switzerland & agricultural trade',
		},
		description: {
			de: 'Lassen Sie sich überraschen und testen Sie hier Ihr Wissen: Welche Rolle spielt die Schweiz im globalen Agrarhandel?',
			fr: 'Testez vos connaissances: quel rôle joue la Suisse dans le négoce mondial de matières premières agricoles?',
			en: 'Challenge your knowledge about Switzerland’s role in the global agricultural commodity trade!',
		},
		image: {
			de: 'https://embed.publiceye.ch/share-image-og-assets/share-image.jpg',
			fr: 'https://embed.publiceye.ch/share-image-og-assets/share-image.jpg',
			en: 'https://embed.publiceye.ch/share-image-og-assets/share-image.jpg',
		},
	},
	twitter: {
		title: {
			de: 'Public Eye | Agroquiz: Die Schweiz und der Agrarhandel',
			fr: 'Public Eye | Agroquiz: la Suisse et le négoce agricole',
			en: 'Public Eye | Agroquiz: Switzerland & agricultural trade',
		},
		description: {
			de: 'Lassen Sie sich überraschen und testen Sie hier Ihr Wissen: Welche Rolle spielt die Schweiz im globalen Agrarhandel?',
			fr: 'Testez vos connaissances: quel rôle joue la Suisse dans le négoce mondial de matières premières agricoles?',
			en: 'Challenge your knowledge about Switzerland’s role in the global agricultural commodity trade!',
		},
		image: {
			de: 'https://embed.publiceye.ch/share-image-assets/share-image.jpg',
			fr: 'https://embed.publiceye.ch/share-image-assets/share-image.jpg',
			en: 'https://embed.publiceye.ch/share-image-assets/share-image.jpg',
		},
		handle: {
			de: 'publiceye_ch',
			fr: 'PublicEyeSuisse',
			en: 'publiceye_ch',
		},
	}
}

export default SOCIAL_MEDIA_CONFIG
