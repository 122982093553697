const COMMODITIES = [
	{
		id: 0,
		name: {
			de: 'Kaffee',
			en: 'Coffee',
			fr: 'Café '
		},
		text: {
			de: 'Kaffee',
			en: 'coffee',
			fr: 'café'
		},
		icon: 'coffee.png',
		visible: true
	},
	{
		id: 1,
		name: {
			de: 'Getreide',
			en: 'Grains',
			fr: 'Céréales'
		},
		text: {
			de: 'Getreide',
			en: 'grain',
			fr: 'céréales'
		},
		icon: 'grains.png',
		visible: true
	},
	{
		id: 2,
		name: {
			de: 'Zucker',
			en: 'Sugar',
			fr: 'Sucre'
		},
		text: {
			de: 'Zucker',
			en: 'sugar',
			fr: 'sucre'
		},
		icon: 'sugar.png',
		visible: true
	},
	{
		id: 3,
		name: {
			de: 'Kakao',
			en: 'Cocoa',
			fr: 'Cacao'
		},
		text: {
			de: 'Kakao',
			en: 'cocoa',
			fr: 'cacao'
		},
		icon: 'cocoa.png',
		visible: true
	},
	{
		id: 4,
		name: {
			de: 'Baumwolle',
			en: 'Cotton',
			fr: 'Coton'
		},
		text: {
			de: 'Baumwolle',
			en: 'cotton',
			fr: 'coton'
		},
		icon: 'cotton.png',
		visible: true
	},
	{
		id: 5,
		name: {
			de: 'Soja',
			en: 'Soy',
			fr: 'Soja'
		},
		text: {
			de: 'Soja',
			en: 'soy',
			fr: 'soja'
		},
		icon: 'soy.png',
		visible: true
	},
	{
		id: 6,
		name: {
			de: 'Bananen',
			en: 'Bananas',
			fr: 'Bananes'
		},
		text: {
			de: 'Bananen',
			en: 'bananas',
			fr: 'bananes'
		},
		icon: 'banana.png',
		visible: true
	},
	{
		id: 7,
		name: {
			de: 'Orangensaft',
			en: 'Orange juice',
			fr: 'Jus d\'orange'
		},
		text: {
			de: 'Orangensaft',
			en: 'orange juice',
			fr: 'jus d\'orange'
		},
		icon: 'juice.png',
		visible: true
	},
	{
		id: 8,
		name: {
			de: 'Probleme',
			en: 'Problems',
			fr: 'Problèmes'
		},
		text: {
			de: 'Probleme',
			en: 'problems',
			fr: 'problèmes'
		},
		icon: 'soy.png',
		visible: false
	},
	{
		id: 9,
		name: {
			de: 'Finish',
			en: 'Finish',
			fr: 'Finish'
		},
		text: {
			de: 'Finish',
			en: 'Finish',
			fr: 'Finish'
		},
		icon: 'bananas.png',
		visible: false
	},
	{
		id: 9,
		name: {
			de: 'Finish',
			en: 'Finish',
			fr: 'Finish'
		},
		text: {
			de: 'Finish',
			en: 'Finish',
			fr: 'Finish'
		},
		icon: 'orangejuice.png',
		visible: false
	},
]

export default COMMODITIES























































































































































































































































































































































































































































































































































































































































































































































































































































