import React, { Component } from 'react';
import {inject, observer} from 'mobx-react/index';

import './styles.scss';
import renderHTML from "react-render-html"

@inject('store')
@observer
class TextComponent extends Component {
	render() {
		const {
			staticText,
			resultText,
			uiElement,
			mapResult,
			mapVotesCorrect,
			mapVotesWrong,
			sliderResult,
			sliderCorrectAnswer,
			sliderVotesWorseThanMe,
			sliderVotesTotal,
			gridResult,
			gridResultCorrectAnswers,
			gridResultsVotesEqualToMe,
			gridResultsVotesTotal,
			gridResultsVotesBetterThanMe,
			commodityTitle
		} = this.props
		
		let dynamicText = 'UNSET'

		// eslint-disable-next-line
		switch(uiElement) {
			case 'map':
				// Check map answer and generate result text
				// Richtig
				if (mapResult) {
					switch(this.props.store.language) {
						// Deutsch
						case 'de':
							dynamicText = '<span className="red">Richtig!</span> Insgesamt gingen bis jetzt ' + parseInt(mapVotesCorrect + 1) + ' richtige Tipps ein. ' + parseInt(mapVotesWrong + 1) + '  Personen lagen falsch.'
							break;
						// Französisch
						case 'fr':
							dynamicText = '<span className="red">Bien vu!</span> ' + parseInt(mapVotesCorrect + 1)+ ' personnes avant vous ont bien répondu. ' + parseInt(mapVotesWrong + 1) + ' se sont trompées.'
							break;
						// Englisch
						case 'en':
							dynamicText = '<span className="red">Well done!</span> ' + parseInt(mapVotesCorrect + 1) + ' people before you also knew the answer. ' + parseInt(mapVotesWrong  + 1) + ' people clicked on the wrong country.'
							break;
						default:
							dynamicText = staticText;
							break;
					}
				} else {
					// Falsch
					switch(this.props.store.language) {
						// Deutsch
						case 'de':
							dynamicText = '<span className="red">Knapp daneben.</span> Ihre und ' + parseInt(mapVotesWrong) + ' weitere Antworten waren leider falsch. ' + parseInt(mapVotesCorrect + 1) + ' Personen vor Ihnen wussten es.'
							break;
						// Französisch
						case 'fr':
							dynamicText = '<span className="red">Faux !</span> ' + parseInt(mapVotesWrong) + ' personnes avant vous se sont aussi trompées. ' + parseInt(mapVotesCorrect + 1) + ' personnes ont donné la bonne réponse.'
							break;
						// Englisch
						case 'en':
							dynamicText = '<span className="red">Not quite.</span> ' + parseInt(mapVotesWrong ) + ' people before you also clicked on the wrong country. ' + parseInt(mapVotesCorrect + 1) + ' people knew the answer.'
							break;
						default:
							dynamicText = staticText;
							break;
					}
				}
				break;
			case 'redbox':
				const wrongPeople = sliderVotesWorseThanMe === 0 ? 'alle ' + sliderVotesTotal + ' Personen vor Ihnen' : sliderVotesWorseThanMe + ' der ' + sliderVotesTotal + ' Personen vor Ihnen'
				if (sliderResult) {
					// Richtig
					switch(this.props.store.language) {
						// Deutsch
						case 'de':
							dynamicText = 'Exakt! Über die Schweiz werden <span className="red">mindestens ' + sliderCorrectAnswer + ' Prozent des globalen ' + commodityTitle + 'handels</span> abgewickelt. Sie haben genauer geschätzt als ' + wrongPeople +'.'
							break;
						// Französisch
						case 'fr':
							dynamicText = 'Bien vu! Au moins <span className="red">' + sliderCorrectAnswer + '% du négoce mondial de ' + commodityTitle + '</span> est réalisé via la Suisse. Votre estimation est meilleure que celle de ' + sliderVotesWorseThanMe + ' des ' + parseInt(sliderVotesTotal) + ' personnes qui ont répondu avant vous.'
							break;
						// Englisch
						case 'en':
							dynamicText = 'Exactly! Switzerland\'s <span className="red">market share in global ' + commodityTitle + ' trade is at least ' + sliderCorrectAnswer + '%</span>. You did better than ' + sliderVotesWorseThanMe + ' of the ' + parseInt(sliderVotesTotal) + ' people before you. '
							break;
						default:
							dynamicText = staticText;
							break;
					}
				} else {
					// Falsch
					switch(this.props.store.language) {
						// Deutsch
						case 'de':
							dynamicText = 'Über die Schweiz werden <span className="red">mindestens ' + sliderCorrectAnswer + ' Prozent</span> des globalen ' + commodityTitle + 'handels abgewickelt. Sie haben genauer geschätzt als ' + sliderVotesWorseThanMe + ' der ' + sliderVotesTotal + ' Personen vor Ihnen.'
							break;
						// Französisch
						case 'fr':
							dynamicText = 'Au moins <span className="red">' + sliderCorrectAnswer + '% du négoce mondial de ' + commodityTitle + '</span> est réalisé via la Suisse. Votre estimation est meilleure que celle de ' + sliderVotesWorseThanMe + ' des ' + sliderVotesTotal + ' personnes qui ont répondu avant vous.'
							break;
						// Englisch
						case 'en':
							dynamicText = 'Switzerland\'s <span className="red">market share in global ' + commodityTitle + ' trade is at least ' + sliderCorrectAnswer + '%</span>. You did better than ' + sliderVotesWorseThanMe + ' of the ' + parseInt(sliderVotesTotal + 1) + ' people before you. '
							break;
						default:
							dynamicText = staticText;
							break;
					}
				}
				break;
			case 'grid':
				if (!this.props.isFinishSlide) {
					console.log(gridResult)
					if (gridResult) {
						// Richtig
						// eslint-disable-next-line
						switch(this.props.store.language) {
							// Deutsch
							case 'de':
								const correctWordingDE = this.props.store.results.correctAnswerArray.length === 1 ? 'Firma handelt mit' : this.props.store.results.correctAnswerArray.length + ' Firmen handeln alle mit'
								dynamicText = '<span className="red">Sie haben es gewusst.</span> Diese ' + correctWordingDE + ' ' + commodityTitle + '. Insgesamt gingen bisher ' + parseInt(gridResultsVotesEqualToMe + 1) + ' richtige Tipps ein. ' + parseInt(gridResultsVotesTotal - gridResultsVotesBetterThanMe) + ' Personen lagen falsch.'
							// Französisch
								break;
							case 'fr':
								const correctWordingFR = this.props.store.results.correctAnswerArray.length === 1 ? 'Cette société est active' : 'Ces ' + this.props.store.results.correctAnswerArray.length + ' sociétes suivantes sont actives'
								dynamicText = '<span className="red">Bien vu!</span> ' + correctWordingFR + ' dans le négoce de ' + commodityTitle + '. ' + parseInt(gridResultsVotesEqualToMe + 1) + ' personnes avant vous ont bien répondu. ' + parseInt(gridResultsVotesTotal - gridResultsVotesBetterThanMe) + ' personnes se sont trompées.'
								break;
							// Englisch
							case 'en':
								const correctWordingEN = this.props.store.results.correctAnswerArray.length === 1 ? 'This company trades ' : 'These ' + this.props.store.results.correctAnswerArray.length + ' companies trade '
								dynamicText = '<span className="red">Well done!</span> ' + correctWordingEN + commodityTitle + '. You and ' + parseInt(gridResultsVotesEqualToMe + 1) + ' people before you also gave a correct answer. ' + parseInt(gridResultsVotesTotal - gridResultsVotesBetterThanMe) + ' of the given answers were wrong.'
								break;
						}
					} else {
						// Falsch
						// eslint-disable-next-line
						switch (this.props.store.language) {
							// Deutsch
							case 'de':
								const wrongWordingDE = this.props.store.results.correctAnswers === 1 ? 'Eine Firma haben Sie erraten.' : 'Knapp daneben.'
								const wrongWordingDEtwo = gridResultCorrectAnswers === 1 ? 'Nur eine der Firmen handelt' : 'Diese ' + gridResultCorrectAnswers + ' Firmen handeln'
								dynamicText = '<span className="red">' + wrongWordingDE + '</span> ' + wrongWordingDEtwo + ' mit ' + commodityTitle + '. ' + parseInt(gridResultsVotesTotal - gridResultsVotesBetterThanMe) + ' Antworten waren falsch. ' + gridResultsVotesBetterThanMe + ' Personen vor Ihnen wussten es besser.'
								break;
							// Französisch
							case 'fr':
								const wrongWordingFR = this.props.store.results.correctAnswers === 1 ? 'Bien vu:  vous avez donné une bonne réponse.' : 'Faux !'
								const wrongWordingFRtwo = gridResultCorrectAnswers === 1 ? 'Regardez bien: voici la société active ' : 'Ces ' + gridResultCorrectAnswers + ' sociétés sont actives '
								dynamicText = '<span className="red">' + wrongWordingFR + ' </span>' + wrongWordingFRtwo + ' dans le négoce de ' + commodityTitle + '. ' + parseInt(gridResultsVotesTotal - gridResultsVotesBetterThanMe) + ' autres personnes avant vous se sont trompées. ' + gridResultsVotesBetterThanMe + ' personnes ont donné la bonne réponse.'
								break;
							// Englisch
							case 'en':
								const wrongWordingEN = this.props.store.results.correctAnswers === 1 ? 'You guessed one company right.' : 'Not quite.'
								const wrongWordingENtwo = gridResultCorrectAnswers === 1 ? 'This company trades ' : 'These ' + gridResultCorrectAnswers + ' companies trade '
								dynamicText = '<span className="red">' + wrongWordingEN + '</span> ' + wrongWordingENtwo + ' ' + commodityTitle + '.</span> ' + parseInt(gridResultsVotesTotal - gridResultsVotesBetterThanMe) + ' people were wrong. ' + gridResultsVotesBetterThanMe + ' people answered correctly.'
								break;
						}
					}
				}
		}
		
		return (
			<div id='text-component' className={ `story-text ${uiElement}` }>
				{ staticText && !resultText
				?
					(
						<div
							className={`top-text ${staticText.length > 250 ? 'smaller' : ''} ${this.props.commodityTitle === 'Finish' || this.props.commodityTitle === 'Problems' ? 'smallest' : ''}`}
						>
							{staticText}
						</div>
					)
				:
					(
						<div
							className={`top-text ${dynamicText.length > 250 ? 'smaller' : ''}`}
						>
							{renderHTML(dynamicText)}
						</div>
					)
				}
			</div>
		)
	}
}

export default TextComponent
