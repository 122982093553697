import React, { Component } from 'react';
import {inject, observer} from 'mobx-react/index';
import renderHTML from 'react-render-html';

import './styles.scss';

@inject('store')
@observer
class HTMLContentComponent extends Component {
	constructor (props) {
		super(props)
		this.state = {
			textVisible: true
		}
		this.goToStart = this.goToStart.bind(this)
		this.goToProblems = this.goToProblems.bind(this)
	}
	
	goToStart () {
		this.props.store.goToChapter(0,0,0)
	}
	
	goFurther () {
		this.setState({
			textVisible: true
		})
		this.props.store.goFurther()
	}
	
	goToProblems () {
		this.props.store.goToChapter(8,0,0)
	}
	
	render() {
		const { nextButton, htmlText, backToProblemsButton } = this.props
		
		return (
			<div id={'html-content-component'}
	    >
		     <div
			     className={ 'controls' }
			     style={{
				     top: this.props.store.controlsPositionTop
			     }}
		     >
			     {backToProblemsButton
			     &&
			     <button
				     className={'Button'}
				     ref={'toProblems'}
				     onClick={() => this.goToProblems()}
			     >
				     <i className={'icon long arrow left'} /> {this.props.store.uiElements.backToProblems[this.props.store.language]}
			     </button>
			     }
			     {this.props.backToStartButton && !backToProblemsButton
			     &&
			     <button
				     className={'Button'}
				     ref={'toStart'}
				     onClick={this.props.store.goToStart}
			     >
				     <i className={'icon long arrow left'} /> {this.props.store.uiElements.backToStartButton[this.props.store.language]}
			     </button>
			     }
			     { !backToProblemsButton && nextButton
			     &&
			     <button className={ 'Button' }
			             onClick={ () => this.props.goToFinishSlide ? this.props.store.goToFinishSlide() : this.goFurther() }
			     >
				     { this.props.store.uiElements.nextButtonText[this.props.store.language] }&nbsp;
				     <i className={ 'icon long arrow right' }/>
			     </button>
			     }
		     </div>
				<div
					className={`html-content-container ${this.props.fadeIn ? 'bounce-in-top' : ''}`}
					style={{
						height: (this.props.store.GLOBAL_VALUES.canvas.height) - this.props.store.GLOBAL_VALUES.margin,
						width: (this.props.store.GLOBAL_VALUES.canvas.width) - (2 * this.props.store.GLOBAL_VALUES.margin),
						marginLeft: this.props.store.GLOBAL_VALUES.margin,
						backgroundImage: 'url(' + process.env.PUBLIC_URL + '/photos/' + this.props.photo + ')',
						backgroundSize: 'cover'
					}}
				>
					<div className={`middle-aligned-textbox ${this.state.textVisible || !this.props.photo ? '' : 'invisible'} ${htmlText.length > 500 ? 'hyphens' : ''}`}
				       onClick={ () => this.props.goToFinishSlide ? this.props.store.goToFinishSlide() : this.goFurther() }
					>
						{ renderHTML(htmlText) }
					</div>
					{this.props.photo && this.props.photo !== 'black.jpg'
					&&
					<div
						className={`ui top right attached small icon label red`}
						style={{
							zIndex: 999,
							backgroundColor: this.props.store.colours.red,
						}}
						onClick={() => this.setState({textVisible: !this.state.textVisible})}
					>
						<i className={'icon photo'}
						   style={{
						   	  margin: 0,
							    opacity: !this.state.textVisible ? 1 : 0.4
						   }}
						/>
						<i className={'icon align left'}
						   style={{
						   	 margin: 0,
							   marginLeft: '10px',
							   opacity: this.state.textVisible ? 1 : 0.4
						   }}
						/>
					</div>
					}
				</div>
				<a href={ 'https://www.publiceye.ch' }
				   target={ '_blank' }
				>
					<div
						className={ 'canvas-logo' }
						style={{bottom: '8px'}}
					/>
				</a>
			</div>
		)
	}
}

export default HTMLContentComponent
