import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

const prodConfig = {
	apiKey: process.env.REACT_APP_PROD_API_KEY,
	authDomain: process.env.REACT_APP_PROD_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_PROD_DATABASE_URL,
	projectId: process.env.REACT_APP_PROD_PROJECT_ID,
	storageBucket: process.env.REACT_APP_PROD_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_PROD_MESSAGING_SENDER_ID
}

const devConfig = {
	apiKey: process.env.REACT_APP_DEV_API_KEY,
	authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DEV_DATABASE_URL,
	projectId: process.env.REACT_APP_DEV_PROJECT_ID,
	storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID
}

const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig

class Firebase {
	constructor () {
		app.initializeApp(config)
		this.db = app.database()
	}
	
	// Gets whole database
	guesses = () => this.db.ref(`guesses`)
	
	// Gets actual guess
	guess = (chapter, page, element) => this.db.ref(`guesses/${chapter}/${page}/${element}`)
	
	// Add single guess to database in chapter
	doAddNewGuess = (journey, key, value, oldValues) => this.db.ref(`guesses/${journey.activeChapter}/${journey.activePage}/${journey.activeElement}/`).update({
		...oldValues,
		[key]: value
	})
	
	doAddNewSliderGuess = (journey, value) => this.db.ref(`guesses/${journey.activeChapter}/${journey.activePage}/${journey.activeElement}/`).push({
		value
	})
	
	doAddNewGridGuess = (journey, correct, total) => this.db.ref(`guesses/${journey.activeChapter}/${journey.activePage}/${journey.activeElement}/`).push({
		correct,
		total
	})
	
	doUpdateSingleVote = (id, updatedData) => this.db.ref(`votes/${id}`).update({
		title: updatedData.title,
		lastUpdate: new Date().getTime(),
		type: updatedData.type,
		description: updatedData.description,
		results: updatedData.results
	})
	
	// *** Elections API ***
	election = id => this.db.ref(`elections/${id}`)
	elections = () => this.db.ref(`elections`)
}

export default Firebase
