import ReactGA from 'react-ga';
import {observable, action, computed} from 'mobx';
import STORY from '../constants/story';
import COLOURS from "../constants/colors"
import TRADERS from '../constants/traders'
import COMMODITIES from '../constants/commodities'
import PROBLEMS from '../constants/problems'
import SOCIAL_MEDIA_CONFIG from '../constants/social'

class Store {
	// Observables
	@observable attractorStrength = 1e-5
	@observable mapVotesArray = []
	@observable journeyId = ''
	@observable iconInterval = null
	@observable textArray = []
	@observable gameStarted = false
	@observable social = SOCIAL_MEDIA_CONFIG
	@observable isSelected = false
	@observable story = STORY
	@observable language = 'de'
	@observable colours = COLOURS
	@observable journey = {
		activeChapter: 0,
		activePage: 0,
		activeElement: 0,
	}
	@observable db = []
	@observable results = []
	@observable gameOver = false
	@observable executedActions = []
	@observable allElements = []
	@observable guessPercent = 0
	@observable guessTraders = []
	@observable globalInterval = ''
	@observable buttonIsEnabled = true
	@observable currentResults = this.db.find(r => r[0] === this.journey.activeChapter)
	@observable GLOBAL_VALUES = {
		WIDTH: window.innerWidth >= 750 ? 750 : window.innerWidth,
		canvas: {
			height: (window.innerWidth > 750 ? 750 : window.innerWidth) / 16 * 9,
			width: window.innerWidth > 750 ? 750 : window.innerWidth,
		},
		speed: 20,
		margin: 15,
		thickness: 6
	}
	bodyMass = this.GLOBAL_VALUES.canvas.width / 320
	@observable WALL = {
		options: {
			red: {
				isStatic: true,
				render: {
					fillStyle: COLOURS.red,
				}
			},
			security: {
				isStatic: true,
				render: {
					fillStyle: 'transparent'
				}
			}
		},
		thickness: this.GLOBAL_VALUES.thickness,
		width: this.GLOBAL_VALUES.canvas.width - (2 * this.GLOBAL_VALUES.margin),
		height: (this.GLOBAL_VALUES.canvas.height) - (2 * this.GLOBAL_VALUES.margin),
		margin: this.GLOBAL_VALUES.margin
	}
	@observable traders = TRADERS
	@observable commodities = COMMODITIES
	@observable problems = PROBLEMS
	@observable controlsPositionTop = window.innerWidth > 600 ? -32 : -48
	@observable gridControlsPositionTop = window.innerWidth > 600 ? -32 : -48
	
	@observable uiElements = {
		nextButtonText: {
			de: 'Weiter',
			en: 'Next',
			fr: 'Suivant'
		},
		startButtonText: {
			de: 'Start',
			en: 'Start',
			fr: 'Commencer'
		},
		solutionButton: {
			de: 'Lösung anzeigen',
			en: 'Show me the solution',
			fr: 'Afficher la réponse'
		},
		backToStartButton: {
			de: 'Anderen Rohstoff wählen',
			en: 'Choose another commodity',
			fr: 'Recommence avec une autre matière'
		},
		orientationLockMessage: {
			de: 'Bitte in den Hochformat wechseln.',
			en: 'Please change to portrait mode.',
			fr: 'Veuillez passer en mode portrait.'
		},
		vote: {
			de: 'Stimme',
			en: 'Vote',
			fr: 'Vote'
		},
		votes: {
			de: 'Stimmen',
			en: 'Votes',
			fr: 'Votes'
		},
		loadingMessage: {
			de: 'Lade Daten..',
			en: 'Loading data..',
			fr: 'Chargement des données..'
		},
		backToStartText: {
			de: 'Mit einem aneren Rohstoff neu starten',
			fr: 'Redémarrer avec une autre ressource',
			en: 'Start over with another commodity'
		},
		backToProblems: {
			de: 'Zu den Problemen',
			fr: 'MISSING',
			en: 'MISSING'
		},
		goToWebsiteText: {
			de: 'Weiter zum ausführlichen Report',
			fr: 'Continuer au rapport détaillé',
			en: 'Continue to the detailed report'
		},
		clickHereText: {
			de: 'Hier klicken',
			fr: 'Cliquez ici',
			en: 'Click here'
		},
		goToFinishSlideText: {
			de: 'Weitere Informationen',
			fr: 'Plus d\'information',
			en: 'More information'
		},
		whyDoesThisMatterText : {
			de: 'Warum ist das relevant?',
			fr: 'Pourquoi c’est important ?',
			en: 'Why does that matter?',
		},
		sliderAction: {
			de: 'Regler verschieben, um Tipp abzugeben..',
			fr: 'Déplacer le curseur pour donner une estimation…',
			en: 'Move the slider to enter your guess..'
		},
		appTitle: {
			de: 'Agro Quiz',
			fr: 'Agro Quiz',
			en: 'Agro Quiz'
		}
	}
	
	// Actions
	@action
	toggle = () => {
		this.isSelected = !this.isSelected;
	}
	@action
	goToStart = () => {
		this.gameStarted = false
		this.journey.activeElement = 0
		this.journey.activePage = 0
		this.journey.activeChapter = 0
		this.guessTraders = []
		ReactGA.event({
			category: this.journeyId,
			action: 'BackToStart',
			label: this.language + '/' + this.journey.activeChapter + '/' + this.journey.activePage + '/' + this.journey.activeElement
		})
		window.location.reload();
	}
	@action
	resetCanvasWidth = (width) => {
		this.GLOBAL_VALUES.canvas = {
			height: (width > 750 ? 750 : width) / 16 * 9,
			width: width > 750 ? 750 : width,
		}
	}
	@action
	goToChapter = (chapter, page, element) => {
		if (!this.gameStarted) {
			this.gameStarted = true
		}
		this.journey.activeElement = element
		this.journey.activePage = page
		this.journey.activeChapter = chapter
		ReactGA.event({
			category: this.journeyId,
			action: 'GoToChapter',
			label: this.language + '/' + this.journey.activeChapter + '/' + this.journey.activePage + '/' + this.journey.activeElement
		})
	}
	@action
	goToFinishSlide = () => {
		this.journey.activeChapter = 9
		this.journey.activePage = 0
		this.journey.activeElement = 0
}
	@action
	goFurther = () => {
		if (!this.gameStarted) {
			this.gameStarted = true
		}
		if (this.gameOver) { return }
		// console.log(this.journey.activeChapter, this.journey.activePage, this.journey.activeElement)
		if (this.journey.activeElement + 1 < STORY[this.journey.activeChapter].pages[this.journey.activePage].elements.length) {
			this.journey.activeElement++
		} else {
			if (this.journey.activePage + 1 < STORY[this.journey.activeChapter].pages.length) {
				this.journey.activeElement = 0
				this.journey.activePage++
			} else {
				if (this.journey.activeChapter + 1 < STORY.length) {
					this.journey.activeChapter++
					this.journey.activePage = 0
					this.journey.activeElement = 0
				} else {
					this.gameOver = true
					console.log('Game over: ' + this.gameOver)
				}
			}
		}
		console.log(this.language + '/' + this.journey.activeChapter + '/' + this.journey.activePage + '/' + this.journey.activeElement)
		ReactGA.event({
			category: this.journeyId,
			action: 'Next',
			label: this.language + '/' + this.journey.activeChapter + '/' + this.journey.activePage + '/' + this.journey.activeElement
		})
	}
	
	@action
	startOver = () => {
		if (this.gameOver) {
			this.journey = {
				activeChapter: 0,
				activePage: 0,
				activeElement: 0
			}
			this.gameOver = false
		}
	}
	
	@action
	executeAction = (path) => {
		if (this.executedActions.indexOf(path)) {
			return;
		}
	}
	
	@action
	addElementsToStore = (elements) => {
		this.allElements.push(elements)
	}
	
	@action
	removeAllElements = () => {
		this.allElements = []
	}
	
	@action
	guessPercentTraded = (percent) => {
		this.guessPercent = percent
	}
	
	@action
	enableButton = () => {
		this.buttonIsEnabled = true
	}
	
	@action
	disableButton = () => {
		this.buttonIsEnabled = false
	}
	
	// Computed
	@computed
	get buttonText() {
		if (this.isSelected) {
			return 'selected'
		} else {
			return 'not selected'
		}
	}
	
	@computed
	get activeElementContent() {
		if (STORY[this.journey.activeChapter].pages[this.journey.activePage].elements[this.journey.activeElement]) {
			return STORY[this.journey.activeChapter].pages[this.journey.activePage].elements[this.journey.activeElement]
		} else {
			return null;
		}
	}
	
}

export default Store;
