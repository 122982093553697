// import vendor components
import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import ReactGA from 'react-ga';
import {inject, observer} from 'mobx-react';
import HeaderComponent from './components/header'
import OrientationComponent from './components/orientation';
import uniqid from 'uniqid';

// import constants
import ROUTES from './constants/routes'

import 'semantic-ui-css/components/reset.css'
import 'semantic-ui-css/components/site.css'
import 'semantic-ui-css/components/button.css'
import 'semantic-ui-css/components/header.css'
import 'semantic-ui-css/components/image.css'
import 'semantic-ui-css/components/segment.css'
import 'semantic-ui-css/components/grid.css'
import 'semantic-ui-css/components/divider.css'
import 'semantic-ui-css/components/container.css'
import 'semantic-ui-css/components/message.css'
import 'semantic-ui-css/components/icon.css'
import 'semantic-ui-css/components/menu.css'
import 'semantic-ui-css/components/loader.css'
import 'semantic-ui-css/components/divider.css'
import 'semantic-ui-css/components/flag.css'
import 'semantic-ui-css/components/label.css'

import './scss/main.scss'
import './App.scss'

@inject('store')
@observer
class App extends Component {
  
  constructor (props) {
    super(props)
    this.resize = this.resize.bind(this)
  }
  resize () {
    // window.location.reload()
    // console.log('window resized')
    // window.location.reload()
    let timestamp = new Date().getTime()
    let url = window.location.href;
    if (url.indexOf('?') > -1){
      url = url.split('?')[0] + '?reload=' + timestamp
    }else{
      url =  url + '?reload=' + timestamp
    }
    window.location.href = url;
  }
  componentDidMount() {
    const journeyId = uniqid()
    const timestamp = new Date().getTime()
    this.props.store.journeyId = timestamp + '-' + journeyId
    const journeyPath = this.props.store.language + '/' + this.props.store.journey.activeChapter + '/' + this.props.store.journey.activePage + '/' + this.props.store.journey.activeElement
    window.addEventListener('resize', this.resize)
    ReactGA.initialize('UA-142062484-1');
    ReactGA.pageview(journeyPath)
    ReactGA.event({
      category: this.props.store.journeyId,
      action: 'Start',
      label: journeyPath
    })
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }
  componentWillMount () {
    window.iFrameResizer = {
      heightCalculationMethod: 'taggedElement'
    }
  }
  render () {
    return (
      <div>
        <HeaderComponent />
        {ROUTES.map((route, index) => (
          <Route
            key={`route-${index}`}
            path={route.path}
            component={route.component}
            exact={route.isExact}
          />
        ))}
        <OrientationComponent />
      </div>
    );
  }
}

export default App
