import React, { Component } from 'react';
import {inject, observer} from 'mobx-react/index';

import './styles.scss';

@inject('store')
@observer
class GraphicComponent extends Component {
	render() {
		const { nextButton } = this.props
		
		return (
			<div id={'graphic-component'}>
		     { nextButton
		     &&
		     <div
			     className={ 'controls' }
			     style={{
				     top: this.props.store.controlsPositionTop
			     }}
		     >
			     {this.props.backToStartButton
			     &&
			     <button
				     className={'Button'}
				     ref={'toStart'}
				     onClick={this.props.store.goToStart}
			     >
				     <i className={'icon long arrow left'} /> {this.props.store.uiElements.backToStartButton[this.props.store.language]}
			     </button>
			     }
			     <button className={ 'Button' }
			             onClick={() => this.props.store.goFurther()}
			     >
				     { this.props.store.uiElements.nextButtonText[this.props.store.language] }&nbsp;
				     <i className={ 'icon long arrow right' }/>
			     </button>
		     </div>
				}
				<div
					className={`graphic-container ${this.props.fadeIn ? 'bounce-in-top' : ''}`}
					style={{
						bottom: '7px',
						height: (this.props.store.GLOBAL_VALUES.canvas.height) - (0 * this.props.store.GLOBAL_VALUES.margin) - 1 + 'px',
						width: (this.props.store.GLOBAL_VALUES.canvas.width) - (2 * this.props.store.GLOBAL_VALUES.margin),
						marginLeft: this.props.store.GLOBAL_VALUES.margin,
						backgroundImage: 'url(' + process.env.PUBLIC_URL + '/infographics/' + this.props.graphic + ')',
						backgroundSize: 'contain',
						backgroundColor: 'white',
						backgroundRepeat: 'no-repeat',
						backgroundPosition: 'center',
						position: 'relative'
					}}
				/>
				<a href={ 'https://www.publiceye.ch' }
				   target={ '_blank' }
				>
					<div
						className={ 'canvas-logo' }
						style={{
							bottom: '-5px',
							position: 'absolute'
						}}
					/>
				</a>
			</div>
		)
	}
}

export default GraphicComponent
