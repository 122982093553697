import React from 'react';
// import mobx injection
import { toJS } from 'mobx';
import {inject, observer} from 'mobx-react';
import * as _ from 'underscore'
import { withFirebase } from '../firebase';
import Matter from 'matter-js';
import MatterAttractors from 'matter-attractors';
import renderHTML from 'react-render-html';
import MapComponent from '../map';
import GridComponent from '../grid';
import GraphicComponent from '../graphic';
import TextComponent from '../text';
import HTMLContentComponent from '../htmlcontent'
import FlipcardsComponent from '../flipcards'
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import './styles.scss';
import COLOURS from '../../constants/colors'

// Activate magnetism plugin for physics engine
Matter.use(
	MatterAttractors,
);

// Set up the world
let Engine = Matter.Engine,
	Render = Matter.Render,
	World = Matter.World,
	Events = Matter.Events,
	Bodies = Matter.Bodies;

let engine = Engine.create({
	positionIterations: 5
});

let attractorStrength = 1e-6

@inject('store')
@observer
class StorylineComponent extends React.Component {
	constructor(props) {
		super(props)
		
		this.state = {
			buttonText: 'Start',
			elements: [],
			language: this.props.match.params.language,
			activeChapter: this.props.match.params.chapter ? this.props.match.params.language : 0,
			activeElement: this.props.match.params.element ? this.props.match.params.element : 0,
			activePage: this.props.match.params.page ? this.props.match.params.page : 0,
			percentWithoutGravity: 0,
			buttonIsEnabled: true,
			isLoading: true,
			exportTextToVar: true,
			exportedText: [],
		}
		this.addCircles = this.addCircles.bind(this)
		this.addIcons = this.addIcons.bind(this)
		this.removeElements = this.removeElements.bind(this)
		this.goToNextPage = this.goToNextPage.bind(this)
		this.executeActionsOnce = this.executeActionsOnce.bind(this)
		this.iconToggle = this.iconToggle.bind(this)
		this.clearWorld = this.clearWorld.bind(this)
		this.gravityToggle = this.gravityToggle.bind(this)
		this.specialGravityToggle = this.specialGravityToggle.bind(this)
		this.disableButtonForSomeSeconds = this.disableButtonForSomeSeconds.bind(this)
		this.updateDimensions = this.updateDimensions.bind(this)
		this.addNewGuessToFirebase = this.addNewGuessToFirebase.bind(this)
		this.saveGuessToDatabaseAndGoFurther = this.saveGuessToDatabaseAndGoFurther.bind(this)
		this.snapshotToArray = this.snapshotToArray.bind(this)
		this.removeAttractiveBody = this.removeAttractiveBody.bind(this)
		this.randomValueGenerator = this.randomValueGenerator.bind(this)
	}
	
	saveGuessToDatabaseAndGoFurther (guess) {
		// Set guess to State for checking later
		// Check if DB in Firebase exists
		const dbExists = this.props.store.db[this.props.store.journey.activeChapter] !== undefined
		
		switch (this.props.store.activeElementContent.uiElement) {
			case 'map':
				/* Map actions */
				// If map, take guess string as userGuess
				this.setState({
					userGuess: guess[0]
				})
				// Do all the magic for the map guess
				if (dbExists) {
					if (this.props.store.db[this.props.store.journey.activeChapter][this.state.journey.activePage][this.state.journey.activeElement][guess]) {
						// Raise by 1
						const currentValue = this.props.store.db[this.state.journey.activeChapter][this.state.journey.activePage][this.state.journey.activeElement][guess]
						this.props.firebase.doAddNewGuess(this.state.journey, guess, currentValue + 1, this.props.store.db.activePath)
					} else {
						// Set new guess key
						this.props.firebase.doAddNewGuess(this.state.journey, guess, 1, this.props.store.db.activePath)
					}
				} else {
					this.props.firebase.doAddNewGuess(this.state.journey, guess, 1, this.props.store.db.activePath)
				}
				this.props.firebase.guess(this.props.store.journey.activeChapter, this.props.store.journey.activePage, this.props.store.journey.activeElement).once('value', snapshot => {
					let amountOfVotes = 0
					let correctVotes = 0
					let wrongVotes= 0
					// Get the total amount of votes
					Object.entries(snapshot.val()).forEach(([key, value]) => {
						// console.log(value)
						amountOfVotes = amountOfVotes + value
						if (this.props.store.activeElementContent.uiElement === 'map') {
							if (this.props.store.activeElementContent.options.countryCode === this.state.userGuess) {
								correctVotes = correctVotes + 1
							}
							if (key === this.props.store.activeElementContent.options.countryCode) {
								correctVotes = correctVotes + value
							} else {
								wrongVotes = wrongVotes + value
							}
						}
					});
					// Save results to state / and the amount of results
					this.setState({
						resultsAmountTotal: amountOfVotes,
						results: snapshot.val(),
						correctVotes: correctVotes,
						wrongVotes: wrongVotes
					})
				})
				break;
			case 'slider':
				/* Slider actions */
				// If not map, take a number from the slider
				this.setState({
					userGuess: this.props.store.guessPercent
				})
				this.props.firebase.doAddNewSliderGuess(this.state.journey, this.props.store.guessPercent)
				let votesBetterThanMe = 0
				let votesWorseThanMe = 0
				let votesLikeMe = 0
				let userMistakePercent = Math.round(Math.abs(parseFloat(this.props.store.activeElementContent.options.nextResult) - parseFloat(this.props.store.guessPercent)))
				
				// console.log(this.props.store.activeElementContent.options.nextResult)
				// console.log('wrong by: ' + userMistakePercent + '%')
				
				this.props.firebase.guess(this.props.store.journey.activeChapter, this.props.store.journey.activePage, this.props.store.journey.activeElement).once('value', snapshot => {
					Object.entries(snapshot.val()).forEach(([key, value]) => {
						const userGuess = value.value
						const wrongPercent = Math.round(Math.abs(parseFloat(userGuess) - parseFloat(this.props.store.activeElementContent.options.nextResult)))
						
						if (wrongPercent > userMistakePercent) {
							votesWorseThanMe = votesWorseThanMe + 1
						}
						if (wrongPercent < userMistakePercent) {
							votesBetterThanMe = votesBetterThanMe + 1
						}
						if (wrongPercent === userMistakePercent) {
							votesLikeMe = votesLikeMe + 1
						}
						
					})
					this.setState({
						votesBetterThanMe,
						votesWorseThanMe,
						userMistakePercent,
						votesLikeMe: votesLikeMe - 1,
						totalVotes: votesBetterThanMe + votesWorseThanMe + votesLikeMe
					})
				})
				break;
			case 'grid':
				/* Grid actions */
				// console.log('GRID ACTIONS')
				this.setState({
					userGuess: 'asdf'
				})
				let correct = 0
				let wrong = 0
				let total = this.props.store.activeElementContent.options.nextResult.length
				
				for (let i = 0; i < this.props.store.guessTraders.length; i++) {
					if (this.props.store.activeElementContent.options.nextResult.indexOf(this.props.store.guessTraders[i]) !== -1) {
						// console.log('CORRECT')
						correct = correct + 1
					} else {
						// console.log('WRONG')
						wrong = wrong + 1
					}
				}
				
				// console.log(correct, wrong, total)
				
				this.props.firebase.doAddNewGridGuess(this.state.journey, correct, total)
				
				// Set  correct result in previous story line
				// console.log(this.props.store.activeElementContent.options.nextResult)
				
				let betterThanMe = 0
				let worseThanMe = 0
				let equalToMe = 0
				let totalVotes = 0
				
				this.props.firebase.guess(this.props.store.journey.activeChapter, this.props.store.journey.activePage, this.props.store.journey.activeElement).once('value', snapshot => {
					Object.entries(snapshot.val()).forEach(([key, value]) => {
						// console.log(key, value)
						const userGuess = correct
						if (value.correct > userGuess) {
							betterThanMe = betterThanMe + 1
						}
						if (value.correct < userGuess) {
							worseThanMe = worseThanMe + 1
						}
						if (value.correct === userGuess) {
							equalToMe = equalToMe + 1
						}
						totalVotes++
					})
					this.props.store.results = {
						betterThanMe,
						worseThanMe,
						equalToMe: equalToMe - 1,
						totalVotes,
						correctAnswers: correct,
						totalRight: total,
						guessAmount: this.props.store.activeElementContent.options.guessAmount,
						correctAnswerArray: this.props.store.activeElementContent.options.nextResult
					}
				})
				break;
			default:
				return null
		}
		
		if (this.props.store.activeElementContent.uiElement === 'map') {
		
		
		} else {
		
		}
		console.log(this.props.store.guessPercent)
		
		this.goToNextPage()
	}
	
	snapshotToArray (snapshot) {
		var returnArr = _.toArray(snapshot);
		return returnArr
	};
	
	goToNextPage (e) {
		if (e) {
			e.preventDefault()
		}
		
		if (this.props.store.globalInterval !== '') {
			clearInterval(this.props.store.globalInterval);
		}
		
		if (this.props.store.activeElementContent.options.goToFinishSlide) {
			this.props.store.goToFinishSlide()
		}
		
		this.setState({
			results: []
		})
		this.props.store.goFurther()
	}
	
	updateDimensions (newWidth) {
		let width = window.innerWidth
		
		if (newWidth) {
			width = newWidth
		}
		
		this.props.store.resetCanvasWidth(width)
		this.forceUpdate();
	}
	
	componentDidMount() {
		this.updateDimensions()

		this.props.store.language = this.props.match.params.language
		
		this.props.store.journey.activeChapter = this.props.match.params.chapter ? parseInt(this.props.match.params.chapter) : 0
		this.props.store.journey.activePage = this.props.match.params.page ? parseInt(this.props.match.params.page) : 0
		this.props.store.journey.activeElement = this.props.match.params.element ? parseInt(this.props.match.params.element) : 0
		
		this.props.firebase.guesses().on('value', snapshot => {
			const results = Object.keys(snapshot.val()).map(key => {
				return [Number(key), snapshot.val()[key]];
			});
			// console.log(results)
			this.props.store.db = snapshot.val()
			this.props.store.results = results
			if (this.props.store.db) {
				this.setState({
					isLoading: false,
					journey: this.props.store.journey,
				})
			}
		});
		
		var render = Render.create({
			element: this.refs.scene,
			engine: engine,
			options: {
				width: this.props.store.GLOBAL_VALUES.canvas.width,
				// height: window.innerWidth > 750 ? 750 : (window.innderWidth / 16 * 9),
				height: this.props.store.GLOBAL_VALUES.canvas.height,
				wireframes: false,
				background: 'white'
			}
		})
		
		
		
		// GENERATING PUBLIC EYE SIDE WALLS
		const topWall = Bodies.rectangle(
			this.props.store.GLOBAL_VALUES.canvas.width / 2,
			this.props.store.WALL.thickness - 2,
			this.props.store.WALL.width,
			this.props.store.WALL.thickness,
			this.props.store.WALL.options.red
		)
		const leftWall = Bodies.rectangle(
			this.props.store.WALL.margin + (this.props.store.WALL.thickness / 2),
			this.props.store.GLOBAL_VALUES.canvas.height / 2 - this.props.store.WALL.thickness,
			this.props.store.WALL.thickness,
			this.props.store.GLOBAL_VALUES.canvas.height - (this.props.store.WALL.margin * 0.5),
			this.props.store.WALL.options.red
		)
		const rightWall = Bodies.rectangle(
			this.props.store.GLOBAL_VALUES.canvas.width - this.props.store.WALL.margin - (this.props.store.WALL.thickness / 2),
			this.props.store.GLOBAL_VALUES.canvas.height / 2 - this.props.store.WALL.thickness,
			this.props.store.WALL.thickness,
			this.props.store.GLOBAL_VALUES.canvas.height - (this.props.store.WALL.margin * 0.5),
			this.props.store.WALL.options.red
		)
		const bottomWall = Bodies.rectangle(
			this.props.store.GLOBAL_VALUES.canvas.width / 2,
			this.props.store.GLOBAL_VALUES.canvas.height - (this.props.store.WALL.margin * 0.5),
			this.props.store.WALL.width,
			this.props.store.WALL.thickness,
			this.props.store.WALL.options.red
		)
		
		// GENERATING SECURITY WALLS
		const topWallSecurity = Bodies.rectangle(
			this.props.store.GLOBAL_VALUES.canvas.width / 2,
			this.props.store.WALL.margin / 4,
			this.props.store.WALL.width,
			this.props.store.WALL.thickness * 4,
			this.props.store.WALL.options.security
		)
		const leftWallSecurity = Bodies.rectangle(
			this.props.store.WALL.margin / 4,
			this.props.store.GLOBAL_VALUES.canvas.height / 2,
			this.props.store.WALL.thickness * 4,
			this.props.store.GLOBAL_VALUES.canvas.height,
			this.props.store.WALL.options.security
		)
		const rightWallSecurity = Bodies.rectangle(
			this.props.store.GLOBAL_VALUES.canvas.width - (this.props.store.WALL.thickness / 2),
			this.props.store.GLOBAL_VALUES.canvas.height / 2,
			this.props.store.WALL.thickness * 4,
			this.props.store.GLOBAL_VALUES.canvas.height,
			this.props.store.WALL.options.security
		)
		const bottomWallSecurity = Bodies.rectangle(
			this.props.store.GLOBAL_VALUES.canvas.width / 2,
			this.props.store.GLOBAL_VALUES.canvas.height,
			this.props.store.WALL.width,
			this.props.store.WALL.thickness * 4,
			this.props.store.WALL.options.security
		)
		
		World.add(engine.world, [
			topWallSecurity, leftWallSecurity, rightWallSecurity, bottomWallSecurity, topWall, leftWall, rightWall, bottomWall,
		])
		
		// create a body with an attractor
		let attractiveBody = Bodies.circle(
			this.props.store.GLOBAL_VALUES.canvas.width/ 2,
			- this.props.store.WALL.margin * 2,
			this.props.store.GLOBAL_VALUES.canvas.width / 30,
			{
				isStatic: true,
				label: 'attractor',
				render: {
					fillStyle: 'transparent'
				},
				
				// example of an attractor function that
				// returns a force vector that applies to bodyB
				plugin: {
					attractors: [
						function(bodyA, bodyB) {
							return {
								x: (bodyA.position.x - bodyB.position.x) * attractorStrength,
								y: (bodyA.position.y - bodyB.position.y) * attractorStrength,
							};
						}
					]
				}
			}
		);
		World.add(engine.world, attractiveBody);
		// end adding attractive body
		/*
		let magnetSpaceRectangle = Bodies.rectangle(
			this.props.store.GLOBAL_VALUES.canvas.width/ 2,
			28,
			this.props.store.GLOBAL_VALUES.canvas.width * 0.4,
			this.props.store.GLOBAL_VALUES.canvas.width > 500 ? 0 : 0,
			{
				isStatic: true,
				render: {
					fillStyle: 'transparent'
				},
			}
		)
		World.add(engine.world, magnetSpaceRectangle);
		*/
		Engine.run(engine)
		Render.run(render)
	}
	
	addIcons (elements, companyIcon, renderStyles,) {
		let intervalSpeed = this.props.store.GLOBAL_VALUES.speed
		let i = 0
		let iconElements = []
		
		// Remove attractor
		// this.removeAttractiveBody()
		
		// Add guiding walls
		World.add(engine.world, [
			Bodies.rectangle(
				this.props.store.GLOBAL_VALUES.canvas.width * 0.35,
				this.props.store.GLOBAL_VALUES.canvas.width * 0.15,
				this.props.store.GLOBAL_VALUES.canvas.width * 0.45,
				this.props.store.WALL.thickness,
				{
					label: 'guide',
					isStatic: true,
					angle: Math.PI * 0.08,
					render: {
						fillStyle: COLOURS.red
					}
				}
			),
			Bodies.rectangle(
				this.props.store.GLOBAL_VALUES.canvas.width * 0.62,
				this.props.store.GLOBAL_VALUES.canvas.width * 0.3,
				this.props.store.GLOBAL_VALUES.canvas.width * 0.7,
				this.props.store.WALL.thickness,
				{
					isStatic: true, angle: -Math.PI * 0.08,
					label: 'guide',
					render: {
						fillStyle: COLOURS.red
					}
				}
			),
			// Bodies.rectangle(340, 580, 700, 20, { isStatic: true, angle: Math.PI * 0.04 })
		]);
		
		// Set responsive sizes for all elements
		
		const iconSize = 50
		const spriteSize = 180
		let iconSpriteScale = 1
		let iconSpriteSize = iconSize
		let logoSpriteScale = 1
		let logoSpriteSize = spriteSize
		
		if (window.innerWidth >= 800) {
			logoSpriteScale = 1
			logoSpriteSize = spriteSize
		}
		
		if (window.innerWidth >= 500 && window.innerWidth < 800) {
			iconSpriteSize = 35
			iconSpriteScale = 0.75
			logoSpriteScale = 0.8 / spriteSize * 160
			logoSpriteSize = 160
		}
		
		if (window.innerWidth >= 400 && window.innerWidth < 500) {
			iconSpriteSize = 30
			iconSpriteScale = 0.6
			logoSpriteScale = 0.6 / spriteSize * 140
			logoSpriteSize = 140
		}
		
		if (window.innerWidth < 400) {
			iconSpriteSize = 25
			iconSpriteScale = 1 / iconSize * iconSpriteSize
			logoSpriteScale = 0.4
			logoSpriteSize = 90
		}
		
		for (let i = 0; i < elements.length; i++) {
			// for (let ii = 0; ii < elements[i].length; ii++) {
			for (let ii = 0; ii < elements[i]['amount']; ii++) {
				let tempIcon = Bodies.rectangle(
					this.props.store.GLOBAL_VALUES.canvas.width / 3,
					this.props.store.WALL.margin * 2,
					iconSpriteSize,
					iconSpriteSize,
					{
						// density: 0.0005,
						frictionAir: 0.06,
						restitution: 0.2,
						friction: 0,
						mass: this.props.store.bodyMass,
						label: 'icon',
						render: {
							...renderStyles,
							sprite: {
								texture: process.env.PUBLIC_URL + '/icons/' + elements[i]['icon'] + '.png',
								xScale: iconSpriteScale,
								yScale: iconSpriteScale,
							}
						}
					}
				)
				iconElements.push(
					tempIcon
				)
			}
		}
		
		// Shuffle elements array
		let shuffledElements = iconElements.sort(() => Math.random() - 0.5)
		
		const companySize = this.props.store.GLOBAL_VALUES.canvas.width * 0.2
		const company = Bodies.rectangle(
			this.props.store.GLOBAL_VALUES.margin + 0.75 * companySize,
			(this.props.store.GLOBAL_VALUES.canvas.height - this.props.store.WALL.margin) - 0.75 * companySize,
			logoSpriteSize,
			logoSpriteSize,
			{
				isStatic: true,
				label: 'company',
				render: {
					fillStyle: COLOURS.red,
					sprite: {
						texture: process.env.PUBLIC_URL + '/logos/logos-assets/' + companyIcon,
						xScale: logoSpriteScale,
						yScale: logoSpriteScale
					}
				}
			}
		)
		
		World.add(engine.world, company)
		
		this.props.store.iconInterval = setInterval(() => {
			World.add(engine.world, shuffledElements[i])
			
			if (i + 1 < shuffledElements.length) {
				i++
			} else {
				clearInterval(this.props.store.iconInterval)
				i = 0
			}
		}, intervalSpeed * 15)
		
		// an example of using collisionStart event on an engine
		Events.on(engine, 'collisionStart', function(event) {
			var pairs = event.pairs;
			
			for (var i = 0; i < pairs.length; i++) {
				var pair = pairs[i];
				if (pair.bodyA.label === 'company') {
					// console.log(pair.bodyA)
					World.remove(engine.world, pair.bodyB)
				} else if (pair.bodyB.label === 'company') {
					// console.log(pair.bodyB)
					// Matter.Body.scale(pair.bodyB, 1)
					if (pair.bodyB.angle !== 0) {
						pair.bodyB.angle = -pair.bodyB.angle
					} else {
						Matter.Body.rotate(pair.bodyB, Math.PI * 0.02)
					}
					World.remove(engine.world, pair.bodyA)
				}
			}
		});
	}
	
	randomValueGenerator (min, max) {
		return Math.floor(Math.random() * (max - min) ) + min;
	}
	
	addCircles (amount, color, size, speed, options, renderStyles, icon) {
		let intervalSpeed = amount > 1000 ? 10 : this.props.store.GLOBAL_VALUES.speed * 1.25
		let i = 0
		let circles = []
		for (let i = 0; i < amount; i++) {
			let xPos = this.randomValueGenerator(this.props.store.GLOBAL_VALUES.canvas.width * 0.45, this.props.store.GLOBAL_VALUES.canvas.width * 0.55)
			circles.push(
				Bodies.circle(
					xPos,
					this.props.store.WALL.margin * 2,
					window.innerWidth > 500 ? this.props.store.GLOBAL_VALUES.canvas.height / 30 : this.props.store.GLOBAL_VALUES.canvas.height / 30,
					{
						...options,
						restitution: 0.025,
						friction: 0.01,
						frictionAir: 0.025,
						mass: this.props.store.bodyMass,
						label: 'commodity',
						render: {
							...renderStyles,
							fillStyle: color,
							sprite: {
								texture: icon ? process.env.PUBLIC_URL + '/icons/' + icon + '.png' : null,
								xScale: this.props.store.GLOBAL_VALUES.canvas.width / (1600 * 1),
								yScale: this.props.store.GLOBAL_VALUES.canvas.width / (1600 * 1)
							}
						}
					}
				)
			)
		}
		let interval = setInterval(() => {
			World.add(engine.world, circles[i])
			if (i + 1 < amount) {
				i++
			} else {
				// console.log(circles)
				clearInterval(interval)
				i = 0
			}
		}, intervalSpeed)
	}
	
	removeAttractiveBody () {
		const attractiveBody = engine.world.bodies.filter((body) => body.label === 'attractor')
		World.remove(engine.world, attractiveBody)
	}
	
	clearWorld () {
		console.log('CLEAR WORLD')
		clearInterval(this.props.store.iconInterval)
		this.props.store.guessPercent = 0
		if (this.props.store.journey.activePage === 0 && this.props.store.journey.activeElement <= 1) {
			this.props.store.textArray = []
		}
		const allElements = engine.world.bodies.filter((body) => body.label === 'commodity' || body.label === 'icon' || body.label === 'company' || body.label === 'guide')
		for (let i = 0; i < allElements.length; i++) {
			World.remove(engine.world, allElements[i])
		}
	}
	
	colourToggle (options) {
		const allElements = engine.world.bodies.filter((body) => body.label === 'commodity')
		allElements.sort((a, b) => a.position.y - b.position.y)
		let amountOfElements = allElements.length
		for (let i = 0; i < amountOfElements; i++) {
			if (i <= allElements.length / 100 * options[0].value) {
				allElements[i].render.fillStyle = options[0].colour
			} else {
				allElements[i].render.fillStyle = options[1].colour
			}
		}
	}
	
	gravityToggle (percent) {
		// Set percentage in mobx
		this.props.store.guessPercentTraded(percent)
		// Get all circles
		const allElements = engine.world.bodies.filter((body) => body.label === 'commodity')
		allElements.sort((a, b) => a.position.y - b.position.y)
		let percentageToChange = allElements.length
		if (percent) {
			if (percent === 0) {
				percentageToChange = allElements.length
			} else {
				percentageToChange = allElements.length / 100 * percent
			}
		}
		for (let i = 0; i < allElements.length; i++) {
			let body = allElements[i]
			if (i < percentageToChange) {
				if (percent === 0) {
					body.mass = this.props.store.bodyMass
				} else {
					body.mass = 0.05
				}
			} else {
				body.mass = this.props.store.bodyMass
			}
		}
	}
	
	specialGravityToggle (percent) {
		// Set percentage in mobx
		if (!percent) {
			percent = 100;
		}
		this.props.store.guessPercentTraded(percent)
		// Get all circles
		const allElements = engine.world.bodies.filter((body) => body.label === 'commodity')
		allElements.sort((a, b) => a.position.y - b.position.y)
		// eslint-disable-next-line
		let percentageToChange = allElements.length
		if (percent) {
			if (percent === 0) {
				percentageToChange = allElements.length
			} else {
				percentageToChange = allElements.length / 100 * percent
			}
		}
		
		this.props.store.globalInterval = setInterval(() => {
			const randomNumber = Math.round(Math.random() * ((allElements.length - 1) - 0) + 0);
			const randomElement = allElements[randomNumber];
			// console.log(parseInt(randomNumber));
			randomElement.mass = 0.05;
			setTimeout(() => {
				randomElement.mass = 10;
			}, 325);
		},50);
	}
	
	iconToggle (percent, option, icon) {
		const allElements = engine.world.bodies.filter((body) => body.label === 'commodity')
		let percentageToChange = allElements.length
		if (percent) {
			percentageToChange = Math.round(allElements.length / 100 * percent)
		}
		switch (option) {
			case 'toIcon':
				for (let i = 0; i < percentageToChange; i++) {
					allElements[i].render.sprite.texture = process.env.PUBLIC_URL + '/icons/' + icon + '.png'
				}
				break;
			case 'toColour':
				for (let i = 0; i < percentageToChange; i++) {
					allElements[i].render.sprite.texture = null
				}
				break;
			default:
				return;
		}
	}
	
	removeElements () {
		let i = 0
		let elements = this.props.store.allElements
		let interval = setInterval(function() {
			if (i < elements.length) {
				World.remove(engine.world, elements[i])
				i++
			} else {
				clearInterval(interval)
			}
		}, this.props.store.GLOBAL_VALUES.speed / 8)
		this.props.store.removeAllElements()
	}
	
	executeActionsOnce (path, actions, options) {
		/* Export text */
		if (this.state.exportTextToVar) {
			let newElement = this.props.store.journey.activeChapter + '' + this.props.store.journey.activePage + '' + this.props.store.journey.activeElement
			if (this.props.store.textArray.indexOf(newElement) < 0) {
				this.props.store.textArray.push(newElement)
			} else {
				return
			}
		}
		
		if (!actions) { return }
		
		this.refs.next.disabled = true
		
		let promisesArray = []
		
		for (let i = 0; i < actions.length; i++) {
			let action = actions[i]
			if (promisesArray.length > 1) { return }
			let timeout = 2000
			if (this.props.store.activeElementContent.options.timeOut) {
				timeout = this.props.store.activeElementContent.options.timeOut
			}
			switch (action) {
				case 'clearWorld':
					let clearPromise = new Promise((resolve, reject) => {
						this.clearWorld()
						setTimeout(resolve, timeout);
					})
					promisesArray.push(clearPromise)
					break;
				case 'stopGuessListener':
					let listenerPromise = new Promise((resolve, reject) => {
						this.props.firebase.guesses().off()
						console.log('listener turned off')
						setTimeout(resolve, timeout);
					})
					promisesArray.push(listenerPromise)
					break;
				case 'nextElement':
					let nextPromise = new Promise((resolve, reject) => {
						setTimeout(() => this.props.store.goFurther(), this.props.store.activeElementContent.options.timeout ? this.props.store.activeElementContent.options.timeout : 2000);
					})
					promisesArray.push(nextPromise)
					break;
				case 'addCircles':
					let circlePromise = new Promise((resolve, reject) => {
						this.addCircles(options.amount, options.color, options.size || null, null, null, null, options.icon)
						setTimeout(resolve, timeout);
					})
					promisesArray.push(circlePromise)
					break;
				case 'addIcons':
					let addIconPromise = new Promise((resolve, reject) => {
						this.addIcons(options.elements, options.companyIcon,)
						setTimeout(resolve, timeout);
					})
					promisesArray.push(addIconPromise)
					break;
				case 'iconToggle':
					let iconPromise = new Promise((resolve,  reject) => {
						this.iconToggle(options.percent, options.direction, options.icon)
						setTimeout(resolve, timeout);
					})
					promisesArray.push(iconPromise)
					break;
				case 'gravityToggle':
					let gravityPromise = new Promise((resolve, reject) => {
						this.gravityToggle(options.gravityFree)
						setTimeout(resolve, timeout);
					})
					promisesArray.push(gravityPromise)
					break;
				case 'colourToggle':
					let colourPromise = new Promise((resolve, reject) => {
						this.colourToggle(options.colours)
						setTimeout(resolve, timeout);
					})
					promisesArray.push(colourPromise)
					break;
				case 'specialCase':
					let specialCasePromise = new Promise ((resolve, reject) => {
						this.specialGravityToggle();
					})
					promisesArray.push(specialCasePromise)
					break;
				case 'fetchData':
					let path = this.props.store.journey.activeChapter + '/' + this.props.store.journey.activePage + '/' + this.props.store.journey.activeElement + this.props.store.activeElementContent.options.dataSrc
					// console.log(path)
					let fetchPromise = new Promise((resolve, reject) => {
						fetch('https://publiceye-redbox.firebaseio.com/rest/guesses/' + path + '.json?callback=gotData')
							.then(res => res.json())
							.then(resolve)
					})
					promisesArray.push(fetchPromise)
					break;
				default:
					setTimeout(() => {
						this.refs.next.disabled = false
					}, timeout)
					break;
			}
		}
		
		Promise.all(promisesArray)
			.then(() => {
				this.props.store.executeAction(path)
				this.refs.next.disabled = false
			});
	}
	
	disableButtonForSomeSeconds () {
		this.setState({
			buttonIsEnabled: false
		})
		let timeout = 2000
		if (this.props.store.activeElementContent.options.timeout) {
			timeout = this.props.store.activeElementContent.options.timeout
		}
		setTimeout(() => this.setState({buttonIsEnabled: true}), timeout)
	}
	
	componentWillUnmount () {
		const backToStartButton = document.querySelector(".back-to-start-button") ? document.querySelector(".back-to-start-button") : null
		if (backToStartButton) {
			backToStartButton.removeEventListener("click", () => console.log('event listener removed'));
		}
	}
	
	componentDidUpdate (prevProps) {
		const prevPath = [parseInt(prevProps.store.journey.activeChapter), prevProps.store.journey.activePage, prevProps.store.journey.activeElement]
		const path = [parseInt(this.props.store.journey.activeChapter), this.props.store.journey.activePage, this.props.store.journey.activeElement]
		const executedActions = toJS(this.props.store.executedActions)
		const prevExecutedActions = toJS(prevProps.store.executedActions)
		
		/*
		if (this.props.store.journey.activePage === 1 && this.props.store.journey.activeElement > 1) {
			this.props.firebase.guesses().off()
		}
		*/
		
		const backToStartButton = document.querySelector(".back-to-start-button") ? document.querySelector(".back-to-start-button") : null
		if (backToStartButton) {
			backToStartButton.addEventListener('click', () => {
				this.props.store.goToStart()
			})
		}
		
		if (prevExecutedActions === executedActions) {
			return
		}
		
		if (prevPath !== path && executedActions !== prevExecutedActions) {
			// eslint-disable-next-line
			const activeChapter = this.props.store.journey.activeChapter
			// eslint-disable-next-line
			const activePage = this.props.store.journey.activePage
			// eslint-disable-next-line
			const activeElement= this.props.store.journey.activeElement
			// eslint-disable-next-line
			const previousElement = this.props.store.journey.activeElement === 0 ? this.props.store.journey.activeElement : this.props.store.journey.activeElement - 1
			
			this.executeActionsOnce(
				path,
				this.props.store.activeElementContent.actions,
				this.props.store.activeElementContent.options
			)
		}
	}
	
	addNewGuessToFirebase () {
		this.props.firebase.doAddNewGuess(this.props.store.journey.activeChapter, this.props.store.journey.activePage, this.props.store.journey.activeElement, 'vnm')
	}
	
	render() {
		// eslint-disable-next-line
		const { story, journey, gameOver, activeElementContent } = this.props.store
		// eslint-disable-next-line
		const { activeChapter, activePage, activeElement } = this.props.store.journey
		// eslint-disable-next-line
		const { isLoading } = this.state

		// const currentWidth = window.innerWidth

		return (
			<div data-iframe-height>
				<div>
					<div id={ 'story-stage' }
					     style={ {
						     display: 'block'
					     }}
					>
						<div id={ 'story' }>
							<div id={ 'messages' }
							     className={ `ui vertical aligned top aligned very basic segment ${ activeElementContent.uiElement === 'blackout' ? '_dark' : '' }` }
							     style={{
								     // height: currentWidth / 16 * 6
							     }}
							>
								<div className={ 'chapter-control' }>
									{ journey.activeChapter } / { journey.activePage } / { journey.activeElement }
									{ gameOver
									&& <p style={ {color: 'red'} }>GAME OVER</p>
									}
								</div>
								<TextComponent
									staticText={ renderHTML(activeElementContent['text'][this.props.store.language]) }
									resultText={ activeElementContent.options.resultText }
									uiElement={ activeElementContent.uiElement }
									isFinishSlide={ activeElementContent.options.isFinishSlide }
									mapResult={ this.state.userGuess === activeElementContent.options.countryCode }
									mapVotesCorrect={ this.state.correctVotes - 1 }
									mapVotesWrong={ this.state.wrongVotes - 1 }
									sliderResult={ this.state.userMistakePercent === 0 }
									sliderCorrectAnswer={ activeElementContent.options.gravityFree }
									sliderVotesWorseThanMe={ this.state.votesWorseThanMe }
									sliderVotesBetterThanMe={ this.state.votesBetterThanMe }
									sliderVotesTotal={ this.state.totalVotes - 1 }
									gridResult={ parseInt(this.props.store.results.correctAnswers) >= this.props.store.results.guessAmount }
									gridResultCorrectAnswers={ this.props.store.results.totalRight }
									gridResultGuessAmount={ this.props.store.results.guessAmount }
									gridResultGuessedRight={ this.props.store.results.totalRight }
									gridResultTotalTraders={ this.props.store.traders.length }
									gridResultsVotesEqualToMe={ this.props.store.results.equalToMe }
									gridResultsVotesTotal={ this.props.store.results.totalVotes }
									gridResultsVotesBetterThanMe={ this.props.store.results.betterThanMe }
									commodityTitle={ this.props.store.commodities[this.props.store.journey.activeChapter]['text'][this.props.store.language] }
								/>
								{ activeElementContent.uiElement === 'slider'
								&&
								<div className={ 'slider-container' }>
									<h4 className={ 'slider-value' }>{ this.props.store.guessPercent }%</h4>
									<Slider
										min={ 0 }
										max={ 100 }
										onChange={ (e) => this.gravityToggle(e) }
									/>
									{ this.props.store.guessPercent === 0
									&&
									<div
										className={ `ui pointing ${ window.innerWidth > 500 ? '' : 'below' } medium publiceye-red inverted center aligned label call-to-action centered heartbeat` }>
										<i className='icon hand pointer'/>{this.props.store.uiElements.sliderAction[this.props.store.language]}
									</div>
									}
								</div>
								}
								<div
									className={ 'controls' }
									style={ {
										top: this.props.store.GLOBAL_VALUES.WIDTH / 16 * 6 + this.props.store.controlsPositionTop + 5,
										// eslint-disable-next-line
										display: activeElementContent.uiElement === 'redbox' || activeElementContent.uiElement === 'slider' && this.props.store.guessPercent > 0 ? 'block' : 'none'
									} }
								>
									{ activeElementContent.options.backToStartButton && activeElementContent.uiElement === 'redbox'
									&&
									<button
										className={ 'Button' }
										ref={ 'toStart' }
										onClick={ this.props.store.goToStart }
									>
										<i
											className={ 'icon long arrow left' }/> { this.props.store.uiElements.backToStartButton[this.props.store.language] }
									</button>
									}
									{ this.props.store.gameOver
										? (
											<button
												className={ 'Button white' }
												onClick={ this.props.store.startOver }
											>
												<i className={ 'icon long arrow left' }/> Start over
											</button>
										)
										:
										(
											<div>
												<button
													className={ `Button` }
													onClick={ () => this.goToNextPage() }
													ref={ 'next' }
													style={ {
														display: activeElementContent.uiElement !== 'slider' && activeElementContent.options.nextButton ? 'unset' : 'none',
													}}
												>
													<span>
														{this.props.store.uiElements.nextButtonText[this.props.store.language]} <i className={'icon long arrow right'} />
													</span>
												</button>
												<button
													className={ `Button` }
													onClick={ () => this.saveGuessToDatabaseAndGoFurther(this.props.store.guessPercent) }
													ref={ 'next' }
													style={ {
														display: activeElementContent.uiElement === 'slider' && this.props.store.guessPercent >= 1 ? 'unset' : 'none',
													}}
												>
													<span>
														{this.props.store.uiElements.nextButtonText[this.props.store.language]} <i className={'icon long arrow right'} />
													</span>
												</button>
												<button
													className={ `Button` }
													onClick={ activeElementContent.options.goToProblems ? () => this.props.store.goToChapter(8,0,0) : this.goToNextPage }
													ref={ 'next' }
													style={{
														display: activeElementContent.uiElement === 'redbox' && !activeElementContent.options.nextButton ? 'unset' : 'none',
													}}
												>
													<span>{ !activeElementContent.options.whatMattersText ? this.props.store.uiElements.nextButtonText[this.props.store.language] : this.props.store.uiElements.whyDoesThisMatterText[this.props.store.language] }</span>
													&nbsp;<i className={ 'icon long arrow right' }/>
												</button>
											</div>
										)
									}
								</div>
							</div>
						</div>
						<div
							id={ 'redbox' }
							ref='scene'
							style={ {
								display: activeElementContent.uiElement === 'redbox' || activeElementContent.uiElement === 'slider' ? 'block' : 'none',
								// height: ((this.props.store.GLOBAL_VALUES.canvas.width / 16 * 9) + 15) + 'px',
								height: window.innerWidth > 750 ? (750 / 16 * 9) + 15 + 'px' : (window.innerWidth / 16 * 9) + 15 + 'px',
							}}
						>
							<a href={ 'https://www.publiceye.ch' }
							   target={ '_blank' }
							   style={ {
								   opacity: activeElementContent.uiElement === 'slider' || activeElementContent.uiElement === 'redbox' ? 1 : 0
							   }}
							>
								<div
									className={ 'canvas-logo' }
								/>
							</a>
						</div>
						{ activeElementContent.uiElement === 'map'
						&&
						<div className={ 'map-container' }>
							<MapComponent
								center={ activeElementContent.options.center }
								disableZoom={ activeElementContent.options.disableZoom }
								disablePanning={ activeElementContent.options.disablePanning }
								disableOptimization={ activeElementContent.options.disableOptimization }
								userInteraction={ activeElementContent.options.userInteraction }
								nextButton={ activeElementContent.options.nextButton }
								zoom={ activeElementContent.options.zoom }
								userZoom={ activeElementContent.userZoom }
								results={ activeElementContent.options.results }
								countryCode={ activeElementContent.options.countryCode }
								clickHandler={ this.goToNextPage }
								solutionHandler={ this.saveGuessToDatabaseAndGoFurther }
								annotations={ activeElementContent.options.annotations }
								borderWeight={ this.props.store.GLOBAL_VALUES.thickness }
							/>
						</div>
						}
						{ activeElementContent.uiElement === 'graphic'
						&&
						<GraphicComponent
							nextButton={ activeElementContent.options.nextButton }
							graphic={ activeElementContent.options.infographic }
							backToStartButton={ activeElementContent.options.backToStartButton }
							goToFinishSlide={ activeElementContent.options.goToFinishSlide }
							fadeIn={ activeElementContent.options.fadeIn }
						/>
						}
						{ activeElementContent.uiElement === 'htmlcontent'
						&&
						<HTMLContentComponent
							nextButton={ activeElementContent.options.nextButton }
							photo={ activeElementContent.options.htmlContent.img}
							backToProblemsButton={ activeElementContent.options.backToProblemsButton }
							htmlText={ activeElementContent.options.htmlContent.text[this.props.store.language] }
							backToStartButton={ activeElementContent.options.backToStartButton }
							goToFinishSlide={ activeElementContent.options.goToFinishSlide }
							fadeIn={ activeElementContent.options.fadeIn }
						/>
						}
						{ activeElementContent.uiElement === 'flipcards'
						&&
						<FlipcardsComponent />
						}
						{ activeElementContent.uiElement === 'grid'
						&&
						<div
							className={ 'grid-container' }
							style={ {
								// height: (this.props.store.GLOBAL_VALUES.WIDTH / 16 * 9) - 4 + 'px',
								border: this.props.store.GLOBAL_VALUES.thickness + 'px solid ' + COLOURS.red,
							} }
						>
							<GridComponent
								rows={ activeElementContent.options.rows }
								columns={ activeElementContent.options.columns }
								nextButton={ activeElementContent.options.nextButton }
								solutionHandler={ this.saveGuessToDatabaseAndGoFurther }
								guessAmount={ activeElementContent.options.guessAmount }
								data={ activeElementContent.options.content }
								isDark={ activeElementContent.options.isDark }
								isQuiz={ activeElementContent.options.quiz }
								isFinishSlide={ activeElementContent.options.isFinishSlide }
								goToFinishSlide={ activeElementContent.options.goToFinishSlide }
							/>
						</div>
						}
					</div>
				</div>
			</div>
		)
	}
}
export default withFirebase(StorylineComponent)
