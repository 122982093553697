function shuffleArray(array) {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
	return array
}
const TRADERS = [
	{
		id: 'adm',
		name: 'adm',
	},
	{
		id: 'alvean',
		name: 'alvean',
	},
	{
		id: 'bunge',
		name: 'bunge',
	},
	{
		id: 'cargil',
		name: 'cargil',
	},
	{
		id: 'chiquita',
		name: 'chiquita',
	},
	{
		id: 'cofco',
		name: 'cofco',
	},
	{
		id: 'ecom',
		name: 'ecom',
	},
	{
		id: 'glencore',
		name: 'glencore',
	},
	{
		id: 'ldc',
		name: 'ldc',
	},
	{
		id: 'hkg',
		name: 'cargil',
	},
	{
		id: 'olam',
		name: 'olam',
	},
	{
		id: 'reinhart',
		name: 'reinhart',
	},
	{
		id: 'sucafina',
		name: 'sucafina',
	},
	{
		id: 'cutrale',
		name: 'cutrale',
	},
	{
		id: 'volcafe',
		name: 'volcafe',
	}
	
]

export default shuffleArray(TRADERS)
