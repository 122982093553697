import React, { Component } from 'react';
import {inject, observer} from 'mobx-react/index';

@inject('store')
@observer
class RedirectComponent extends Component {
	componentDidMount () {
		let newPath = ''
		if (typeof window !== 'undefined') {
			const commodity = this.props.match.params.commodity
			switch (commodity) {
				case 'agroquiz-fr':
					newPath = '/agroquiz/fr/0'
					break;
				case 'agroquiz-de':
					newPath = '/agroquiz/de/0'
					break;
				case 'agroquiz-en':
					newPath = '/agroquiz/en/0'
					break;
				case 'getreide':
					newPath ='/agroquiz/de/1/0/1'
					break;
				case 'cereales':
					newPath ='/agroquiz/fr/1/0/1'
					break;
				case 'grains':
					newPath ='/agroquiz/en/1/0/1'
					break;
				case 'kaffee':
					newPath ='/agroquiz/de/0/0/1'
					break;
				case 'cafe':
					newPath ='/agroquiz/fr/0/0/1'
					break;
				case 'coffee':
					newPath ='/agroquiz/en/0/0/1'
					break;
				case 'zucker':
					newPath ='/agroquiz/de/2/0/1'
					break;
				case 'sucre':
					newPath ='/agroquiz/fr/2/0/1'
					break;
				case 'sugar':
					newPath ='/agroquiz/en/2/0/1'
					break;
				case 'kakao':
					newPath ='/agroquiz/de/3/0/1'
					break;
				case 'cacao':
					newPath ='/agroquiz/fr/3/0/1'
					break;
				case 'cocoa':
					newPath ='/agroquiz/en/3/0/1'
					break;
				case 'baumwolle':
					newPath ='/agroquiz/de/4/0/1'
					break;
				case 'coton':
					newPath ='/agroquiz/fr/4/0/1'
					break;
				case 'cotton':
					newPath ='/agroquiz/en/4/0/1'
					break;
				case 'soja-de':
					newPath ='/agroquiz/de/5/0/1'
					break;
				case 'soy':
					newPath ='/agroquiz/fr/5/0/1'
					break;
				case 'soja-fr':
					newPath ='/agroquiz/en/5/0/1'
					break;
				case 'bananen':
					newPath ='/agroquiz/de/6/0/1'
					break;
				case 'bananas':
					newPath ='/agroquiz/fr/6/0/1'
					break;
				case 'bananes':
					newPath ='/agroquiz/en/6/0/1'
					break;
				case 'orangensaft':
					newPath ='/agroquiz/de/7/0/1'
					break;
				case 'jusdorange':
					newPath ='/agroquiz/fr/7/0/1'
					break;
				case 'orangejuice':
					newPath ='/agroquiz/en/7/0/1'
					break;
				default:
					newPath ='/agroquiz/de/0'
					break;
			}
			this.props.history.push(newPath)
		}
	}
	render () {
		return (
			<div />
		);
	}
}

export default RedirectComponent
