import React, { Component } from 'react';
import {inject, observer} from 'mobx-react/index';
import ReactGA from 'react-ga';
import renderHTML from 'react-render-html';
import { Textfit } from 'react-textfit';

import './styles.scss';

const animationDuration = 1000

@inject('store')
@observer
class FlipcardsComponent extends Component {
	constructor (props) {
		super(props)
		this.state = {
			isFlipped: -1,
			hasSeenOneProblem: false
		}
		this.showBack = this.showBack.bind(this)
		this.hideBack = this.hideBack.bind(this)
	}
	showBack (e) {
		const id = parseInt(e.target.getAttribute('data-id'))
		console.log(id)
		this.setState({
			isFlipped: id,
		});
		ReactGA.event({
			category: this.props.store.journeyId,
			action: 'ShowProblem/' + id,
			label: this.props.store.language + '/' + this.props.store.journey.activeChapter + '/' + this.props.store.journey.activePage + '/' + this.props.store.journey.activeElement,
			value: id
		})
	}
	hideBack () {
		this.setState({
			fadeOverlayOut: true
		})
		setTimeout(() => {
			this.setState({
				isFlipped: -1,
				fadeOverlayOut: false
			})
		}, animationDuration * 1.1)
		if (this.state.hasSeenOneProblem) {
			return null
		} else {
			setTimeout(() => {
				this.setState({
					hasSeenOneProblem: true
				})
			}, animationDuration * 1.6)
		}
	}
	render () {
		const { hasSeenOneProblem, isFlipped } = this.state
		const { problems } = this.props.store
		const mappedCards = this.props.store.problems.map((el,i) => (
			<Flipcard
				openHandler={this.showBack}
				closeHandler={this.hideBack}
				key={`flipcard-${i}`}
				columns={3}
				rows={3}
				data={el}
				activeCard={this.state.isFlipped}
			/>
		))
		return (
			<div className={'flipcards-component flex-grid'}>
				{ mappedCards }
				{ isFlipped === -1 && hasSeenOneProblem && this.props.store.journey.activeChapter !== 9
				&&
				<div
					className={'controls'}
					style={{
						top: this.props.store.controlsPositionTop
					}}
				>
					<button className={'Button white'} onClick={() => this.props.store.goToFinishSlide()}>
						{this.props.store.uiElements.nextButtonText[this.props.store.language]}&nbsp;
						<i className={'icon long arrow right'} />
					</button>
				</div>
				}
				{this.state.isFlipped !== -1
				&&
				<div
					className={`flipcards-overlay ${this.state.fadeOverlayOut ? 'bounce-out-top' : 'bounce-in-top'}`}
					style={{
						height: (this.props.store.GLOBAL_VALUES.canvas.height - (1.5 * this.props.store.GLOBAL_VALUES.margin)) + 'px',
						animationDuration: animationDuration / 1000 + 's',
					}}
					onClick={this.hideBack}
				>
					<div
						className={'ui text container'}
						style={{
							textAlign: 'left'
						}}
					>
						<h1
							className="ui header"
							style={{
								background: problems.filter(card => card.id === this.state.isFlipped)[0].background
							}}
						>
							<img
								alt="icon for problem"
								className="ui small image"
								src={process.env.PUBLIC_URL + '/icons/' + problems.filter(card => card.id === this.state.isFlipped)[0].icon}
							/>
								<div className="content" style={{padding: '0 5px'}}>
									{ problems.filter(card => card.id === this.state.isFlipped)[0].name[this.props.store.language] }
								</div>
						</h1>
						<br />
						<div
							className={`overlay-content ${problems.filter(card => card.id === this.state.isFlipped)[0].content[this.props.store.language].length > 250 ? 'smaller' : ''}`}
							style={{
								color: problems.filter(card => card.id === this.state.isFlipped)[0].color === 'black' ? 'black' : 'inherit'
							}}
						>
							<Textfit max={22} mode="multi">
							{ renderHTML(problems.filter(card => card.id === this.state.isFlipped)[0].content[this.props.store.language]) }
							</Textfit>
						</div>
					</div>
					<div
						className={'close-button'}
					>
						<i className={'big icon remove'} />
					</div>
					<a href={ 'https://www.publiceye.ch' }
					   target={ '_blank' }
					>
						<div
							className={ 'canvas-logo' }
							style={{bottom: '-16px'}}
						/>
					</a>
				</div>
				}
			</div>
		);
	}
}

@inject('store')
@observer
class Flipcard extends Component {
	constructor (props) {
		super(props)
		this.state = {
			isFlipped: false
		}
		this.hideCard = this.hideCard.bind(this)
		this.showCard = this.showCard.bind(this)
	}
	showCard () {
		this.setState({
			isFlipped: true
		})
	}
	hideCard() {
		this.setState({
			isFlipped: false
		})
	}
	render () {
		// eslint-disable-next-line
		const {rows, columns, nextButton, data, openHandler, activeCard} = this.props;
		// eslint-disable-next-line
		const tileWidth = (this.props.store.GLOBAL_VALUES.canvas.width / columns) - (2 * this.props.store.GLOBAL_VALUES.margin)
		return (
			<div
				className="col flex-grid-thirds"
				style={{
					flex: '1 0 ' + 100 / this.props.columns +'%',
					height: (this.props.store.GLOBAL_VALUES.canvas.height - (1.5 * this.props.store.GLOBAL_VALUES.margin)) / rows + 'px',
					background: data.background,
					opacity: activeCard === -1 || activeCard === data.id ? 1 : 0.2
				}}
			>
				<img
					alt={'Icon of the problem'}
					src={process.env.PUBLIC_URL + '/icons/' + data.icon}
					width={'100%'}
					style={{
						width: '100%',
						height: '100%',
						objectFit: 'contain',
					}}
					data-id={data.id}
					onClick={openHandler}
				/>
			</div>
		)
	}
}

export default FlipcardsComponent
