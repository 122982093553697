import React, { Component } from 'react';
import {
	ComposableMap,
	ZoomableGroup,
	Geographies,
	Geography,
	// Markers,
	// Marker,
	Annotations,
	Annotation,
} from 'react-simple-maps';
import {inject, observer} from 'mobx-react';
// import { toJS } from 'mobx';
import ReactTooltip from 'react-tooltip';
import { Motion, spring } from 'react-motion';
import * as _ from 'underscore';

import { scaleLinear } from 'd3-scale';
import COLOURS from '../../constants/colors';
import World from './data/worldmap-new';
import './styles.scss';

const wrapperStyles = {
	width: '100%',
	maxWidth: 900,
	margin: '0 auto',
	// bottom: '8px',
	position: 'absolute',
	top: 0,
	left: 0
};

let maxCountryVotes = 0;

@inject('store')
@observer
class MapComponent extends Component {
	constructor(props) {
		super(props)
		this.state = {
			touchMessage: null,
			selected: [],
			center: [0, 20],
			zoom: 1,
			maxVotes: 0
		}
		this.handleZoomIn = this.handleZoomIn.bind(this)
		this.handleZoomOut = this.handleZoomOut.bind(this)
		this.handleCityClick = this.handleCityClick.bind(this)
		this.handleReset = this.handleReset.bind(this)
		this.handleClick = this.handleClick.bind(this)
		this.handleMoveEnd = this.handleMoveEnd.bind(this)
		this.handleWheel = this.handleWheel.bind(this)
	}
	componentDidMount() {
		this.setState({
			zoom: (this.props.store.GLOBAL_VALUES.WIDTH - 30) * 0.001 * this.props.zoom,
			center: [0,20],
			selected: [],
		});
	}
	componentDidUpdate(prevProps) {
		if (this.props.results) {
			const results = this.props.store.db[this.props.store.journey.activeChapter][this.props.store.journey.activePage][this.props.store.journey.activeElement + 1]
			console.log(results)
			const maxVotes = _.max(results)
			console.log(maxVotes)
			maxCountryVotes = maxVotes
		}
		if (prevProps.zoom !== this.props.zoom) {
			setTimeout(() => {
				this.setState({
					zoom: ((this.props.store.GLOBAL_VALUES.WIDTH - 30) * 0.001) * this.props.zoom,
					center: this.props.center,
				})
			}, 100 + 1000)
		}
		if (prevProps.disableOptimization !== this.props.disableOptimization) {
			const timeOut = this.props.disableOptimization ? 900 : 0
			setTimeout(() => {
				this.setState({
					disableOptimization: this.props.disableOptimization
				})
			}, timeOut + 1000)
		}
	}
	handleWheel(e) {
		if (!this.props.userZoom) {
			return
		}
		if (e.nativeEvent.wheelDelta > 0) {
			this.handleZoomIn()
		} else {
			this.handleZoomOut()
		}
	}
	handleZoomIn() {
		if (!this.props.userZoom) {
			return
		}
		this.setState({
			zoom: this.state.zoom === 4 ? 4 : this.state.zoom * 2,
		})
	}
	handleZoomOut() {
		if (!this.props.userZoom) {
			return
		}
		this.setState({
			zoom: this.state.zoom === 1 ? 1 : this.state.zoom / 2,
		})
	}
	handleCityClick(city) {
		this.setState({
			zoom: 2,
			center: city.coordinates,
		})
	}
	handleClick(geo) {
		if (this.props.results || !this.props.disableOptimization) {
			return
		}
		// // console.log(geo)
		const isSelected = this.state.selected.indexOf(geo.properties.adm0_a3) !== -1
		this.setState({
			selected: isSelected
				? this.state.selected.reduce((acc, cur) => {
					if (geo.properties.adm0_a3 !== cur) acc.push(cur)
					return acc
				}, [])
				: [geo.properties.adm0_a3]
		})
	}
	handleReset() {
		this.setState({
			center: [0,20],
			zoom: 1,
			selected: [],
		})
		this.forceUpdate();
	}
	
	handleMoveEnd(newCenter) {
		this.setState({
			center: newCenter
		})
	}
	render() {
		const popScale = scaleLinear()
			.domain([0, maxCountryVotes])
			.range(['#CFD8DC','#AEB8BC']);
		
		return (
			<div className={'map-component-wrapper'}>
				<a href={'https://www.publiceye.ch'}
				   target={'_blank'}
				>
					<div
						className={'canvas-logo'}
						style={{
							zIndex: 999,
							bottom: '-10px',
							right: '15px'
						}}
					/>
				</a>
				<div
					onWheel={this.handleWheel}
					id={'map-component'}
					style={{
						...wrapperStyles,
						border: this.props.borderWeight + 'px solid ' + COLOURS.red,
						height: '100%'
					}}
				>
					{this.state.touchMessage
					&&
					<div className={`touch-message ${this.state.touchMessage ? 'visible' : ''}`}>
						<i className={'icon expand arrows alternate'} />Move the map with two fingers
					</div>
					}
					{this.props.userZoom
					&&
					<div className={'button-container'}>
						<button
							className={'Button white'}
							onClick={this.handleZoomIn}
						>
							<i className={'big  icon zoom in'} />
						</button>
						<button
							className={'Button white'}
							onClick={this.handleZoomOut}
						>
							<i className={'big icon zoom out'} />
						</button>
					</div>
					}
					<Motion
						defaultStyle={{
							zoom: this.props.zoom,
							x: this.props.center[0],
							y: this.props.center[1],
						}}
						style={{
							zoom: spring(this.state.zoom, {stiffness: 170, damping: 26}),
							x: spring(this.state.center[0], {stiffness: 170, damping: 26}),
							y: spring(this.state.center[1], {stiffness: 170, damping: 26}),
						}}
					>
						{({zoom,x,y}) => (
							<ComposableMap
								projectionConfig={{
									scale: 270,
									rotation: [-10,0,0],
								}}
								width={this.props.store.GLOBAL_VALUES.canvas.width}
								height={this.props.store.GLOBAL_VALUES.canvas.height + this.props.store.GLOBAL_VALUES.margin}
								style={{
									width: '100%',
									height: 'auto',
									top: 0,
									bottom: 0
								}}
							>
								<ZoomableGroup
									center={[x,y]}
									zoom={zoom}
									disablePanning={this.props.disablePanning}
								>
									<Geographies geography={ World } disableOptimization={this.state.disableOptimization}>
										{(geographies, projection) =>
											geographies.map((geography, i) => {
												
												let userVotes = 0
												
												if (this.props.results) {
													const lastChapter = this.props.store.db[this.props.store.journey.activeChapter][this.props.store.journey.activePage][this.props.store.journey.activeElement - 1] ? this.props.store.db[this.props.store.journey.activeChapter][this.props.store.journey.activePage][this.props.store.journey.activeElement - 1] : this.props.store.db[this.props.store.journey.activeChapter][this.props.store.journey.activePage][this.props.store.journey.activeElement]
													userVotes = lastChapter[geography.properties.adm0_a3] ? lastChapter[geography.properties.adm0_a3] : 0
												}
												const isSelected = this.state.selected.indexOf(geography.properties.adm0_a3) !== -1
												const staticColour = this.props.results ? popScale(userVotes) : '#ddd'
												const fillColour = isSelected && !this.props.results ? COLOURS.vert : staticColour
												const resultColour = this.props.countryCode === geography.properties.adm0_a3 ? COLOURS.vert : staticColour
												let hoverColour = COLOURS.vert
												let pressedColour = COLOURS.vert
												let cursor = 'pointer'
												if (!this.props.results && !this.props.disableOptimization && !this.props.userInteraction) {
													hoverColour = '#ddd'
													pressedColour = '#ddd'
													cursor = 'not-allowed'
												}
												if (this.props.zoom === 1) {
													hoverColour = '#ddd'
													pressedColour = '#ddd'
													cursor = 'not-allowed'
												}
												
												if (geography.id !== '010') {
													return (
														<Geography
															onClick={this.handleClick}
															className={isSelected && this.props.results ? 'selected' : ''}
															key={i}
															data-tip={`${userVotes} ${userVotes === 1 ? this.props.store.uiElements.vote[this.props.store.language] : this.props.store.uiElements.votes[this.props.store.language]}`}
															geography={geography}
															projection={projection}
															style={{
																default: {
																	fill: this.props.results ? resultColour : fillColour,
																	stroke: '#607D8B',
																	strokeWidth: 0,
																	outline: 'none',
																},
																hover: {
																	fill: hoverColour,
																	stroke: COLOURS.red,
																	strokeWidth: 0,
																	outline: 'none',
																	cursor: cursor,
																},
																pressed: {
																	fill: pressedColour,
																	stroke: '#607D8B',
																	strokeWidth: 0,
																	outline: 'none',
																},
															}}
														/>
													)
												}
												return null
											})
										}
									</Geographies>
									{this.props.annotations
									&&
									<Annotations>
										{ this.props.annotations.map((annotation, i) => (
											<Annotation
												style={{
													display: annotation.isVisible ? '' : 'invisible'
												}}
												key={ `annotation-${ i }` }
												dx={ annotation.delta[0] }
												dy={ annotation.delta[1] }
												subject={ annotation.latlng }
												strokeWidth={ annotation.strokeWidth }
												stroke={ COLOURS.black }
											>
												<text
													className={`annotation white ${annotation.className}`}
													x={ 0 }
													y={ annotation.className === 'small' ? 5 : 10 }
												>
													{ annotation.textA[this.props.store.language] }
												</text>
												<text
													className={`annotation ${annotation.className}`}
													y={ annotation.className === 'small' ? 5 : 10 }>
													{ annotation.textA[this.props.store.language] }
												</text>
											</Annotation>
										))
										}
									</Annotations>
									}
								</ZoomableGroup>
							</ComposableMap>
						)}
					</Motion>
					{this.props.results
					&&
					<ReactTooltip />
					}
				</div>
				{this.props.nextButton
				&&
				<div
					className={'controls'}
					style={{
						top: this.props.store.controlsPositionTop
					}}
				>
					{
						this.state.selected.length > 0 && !this.props.results
							?
							<button
								className={'Button'}
								onClick={() => this.props.solutionHandler(this.state.selected)}
							>
								{this.props.store.uiElements.nextButtonText[this.props.store.language]} <i className={'icon long arrow right'} />
							</button>
							:
							<button
								className={'Button'}
								onClick={this.props.clickHandler}
								style={{display: this.props.userInteraction && this.state.selected.length < 1 ? 'none' : ''}}
							>
								{this.props.store.uiElements.nextButtonText[this.props.store.language]} <i className={'icon long arrow right'} />
							</button>
					}
				</div>
				}
			</div>
		)
	}
}

MapComponent.defaultProps = {
	zoom: 1,
	center: [0, 20]
}

export default MapComponent
