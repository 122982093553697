import COLOURS from './colors'

const PROBLEMS = [
	{
		id: 0,
		name: {
			de: 'Existenzsichernde Einkommen',
			en: 'Poverty wages',
			fr: 'Revenu vital'
		},
		content: {
			de: 'Das Recht auf einen angemessenen Lebensstandard ist ein international anerkanntes Menschenrecht. Trotzdem lebt eine Vielzahl der Kleinproduzenten und Arbeiterinnen, welche die international gehandelten Agrarrohstoffe anbauen, in bitterer Armut.',
			fr: 'Le droit à un niveau de vie suffisant est un droit humain. Pourtant, de nombreux petits producteurs et ouvriers agricoles qui cultivent les matières premières agricoles commercialisées dans le monde vivent dans une pauvreté extrême.',
			en: 'The right to an adequate standard of living is internationally recognised as a human right. Yet despite this, many of the small-scale producers and agricultural workers who cultivate internationally traded agricultural commodities live in abject poverty.'
		},
		image: 'existenzlohn.jpg',
		icon: 'einkommen.svg',
		background: COLOURS.red
	},
	{
		id: 1,
		name: {
			de: 'Zwangsarbeit',
			en: 'Forced labour',
			fr: 'Travail forcé'
		},
		image: 'zwangsarbeit.jpg',
		icon: 'zwangsarbeit.svg',
		content: {
			de: 'In der Land- und Forstwirtschaft sowie der Fischerei arbeiten mehr als 3,5 Millionen Menschen unter Formen moderner Sklaverei. Zwangsarbeit ist die häufigste Form, aber auch andere ausbeuterische Arbeitsbedingungen sind im Anbau vieler Agrarrohstoffe weit verbreitet.',
			fr: "Plus de 3,5 millions de personnes dans le monde travaillent dans des conditions assimilées à de l’esclavage dans l’agriculture, la pêche et l’exploitation forestière. Le type d’esclavage moderne le plus fréquent est le travail forcé, mais d'autres formes d'exploitation sont également largement répandues dans la culture de nombreuses matières premières agricoles.",
			en: 'Over 3.5 million people work in the agricultural, forestry and fishing sectors in conditions of modern slavery. Forced labour is the most common manifestation of this, but other forms of exploitative labour conditions are widespread in the cultivation of many agricultural commodities.'
		},
		background: COLOURS.red
	},
	{
		id: 2,
		name: {
			de: 'Kinderarbeit',
			en: 'Child labour',
			fr: 'Travail des enfants'
		},
		image: 'kinderarbeit.jpg',
		icon: 'kinderarbeit.svg',
		content: {
			de: 'Die Landwirtschaft ist für beinahe drei Viertel aller missbräuchlichen Kinderarbeit (71%) verantwortlich. Das sind weltweit 108 Millionen Kinder, die unter ausbeuterischen Bedingungen arbeiten müssen. Dies raubt den Kindern nicht nur ihre Kindheit und ihr Recht auf Bildung, sondern schmälert auch ihre Zukunftschancen.',
			fr: "C’est dans l’agriculture que le travail abusif des enfants est le plus répandu (71%). Cela représente 108 millions d'enfants exploités dans le monde. Le travail abusif des enfants ne les prive pas seulement de leur enfance: sans accès à l’éducation, ils n'ont que très peu de perspectives d'avenir.",
			en: 'The agricultural sector is responsible for nearly three quarters of all child labour worldwide  (71%) – that means that globally, 108 million children are being forced to work in exploitative conditions. This not only robs children of their childhood and right to an education, it also harms their future prospects.'
		},
		background: COLOURS.red
	},
	{
		id: 3,
		name: {
			de: 'Arbeitssicherheit',
			en: 'Safety at work',
			fr: 'Sécurité au travail'
		},
		image: 'arbeitssicherheit.jpg',
		icon: 'jobsicherheit.svg',
		content: {
			de: 'Die Arbeit im Anbau von Agrarrohstoffen birgt grosse gesundheitliche Risiken. Der Einsatz von Pestiziden ist neben dem Tragen schwerer Lasten oder dem Hantieren mit gefährlichen Werkzeugen eine der risikoreichsten Tätigkeiten. Immer wieder führt dies zu Vergiftungen, chronischen Erkrankungen oder gar Todesfällen – auch durch Suizid.',
			fr: "Le travail dans la production des matières premières agricoles comporte des risques sanitaires majeurs. L'utilisation de pesticides est l'une des activités les plus risquées, avec le port de lourdes charges ou la manipulation d'outils dangereux. Ils entraînent fréquemment des intoxications, des maladies chroniques, et parfois même la mort - y compris par suicide.",
			en: 'There are significant health risks involved in cultivating agricultural commodities. The task that carries the highest risk is the use of pesticides, alongside carrying heavy loads or handling dangerous equipment. These activities often  lead to cases of poisoning, chronic diseases or even death – including through suicide.'
		},
		background: COLOURS.red
	},
	{
		id: 4,
		name: {
			de: 'Was muss passieren?',
			en: 'What needs to happen?',
			fr: 'Que faire?'
		},
		image: 'waspassierenmuss.jpg',
		fontWeight: 'bold',
		icon: 'wasmachen.svg',
		content: {
			de: 'Die Schweiz als wohl wichtigste Drehscheibe im globalen Handel mit Agrarrohstoffen muss endlich Verantwortung übernehmen und den ausbeuterischen Geschäftspraktiken «ihrer» Händler ein Ende setzen. Verbindliche Massnahmen zur Einhaltung der Menschenrechte wie sie die Konzernverantwortungsinitiative fordert sind längst überfällig! Engagieren Sie sich mit uns, damit dies Realität wird.',
			fr: "En tant que plaque tournante du négoce mondial de matières premières agricoles, la Suisse doit enfin assumer ses responsabilités et mettre un terme aux abus perpétrés par «ses» négociants. Il est temps d’imposer à ces sociétés des règles juridiquement contraignantes pour le respect des droits humains, comme le demande l’initiative pour des multinationales responsables. Engagez-vous à nos côtés pour un «oui» dans les urnes!",
			en: 'As likely the world’s largest trading hub for agricultural commodities, Switzerland must finally live up to its responsibility and put an end to the exploitative business practices of ‘its’ traders. Binding measures on respecting human rights, such as those advocated by the Responsible Business Initiative, are long overdue! Join us to make this happen.'
		},
		background: COLOURS.blue,
		color: 'black'
	},
	{
		id: 5,
		name: {
			de: 'Abholzung',
			en: 'Deforestation',
			fr: 'Déforestation'
		},
		image: 'abholzung.jpg',
		icon: 'deforestation.svg',
		content: {
			de: 'Abholzung ist eines der häufigsten und gravierendsten Umweltprobleme im Anbau von Agrarrohstoffen. In Brasilien, Indonesien oder Ghana sind weite Teile von indigenem Land oder geschütztem Urwald bereits verschwunden, um Platz zu machen für Soja-, Palmöl- oder Kakaoplantagen. Dies beraubt die lokale Bevölkerung ihrer Lebensgrundlage, zerstört die Biodiversität und trägt zum Klimawandel bei.',
			fr: "La déforestation est l'un des problèmes environnementaux les plus répandus en lien avec la culture des matières premières agricoles. Au Brésil, en Indonésie ou au Ghana, de vastes étendues de terres autochtones ou de forêts primaires protégées ont déjà disparu pour faire place à d’immenses plantations de soja, d'huile de palme ou de cacao. La déforestation prive la population de ses moyens de subsistance, détruit la biodiversité et accélère le changement climatique.",
			en: 'Deforestation is one of the most common and serious environmental problems associated with the production of agricultural commodities. Large swathes of indigenous land or protected rainforest have already disappeared in Brazil, Indonesia and Ghana to make way for soy, palm oil or cocoa plantations. This deprives the local population of their livelihoods, destroys biodiversity and contributes to climate change.'
		},
		background: COLOURS.red
	},
	{
		id: 6,
		name: {
			de: 'Landkonflikte',
			en: 'Land conflicts',
			fr: 'Accaparement des terres'
		},
		image: 'landraub.jpg',
		icon: 'landgrabbing.svg',
		content: {
			de: 'Zwischen 2006 und 2016 wurden weltweit knapp 500 Fälle von Landraub dokumentiert. Bei grossflächigen Landkäufen handelt es sich nicht, wie häufig behauptet, um ungenutzte Flächen, sondern oft um von Kleinbauern bewirtschaftete Flächen, traditionelles Weideland, dicht besiedelte Gebiete oder fruchtbares Agrarland. Landraub zerstört Lebensgrundlagen und verletzt häufig die Rechte indigener Gruppen.',
			fr: "Entre 2006 et 2016, près de 500 cas d'accaparement de terres ont été documentés dans le monde. Les achats de terres à grande échelle concernent souvent des terres cultivées par des petits agriculteurs, des zones de pâturage traditionnelles ou des terres de parcours, des zones à forte densité de population voire des terres agricoles fertiles. L'accaparement de terres prive la population de ses moyens de subsistance; les violations des droits des peuples autochtones sont monnaie courante.",
			en: 'From 2006 to 2016, nearly 500 cases of land grabbing were documented globally. Contrary to the common claim  that that large-scale land acquisitions primarily involve unused land, such plots are often in fact cultivated by small-scale farmers, serve as traditional pasture, are densely populated or arable agricultural land. Land grabbing destroys livelihoods and often violates the rights of indigenous groups.'
		},
		background: COLOURS.red
	},
	{
		id: 7,
		name: {
			de: 'Steuervergehen',
			en: 'Tax dodging',
			fr: 'Infractions fiscales'
		},
		image: 'steuervergehen.jpg',
		icon: 'taxdodging.svg',
		content: {
			de: 'Im Rohstoffhandel ist das Risiko für Steuervergehen besonders hoch. Eine «beliebte» Strategie ist das sogenannte «Transfer Mispricing», die Manipulation von unternehmensinternen Verrechnungspreisen. Dabei manipulieren Mutter- und Tochtergesellschaften die Preise für interne Transaktionen, damit sie in Ländern mit höheren Steuersätzen niedrigere Gewinne ausweisen können. So werden den Produktionsländern dringend nötige Steuereinnahmen entzogen.',
			fr: "Le négoce de matières premières présente un risque considérable de manipulation ou d’utilisation abusive des prix de transfert intragroupe: les sociétés mères et les filiales manipulent les prix des transactions internes afin de déclarer moins de bénéfices dans les juridictions où les impôts sont plus élevés, et réduire ainsi leurs impôts. Ces pratiques privent les pays producteurs de ressources fiscales dont ils ont pourtant le plus grand besoin pour assurer le développement de leurs infrastructures.",
			en: 'The risk of tax dodging is particularly high in commodity trading. The favoured strategy is often ‘transfer mispricing’ which entails manipulating a company’s internal prices, i.e. enabling holding companies and subsidiaries to set the internal prices they pay for goods in such a way that they report lower profits in higher tax jurisdictions. This deprives producer countries of urgently needed tax revenue.'
		},
		background: COLOURS.red
	},
	{
		id: 8,
		name: {
			de: 'Korruption',
			en: 'Corruption',
			fr: 'Corruption',
		},
		image: 'korruption.jpg',
		icon: 'corruption.svg',
		content: {
			de: 'In den Produktionsländern sind Plantagebesitzerinnen und Produzenten oft Teil von oder eng mit der politischen Elite verbunden. Immer wieder kommt es zu mehr als fragwürdigen Geschäften zwischen diesen politisch exponierten Personen und Agrarhandelsfirmen. Anders als im Bankensektor gibt es für Agrarhändler keine spezifischen Vorschriften, welche diese daran hindern würden, Geschäfte mit politisch exponierten Personen zu tätigen.',
			fr: "Dans les pays producteurs, les propriétaires de plantations et les producteurs font souvent partie de l’élite politique, ou y sont étroitement liés. Les affaires douteuses entre ces personnes politiquement exposées (PEP) et des négociants en matières premières sont fréquentes. Contrairement au secteur bancaire, les activités de négoce ne sont soumises à aucune réglementation spécifique visant à empêcher les relations d’affaires avec des PEP.",
			en: 'In producer countries, agricultural producers or land and plantation owners are often members of, or closely linked to, the political elite. This enables them to exert considerable influence on global supply chains. These politically exposed persons often conclude extremely dubious deals with agricultural trading companies. Unlike in the banking sector, agricultural traders are not subject to any specific provisions preventing them from engaging in business activities with politically exposed persons.'
		},
		background: COLOURS.red
	}
]

export default PROBLEMS







































































































































































































































































































































































































































































































































































































































































































































































































































































































































