import React, { Component } from 'react';
import {inject, observer} from 'mobx-react';
import { Textfit } from 'react-textfit';
import { Helmet } from 'react-helmet';

import './styles.scss';

@inject('store')
@observer
class HeaderComponent extends Component {
	render() {
		return (
			<div
				id={'header-component'}
				className={'ui vertical aligned segment'}
			>
				<Helmet>
					<meta charSet="utf-8" />

					<title>{ this.props.store.social.og.title[this.props.store.language] }</title>

					<meta
						name="title"
						content={ this.props.store.social.og.title[this.props.store.language] }
					/>

					<meta
						name="description"
			      content={ this.props.store.social.og.description[this.props.store.language] }
					/>

					{/* OpenGraph tags */}
					<meta
						name="og:url"
						content={this.props.store.social.og.url[this.props.store.language]}
					/>
					<meta
						name="og:title"
						content={this.props.store.social.og.title[this.props.store.language]}
					/>
					<meta
						name="og:description"
						content={this.props.store.social.og.description[this.props.store.language]}
					/>
					<meta
						name="og:image"
						content={this.props.store.social.og.image[this.props.store.language]}
					/>
					<meta name="og:type" content="website" />
					{/* Twitter Card tags */}
					<meta
						name="twitter:title"
						content={this.props.store.social.twitter.title[this.props.store.language]}
					/>
					<meta
						name="twitter:description"
						content={this.props.store.social.twitter.description[this.props.store.language]}
					/>
					<meta
						name="twitter:image"
						content={this.props.store.social.twitter.image[this.props.store.language]}
					/>
					<meta
						name="twitter:card"
						content={'summary_large_image'}
					/>
					<meta
						name="twitter:creator"
						content={this.props.store.social.twitter.handle[this.props.store.language] || 'publiceye_ch'}
					/>
				</Helmet>
				<div className={'ui text container'} style={{maxWidth: '900px'}}>
					<div className="ui two column grid">
						<div className="row">
							<div className="middle aligned column">
								<a href={'https://www.publiceye.ch'} target={'_blank'}>
									{this.props.store.language === 'de' &&
									<img
										alt={'Public Eye Logo Deutsch'}
										src={'https://www.publiceye.ch/fileadmin/cd/assets/img/logo/2x/Public_Eye_Uebergangslogo_DE.png'}
										className={'header-logo'}
									/>
									}
									{this.props.store.language === 'fr' &&
									<img
										alt={'Public Eye Logo Deutsch'}
										src={'https://www.publiceye.ch/fileadmin/_processed_/7/1/csm_Public_Eye_Uebergangslogo_FRZ_d3ba447b5c.png'}
										className={'header-logo'}
									/>
									}
									{this.props.store.language === 'en' &&
									<img
										alt={'Public Eye Logo Deutsch'}
										src={'https://www.publiceye.ch/fileadmin/_processed_/1/c/csm_Public_Eye_Uebergangslogo_EN_7110b7239f.png'}
										className={'header-logo'}
									/>
									}
								</a>
							</div>
							<div className="center aligned column title">
								<h1
									className={'top-text app-title'}
									onClick={() => this.props.store.goToChapter(0,0,0)}
								>
									<Textfit mode="single" max={30}>
										{ this.props.store.uiElements.appTitle[this.props.store.language] }
									</Textfit>
								</h1>
							</div>
							<div className="column right aligned button-column" style={{paddingRight: '20px'}}>
								<Textfit mode="single" max={14}>
									<button
										className={`language-button ${this.props.store.language === 'de' ? 'active' : ''}`}
										onClick={() => this.props.store.language = 'de'}
									>
										DE
									</button>
									<button
										className={`language-button ${this.props.store.language === 'fr' ? 'active' : ''}`}
										onClick={() => this.props.store.language = 'fr'}
									>
										FR
									</button>
									<button
										className={`language-button ${this.props.store.language === 'en' ? 'active' : ''}`}
										onClick={() => this.props.store.language = 'en'}
									>
										EN
									</button>
								</Textfit>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default HeaderComponent
