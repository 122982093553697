import React, { Component } from 'react';
import {inject, observer} from 'mobx-react';

import './styles.scss';

@inject('store')
@observer
class GridComponent extends Component {
	constructor(props) {
		super(props)
		this.state = {
			chosenOnes: []
		}
		this.toggle = this.toggle.bind(this);
		this.goToChapter = this.goToChapter.bind(this);
		this.goToStart = this.goToStart.bind(this)
	}
	goToStart () {
		this.props.store.goToChapter(0,0,0)
	}
	goToChapter (chapter, page, element) {
		this.props.store.goToChapter(6, 0, element)
	}
	toggle(e) {
		const { data } = this.props
		console.log(e.target.id);
		if (!this.props.guessAmount || this.props.guessAmount < 0) {
			return
		}
		if (data === 'commodities') {
			this.props.store.journey.activeChapter = e.target.id
		}
		let tempArray = this.state.chosenOnes
		const id = e.target.id
		const index = this.state.chosenOnes.indexOf(id);
		if (index > -1) {
			tempArray.splice(index, 1);
		} else {
			if (tempArray.length === this.props.guessAmount) {
				tempArray.shift()
			}
			tempArray.push(id)
		}
		this.setState({
			chosenOnes: tempArray
		})
		this.props.store.guessTraders = tempArray
	}
	render() {
		const {rows, columns, nextButton, data, isDark, isFinishSlide, isQuiz} = this.props;
		// const currentHeight = ((window.innerWidth > 750 ? 750 / rows - (1.5 * this.props.store.GLOBAL_VALUES.margin) + 'px' : (window.innderWidth / 16 * 9) / rows - (1.5 * this.props.store.GLOBAL_VALUES.margin)) + 'px')
		const { chosenOnes } = this.state;
		const { traders, commodities, problems } = this.props.store;
		const tileWidth = (this.props.store.GLOBAL_VALUES.canvas.width / columns) - (2 * this.props.store.GLOBAL_VALUES.margin)
		const visibleCommodities = commodities.filter(commodity => commodity.visible)
		const TraderTiles = traders.map((trader, i) => {
			let isVisible = true
			if (this.props.store.results.correctAnswerArray) {
				if (this.props.store.results.correctAnswerArray.indexOf(trader.id) === -1) {
					isVisible = false
				}
			}
			return (
			<div key={ `trader-${ i }` }
			     style={ {
				     width: tileWidth + 'px',
				     height: (this.props.store.GLOBAL_VALUES.canvas.height - (1.5 * this.props.store.GLOBAL_VALUES.margin)) / rows + 'px',
				     flex: '1 0 ' + (100 / columns) + '%',
				     alignContent: 'center'
			     }}
			>
				<div
					className={ `tile ${ isVisible ? 'visible' : 'slide-out-blurred-top'} ${isDark ? '' : 'fade-in'}` }
				>
					<img
						style={ {
							height: 'auto',
							width: (this.props.store.GLOBAL_VALUES.canvas.width / columns) - (this.props.store.GLOBAL_VALUES.margin * 2) + 'px',
						}}
						onClick={ this.toggle }
						alt={ 'logo ' + trader.name }
						id={ trader.id }
						src={ require('./imgs/logos-assets/' + trader.id + '.jpg') }
						className={ `tile-image trader sing-in-top-bck ${ chosenOnes.length < 1 ? '' : 'inactive' } ${ chosenOnes.includes(trader.id) ? 'active' : '' }` }
					/>
				</div>
			</div>
		)});
		const CommodityTiles = visibleCommodities.map((commodity, i) => (
			<div
				key={`commodity-${i}`}
				style={{
					position: 'relative',
					float: 'left',
					width: 'calc(100% / 4)',
				}}>
				<img
					style={{
						width: '100%',
						padding: 0,
						objectFit: 'contain'
					}}
					onClick={this.toggle}
					alt={'icon ' + commodity.name}
					id={commodity.id}
					src={require('./imgs/icons-assets/' + commodity.icon)}
					className={`tile-image commodity-tile ${chosenOnes.includes(commodity.id.toString()) && this.props.guessAmount === 1 ? 'wobble-hor-top' : ''}`}
				/>
				<h3
					className={'commodity-title'}
					style={{
						display: chosenOnes.includes(commodity.id.toString()) ? 'block' : 'none',
						position: 'absolute'
					}}
					onClick={() => this.props.store.goToChapter(commodity.id, 0, 1)}
				>
					{commodity.name[this.props.store.language]}
				</h3>
			</div>
		))
		const ProblemTiles = problems.map((problem, i) => (
			<div
				key={`problem-${i}`}
				className={`problem tile container ${problem.image !== 'waspassierenmuss.jpg' ? '' : 'always-visible'}`}
				style={{
					flex: '0 1 33.33%',
					background: problem.image ? 'url(' + process.env.PUBLIC_URL + '/photos/' + problem.image +')' : this.props.store.colours.red,
					backgroundSize: 'cover',
				}}
				onClick={() => this.props.store.goToChapter(7, problem.id, 0)}
			>
				<div
					style={{
						width: '100%',
						height: (this.props.store.GLOBAL_VALUES.canvas.height - (this.props.store.GLOBAL_VALUES.margin * 1.5)) / rows + 'px',
					}}
				>
					<p
						alt={'icon ' + problem.name}
						id={problem.id}
						className={`tile-problem ${chosenOnes.includes(problem.id.toString()) ? 'active' : ''}`}
						style={{
							fontWeight: problem.fontWeight
						}}
						>
						{problem.name[this.props.store.language]}
					</p>
				</div>
			</div>
		))
		
		return (
			<div
				id={ 'grid-component' }
				className={`${this.props.store.activeElementContent.options.dark ? 'dark' : ''}`}
				style={{
					height: '100%'
				}}
			>
				{this.state.chosenOnes.length === parseInt(this.props.guessAmount)
				// eslint-disable-next-line
				&& this.props.guessAmount > 0
				// eslint-disable-next-line
				|| isDark
				// eslint-disable-next-line
				|| isFinishSlide
				// eslint-disable-next-line
				&&
				<div
					className={'controls'}
					style={{
						top: this.props.store.controlsPositionTop
					}}
				>
					<button
						style={{
							display: this.props.isFinishSlide && this.props.data === 'problems' && this.props.guessAmount === 0 ? 'none' : 'inherit'
						}}
						className={'Button'}
						ref={'next'}
						onClick={() => this.props.goToFinishSlide ? this.props.store.goToFinishSlide() : this.props.store.goFurther()}
					>
						{this.props.store.uiElements.nextButtonText[this.props.store.language]} <i className={'icon long arrow right'} />
					</button>
				</div>
				}
				{nextButton && this.state.chosenOnes.length >= this.props.guessAmount
				&&
				<div
					className={'controls'}
					style={{
						top: this.props.store.gridControlsPositionTop,
					}}
				>
					<button className={'Button'} onClick={() => this.props.store.goFurther()}>
						{this.props.store.uiElements.nextButtonText[this.props.store.language]} <i className={'icon long arrow right'} />
					</button>
				</div>
				}
				{isQuiz && this.state.chosenOnes.length >= this.props.guessAmount
				&&
				<div
					className={'controls'}
					style={{
						top: this.props.store.gridControlsPositionTop
					}}
				>
					<button className={'Button'} onClick={() => this.props.solutionHandler(this.state.chosenOnes)}>
						{this.props.store.uiElements.nextButtonText[this.props.store.language]} <i className={'icon long arrow right'} />
					</button>
				</div>
				}
				{data === 'traders'
				&&
				<div
					className={isDark ? 'spotlight' : ''}
					style={{
						paddingTop: 0,
						height: '100%'
					}}
				>
				{ TraderTiles }
				</div>
				}
				{data === 'commodities'
				&&
				<div style={{
					paddingTop: 0,
					height: '100%'
				}}>
				{ CommodityTiles }
				</div>
				}
				{data === 'problems'
				&&
				<div className={'problem-tiles-wrapper'}>
				{ProblemTiles}
				</div>
				}
				<a href={'https://www.publiceye.ch'}
				   target={'_blank'}
				>
					<div
						className={'canvas-logo'}
						style={{
							zIndex: 999,
							bottom: '-16px',
							right: '10px',
							display: data === 'problems' ? 'none' : 'block'
						}}
					/>
				</a>
			</div>
		)
	}
}

export default GridComponent
