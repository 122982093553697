// VARIABLES FOR COLOURS
const COLOURS = {
	red: 'rgb(255,0,64)',
	blue: '#12c3e1',
	brown: '#8b1921',
	black: '#3b3b34',
	violet: '#5b004d',
	mer: '#006397',
	vert: '#c6cc00',
	jaune: '#ffe300',
	grey: '#aaa'
}

export default COLOURS
