import React from 'react'
import { NavLink } from 'react-router-dom'
import ROUTES from '../../constants/routes'
import renderHTML from 'react-render-html'

import '../../scss/main.scss'
import './styles.scss'

class StartComponent extends React.Component {
	constructor (props) {
		super(props)
		this.state = {
			quote: undefined
		}
	}
	componentDidMount () {
		fetch('https://quotesondesign.com/wp-json/posts?filter[orderby]=rand&filter[posts_per_page]=1')
			.then((res) => res.json())
			.then((data) => this.setState({
				quote: data[0].content
			}))
	}
	render () {
		return (
			<div id={'start'} data-iframe-height>
				<div
					className={'ArticleLead'}
				>
					<NavLink to={'/'}>
						<img src={require('./imgs/logo.svg')} alt={'Public Eye Logo'} width={'100%'} style={{maxWidth: '200px'}} />
					</NavLink>
					<br />
					Infographic Embeddables
				</div>
				<div className={'ArticleMetaInfo'}>
					<div className={'ArticleMetaInfo--content'}>
						Soft Commodities, Version {process.env.REACT_APP_DEPLOYED_VERSION} by Marc Fehr
						<div className='ui text menu'>
							<div className='header item'>Elemente</div><br />
							{ROUTES.filter((route) => route.isVisible).map((route,index) => (
								<NavLink to={route.link}
								      activeClassName={'active'}
								      className='item'
								      key={`link-${index}`}
								>
									{route.title}
								</NavLink>
							))}
						</div>
					</div>
				</div>
				<div className={'ArticleText'}>
					<div className={'ArticleText--inner'}>
						<div>
							{!this.state.quote ? (
								<div className='ui active centered inline loader' />
							) : (
								<div><strong>Random quote: </strong>{ renderHTML(this.state.quote) }</div>
							)}
						</div>
					</div>
				</div>
			</div>
		)
	}
}
export default StartComponent;
