import React, { Component } from 'react';
import {inject, observer} from 'mobx-react/index';

import './styles.scss';

@inject('store')
@observer
class OrientationComponent extends Component {
	componentWillUnmount () {
		console.log('UNMOUNTED')
	}
	render() {
		return (
			<div
				id={'orientation-lock'}
			>
				<img alt={"Please tilt your phone!"} src={require('./imgs/turn-phone.svg')} width='100%' />
				<div className={'orientation-text'}>
					<h3>
						{this.props.store.uiElements.orientationLockMessage[this.props.store.language]}
					</h3>
				</div>
			</div>
		)
	}
}

export default OrientationComponent
