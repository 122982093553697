import COLOURS from './colors'

const STORY = [
	{
		commodity: 'Coffee',
		chapter: 0,
		pages: [
			{
				actions: ['clearWorld'],
				elements: [
					{
						options: {
							content: 'commodities',
							columns: 4,
							rows: 2,
							nextButton: true,
							guessAmount: 1,
						},
						text: {
							de: 'Wählen Sie einen Agrarrohstoff.',
							en: 'Choose a soft commodity.',
							fr: 'Choisissez une matière première agricole.'
						},
						uiElement: 'grid',
					},
					{
						text: {
							en: "<span className='red'>Vietnam</span> is one of the world’s biggest coffee exporters. Do you know where Vietnam is? Click on the right country.</span>",
							de: "<span className='red'>Vietnam</span> ist einer der grössten Kaffee-Exporteure weltweit. Vietnam? Wissen Sie wo das liegt? Klicken Sie auf das Land, um Ihren Tipp abzugeben.</span>",
							fr: "<span className='red'>Le Vietnam</span> est l’un des plus grands exportateurs de café au monde. Où se situe le Vietnam? Cliquez sur le bon pays."
						},
						actions: ['nextElement'],
						options: {
							center: [0, 20],
							zoom: 1,
							disableZoom: true,
							disablePanning: true,
							timeOut: 3200
						},
						uiElement: 'map'
					},
					{
						text: {
							en: "<span className='red'>Vietnam</span> is one of the world’s biggest coffee exporters. Do you know where Vietnam is? <span className='red blink-1'> Click on the right country.</span>",
							de: "<span className='red'>Vietnam</span> ist einer der grössten Kaffee-Exporteure weltweit. Vietnam? Wissen Sie wo das liegt? <span className='red blink-1'>Klicken Sie auf das Land, um Ihren Tipp abzugeben.</span>",
							fr: "<span className='red'>Le Vietnam</span> est l’un des plus grands exportateurs de café au monde. Où se situe le Vietnam? <span className='red blink-1'>Cliquez sur le bon pays.</span>"
						},
						ations: ['clearWorld'],
						options: {
							countryCode: 'VNM',
							zoom: 4,
							center: [85, 21],
							disableZoom: true,
							disablePanning: true,
							disableOptimization: true,
							nextButton: true,
							userInteraction: true,
						},
						uiElement: 'map'
					},
					{
						text: {
							en: "IGNORE THIS TEXT",
							de: "IGNORE THIS TEXT",
							fr: "IGNORE THIS TEXT"
						},
// actions: ['nextElement'],
						options: {
							resultText: true,
							timeOut: 2000,
							disableZoom: false,
							disablePanning: false,
							countryCode: 'VNM',
							zoom: 4,
							center: [85, 21],
							annotations: [
								{
									delta: [-60, 0],
									latlng: [108, 14],
									textA: {de: 'Vietnam', en: 'Vietnam', fr: 'Viêt-Nam'},
									className: 'small',
									result: true,
									fontSize: [20, 10],
									dataSrc: -1,
								},
							],
							results: true,
							nextButton: true,
						},
						uiElement: 'map'
					},
					{
						options: {
							amount: 32,
							color: COLOURS.mer,
							nextButton: true,
							icon: 'coffee-mer'
						},
						actions: ['clearWorld', 'addCircles'],
						text: {
							en: 'Every year <span className="text-mer">Vietnam</span> exports 1.64 million tonnes of coffee. Each of these 32 beans represents approx. 50,000 tonnes of coffee.',
							de: "<span className='text-mer'>Vietnam</span> exportiert jährlich rund 1,64 Millionen Tonnen Kaffee. Jede dieser 32 Bohnen steht somit für rund 50'000 Tonnen Kaffee.",
							fr: "<span className='text-mer'>Le Vietnam</span> exporte chaque année 1,64 million de tonnes de café environ. Chacun de ces 32 grains représente environ 50'000 tonnes de café."
						},
						uiElement: 'redbox',
					},
					{
						options: {
							amount: 33,
							color: COLOURS.jaune,
							timeOut: 2000,
							icon: 'coffee-jaune'
						},
						actions: ['addCircles'],
						text: {
							en: "<span className='text-jaune'>Brazil</span> exports slightly more coffee, namely 1.65 million tonnes.",
							de: "Nur unbedeutend mehr exportiert <span className='text-jaune'>Brasilien</span>, nämlich 1,65 Millionen Tonnen.",
							fr: "<span className='text-jaune'>Le Brésil</span> en exporte légèrement plus: 1,65 million de tonnes par an."
						},
						uiElement: 'redbox',
					},
					{
						options: {
							amount: 14,
							color: COLOURS.violet,
							timeOut: 2000,
							icon: 'coffee-brown'
						},
						actions: ['addCircles'],
						text: {
							en: "The third largest coffee exporter is <span className='text-violet'>Colombia</span>, with 710,000 tonnes of coffee.",
							de: "Drittgrösster Exporteur ist <span className='text-violet'>Kolumbien</span> mit 710'000 Tonnen Kaffee.",
							fr: "<span className='text-violet'>La Colombie</span> est le troisième plus grand exportateur de café au monde, avec 710'000 tonnes."
						},
						uiElement: 'redbox',
					},
					{
						options: {
							amount: 67,
							color: COLOURS.grey,
							timeOut: 2500,
							icon: 'coffee-blue'
						},
						actions: ['addCircles'],
						text: {
							en: "In total, 7.36 million tonnes of coffee are traded on the global market every year. That quantity would fill a freight train reaching from <span className='red'>Zurich to Lisbon.</span>",
							de: "Insgesamt werden jedes Jahr 7,36 Millionen Tonnen Kaffee auf dem Weltmarkt gehandelt. Ein Güterzug mit dieser Ladung würde von <span className='red'>Biel bis Lissabon</span> reichen.",
							fr: "Chaque année, 7,36 millions de tonnes de café sont négociées sur le marché mondial. Un train de marchandises transportant une telle cargaison serait aussi long que la distance qui sépare <span className='red'>Bienne de Lisbonne</span>."
						},
						uiElement: 'redbox',
					},
					{
						options: {
							timeOut: 2000,
							colours: [
								{
									colour: COLOURS.red,
									value: 100
								}
							],
							direction: 'toIcon',
							icon: 'coffee-red'
						},
						actions: ['iconToggle'],
						text: {
							en: "<span className='red'>What does that have to do with Switzerland? </span> <br />Switzerland is heavily involved in global agricultural trading.",
							de: "<span className='red'>Was hat die Schweiz damit zu tun?</span> <br />Die Schweiz mischt im globalen Agrarhandel kräftig mit.",
							fr: "<span className='red'>Quel est le rapport avec la Suisse?</span> <br />La Suisse joue un rôle central dans le négoce mondial de matières premières agricoles."
						},
						uiElement: 'redbox',
					},
					{
						amount: 0,
						options: {
							nextResult: 30,
							nextButton: true,
							solutionButton: true
						},
						text: {
							en: "What do you think? <span className='red'>What is Switzerland’s market share</span> in global coffee trade?",
							de: "Was denken Sie: <span className='red'>Welcher Anteil </span>des globalen Kaffeehandels wird über die Schweiz abgewickelt?",
							fr: "À votre avis, <span className='red'>quel pourcentage </span>du négoce mondial de café est réalisé via la Suisse?"
						},
						uiElement: 'slider',
					},
					{
						amount: 0,
						actions: ['gravityToggle'],
						options: {
							timeOut: 2000,
							gravityFree: 30,
							resultText: true,
						},
						text: {
							en: "IGNORE",
							de: "IGNORE",
							fr: "IGNORE"
						},
						uiElement: 'redbox',
					},
					{
						amount: 0,
						actions: [''],
						text: {
							en: "This makes Switzerland <span className='red'>one of the biggest</span> – if not the biggest – trading hub for coffee.",
							de: "Somit ist <span className='red'>die kleine Schweiz</span> global einer der wichtigsten, wenn nicht gar <span className='red'>der wichtigste Handelsplatz </span>für Kaffee.",
							fr: "<span className='red'>La Suisse</span> est ainsi l’une des principales places mondiales de négoce de café, si ce n'est pas <span className='red'>la plus importante</span>."
						},
						options: {
							timeOut: 2500,
							backToStartButton: false,
							nextButton: true
						},
						uiElement: 'redbox',
					},
				]
			},
			{
				id: 'storyline2',
				elements: [
					{
						options: {
							content: 'traders',
							rows: 3,
							columns: 5,
							guessAmount: -1,
							nextButton: true,
							quiz: false,
							isDark: true,
							nextResult: ['ldc', 'olam', 'ecom', 'cofco', 'volcafe', 'hkg', 'sucafina'],
						},
						text: {
							en: 'Some of the most powerful agricultural traders are based in <span className="red">Switzerland</span>. Few people know these companies – <span className="red">do you</span>?',
							de: 'In der <span className="red">Schweiz</span> sind einige der mächtigsten globalen Agrarhändler beheimatet. <span className="red">Kaum jemand kennt diese Firmen</span> – und Sie?',
							fr: "Plusieurs négociants agricoles figurant parmi les plus puissants au monde sont domiciliés <span className='red'>en Suisse</span>. Mais beaucoup de gens l’ignorent. Et vous, <span className='red'>connaissez-vous les noms de ces sociétés?</span>"
						},
						uiElement: 'grid',
					},
					{
						options: {
							content: 'traders',
							rows: 3,
							columns: 5,
							guessAmount: 2,
							nextButton: false,
							quiz: true,
							nextResult: ['ldc', 'olam', 'ecom', 'cofco', 'volcafe', 'hkg', 'sucafina'],
						},
						text: {
							en: 'Do you know which of these 15 powerful companies trade large quantities of <span className="red">coffee</span>? Select<span className="red"> two firms</span> and click on continue.',
							de: 'Wissen Sie, welche dieser 15 mächtigen Unternehmen in grossem Stil mit <span className="red">Kaffee</span> handeln? Wählen Sie <span className="red">zwei Firmen</span> aus und klicken Sie auf Weiter</span>, um die Auflösung zu sehen.',
							fr: "Savez-vous lesquelles de ces 15 sociétés sont actives dans le négoce de <span className='red'>café</span>? Choisissez <span className='red'>deux sociétés </span> et cliquez sur «suivant»."
						},
						uiElement: 'grid',
					},
					{
						actions: ['stopGuessListener'],
						options: {
							content: 'traders',
							rows: 3,
							columns: 5,
							nextButton: true,
							guessAmount: -1,
							resultText: true,
							timeOut: 0
						},
						text: {
							en: 'IGNORE THIS TEXT',
							de: 'IGNORE THIS TEXT',
							fr: "IGNORE THIS TEXT"
						},
						uiElement: 'grid',
					},
					{
						options: {
							nextButton: true,
							infographic: 'hkg.png',
							fadeIn: true
						},
						text: {
							en: 'These companies dominate not only the global trade – their influence over the<span className="red"> entire value chain </span>is constantly growing. <br/>Let’s look at the Neumann Kaffee Gruppe as an example.',
							de: 'Diese <span className="red">Schweizer Firmen</span> dominieren nicht nur den globalen Handel – auch ihr Einfluss auf die <span className="red">gesamte Wertschöpfungskette</span> wächst laufend. Nehmen wir zum Beispiel die <span className="fat">Neumann Kaffee Gruppe.</span>',
							fr: "Ces sociétés ne dominent pas seulement le négoce mondial de matières premières agricoles: leur influence s’étend sur <span className='red'>l’ensemble de la chaîne de création de valeur</span> – et elle ne cesse de croître. Prenons l'exemple du groupe <span className='red'>Neumann Kaffee</span>."
			},
			uiElement: 'graphic',
},
{
	actions: ['clearWorld'],
		options: {
	infographic: 'coffee.png',
		nextButton: true,
},
	text: {
		en: 'The world’s largest coffee trader<span className="red"> owns plantations</span>, e.g. in Brazil and Uganda.',
			de: 'Der weltgrösste Kaffeehändler besitzt auch <span className="red">eigene Plantagen</span>, z.B. in Brasilien und in Uganda.',
			fr: "Premier négociant de café au monde, le groupe <span className='red'>Neumann est aussi propriétaire de plantations</span>, notamment au Brésil et en Ouganda."
	},
	uiElement: 'graphic',
},
{
	options: {
		goToProblems: true,
			color: COLOURS.red,
			timeOut: 2500,
			companyIcon: 'hkg.png',
			elements: [
			{
				icon: 'truck',
				amount: 7,
				color: COLOURS.red
			},
			{
				icon: 'factory',
				amount: 3,
				color: COLOURS.blue
			},
			{
				icon: 'container',
				amount: 8,
				color: COLOURS.mer
			},
			{
				icon: 'warehouse',
				amount: 4,
				color: COLOURS.jaune
			},
		],
	},
	actions: ['addIcons'],
		text: {
	en: 'In addition to this, <span className="red">Neumann </span>also has warehouses and processing facilities, and its own logistics company.',
		de: 'Daneben verfügt<span className="red"> Neumann </span>auch über Lagerhäuser, Verarbeitungsanlagen und eine eigene Logistikfirma.',
		fr: "Le <span className='red'>groupe Neumann </span>possède également des entrepôts et des usines de transformation, ainsi que sa propre entreprise de logistique."
},
	uiElement: 'redbox',
},
]
},
]
},
{
	commodity: 'Grains',
		chapter: 1,
	pages: [
	{
		actions: ['clearWorld'],
		elements: [
			{
				options: {
					content: 'commodities',
					columns: 4,
					rows: 2,
					nextButton: true,
					guessAmount: 1,
				},
				text: {
					de: 'Wählen Sie einen Agrarrohstoff.',
					en: 'Choose a soft commodity.',
					fr: 'Choisissez une matière première agricole.'
				},
				actions: ['clearWorld'],
				uiElement: 'grid',
			},
			{
				text: {
					en: "<span className='red'>Ukraine</span> is one of the world’s biggest grain exporters. Do you know where Ukraine is? Click on the right country.",
					de: "<span className='red'>Die Ukraine</span> ist einer der grössten Getreide-Exporteure weltweit. Wissen Sie wo die Ukraine liegt? Klicken Sie auf das Land, um Ihren Tipp abzugeben.",
					fr: "<span className='red'>L’Ukraine</span> est l’un des plus grands exportateurs de céréales au monde. Où se situe l’Ukraine? Cliquez sur le bon pays."
				},
				actions: ['nextElement'],
				options: {
					center: [0, 20],
					zoom: 1,
					disableZoom: true,
					disablePanning: true,
					timeOut: 3200,
				},
				uiElement: 'map'
			},
			{
				text: {
					en: "<span className='red'>Ukraine</span> is one of the world’s biggest grain exporters. Do you know where Ukraine is? <span className='red blink-1'>Click on the right country.</span>",
					de: "<span className='red'>Die Ukraine</span> ist einer der grössten Getreide-Exporteure weltweit. Wissen Sie wo die Ukraine liegt? <span className='red blink-1'>Klicken Sie auf das Land, um Ihren Tipp abzugeben.</span>",
					fr: "<span className='red'>L’Ukraine</span> est l'un des plus grands exportateurs de céréales au monde. Où se situe l’Ukraine? <span className='red blink-1'>Cliquez sur le bon pays.</span>"
				},
				actions: ['clearWorld'],
				options: {
					countryCode: 'UKR',
					zoom: 4,
					center: [48, 36],
					disableZoom: true,
					disablePanning: true,
					disableOptimization: true,
					nextButton: true,
					userInteraction: true,
				},
				uiElement: 'map'
			},
			{
				text: {
					en: "IGNORE THIS TEXT",
					de: "IGNORE THIS TEXT ",
					fr: 'IGNORE THIS TEXT'
				},
				options: {
					resultText: true,
					timeOut: 2000,
					disableZoom: false,
					disablePanning: false,
					countryCode: 'UKR',
					zoom: 4,
					center: [85, 21],
					annotations: [
						{
							delta: [60, 20],
							latlng: [35, 48],
							textA: {de: 'Ukraine', fr: 'Ukraine', en: 'Ukraine'},
							className: 'small',
							result: true,
							fontSize: [20, 10],
							dataSrc: -1,
						},
					],
					results: true,
					nextButton: true,
				},
				uiElement: 'map'
			},
			{
				options: {
					amount: 21,
					color: COLOURS.brown,
					timeOut: 3200,
					icon: 'grain-brown'
				},
				actions: ['clearWorld', 'addCircles'],
				text: {
					en: 'Every year<span className="red"> Ukraine </span>exports approximately 41 million tonnes of grains. Each of these 21 ears represents approx. 2 million tonnes of grain.',
					de: "<span className='text-brown'>Die Ukraine</span> exportiert jährlich rund 41 Millionen Tonnen Getreide. Jede dieser 21 Ähren steht somit für rund 2 Millionen Tonnen Getreide.",
					fr: '<span className="red">L’Ukraine</span> exporte chaque année 41 millions de tonnes de céréales environ. Chacun de ces 21 épis représente 2 millions de tonnes de céréales.'
				},
				uiElement: 'redbox',
			},
			{
				options: {
					amount: 27,
					color: COLOURS.mer,
					timeOut: 2500,
					icon: 'grain-mer'
				},
				actions: ['addCircles'],
				text: {
					en: "<span className='text-mer'>Russia</span> exports even more grain, namely 54 million tonnes...",
					de: "Noch mehr Getreide exportieren <span className='text-mer'>Russland</span>, nämlich 54 Millionen Tonnen...",
					fr: '<span className="text-mer">La Russie</span> exporte encore plus de céréales: 54 millions de tonnes...'
				},
				uiElement: 'redbox',
			},
			{
				options: {
					amount: 48,
					color: COLOURS.red,
					timeOut: 3500,
					icon: 'grain-blue'
				},
				actions: ['addCircles'],
				text: {
					en: "... and <span className='text-blue'>the US</span> exports as much as 95 million tonnes which makes it the biggest exporter.",
					de: "... und <span className='text-blue'>die USA</span>. Sie sind mit über 95 Millionen Tonnen grösste Exporteurin.",
					fr: "… et <span className='text-blue'>les États-Unis</span>, premier exportateur au monde, plus de 95 millions de tonnes."
				},
				uiElement: 'redbox',
			},
			{
				options: {
					amount: 126,
					color: COLOURS.grey,
					timeOut: 4000,
					icon: 'grain-vert'
				},
				actions: ['addCircles'],
				text: {
					en: "In total, 443 million tonnes of grain are traded on the global market every year. That quantity would fill a freight train reaching around the world<span className='red'> two and a half times</span>!",
					de: "Insgesamt werden jedes Jahr 443 Millionen Tonnen Getreide auf dem Weltmarkt gehandelt. Ein Güterzug mit dieser Ladung würde <span className='red'>zweieinhalb Mal um die Erde</span> reichen!",
					fr: "Chaque année, 443 millions de tonnes de céréales sont négociées sur le marché mondial. La longueur d’un train de marchandises transportant une telle cargaison <span className='red'>ferait 2,5 fois le tour de la terre</span>."
				},
				uiElement: 'redbox',
			},
			{
				options: {
					timeOut: 2000,
					colours: [
						{
							colour: COLOURS.red,
							value: 100
						}
					],
					direction: 'toIcon',
					icon: 'grain-red'
				},
				actions: ['iconToggle'],
				text: {
					en: "<span className='red'>What does that have to do with Switzerland? </span> <br />Switzerland is heavily involved in global agricultural trading.",
					de: "<span className='red'>Was hat die Schweiz damit zu tun?</span> <br />Die Schweiz mischt im globalen Agrarhandel kräftig mit.",
					fr: "<span className='red'>Quel est le rapport avec la Suisse?</span> <br />La Suisse joue un rôle central dans le négoce mondial de matières premières agricoles."
				},
				uiElement: 'redbox',
			},
			{
				amount: 0,
				options: {
					nextResult: 50,
					nextButton: true,
					solutionButton: true
				},
				text: {
					en: "What do you think? <span className='red'>What is Switzerland’s market share</span> in global grain trade?",
					de: "Was denken Sie: <span className='red'>Welcher Anteil </span>des globalen Getreidehandels wird über die Schweiz abgewickelt?",
					fr: "À votre avis, <span className='red'>quel pourcentage </span>du négoce mondial de céréales est réalisé via la Suisse?"
				},
				uiElement: 'slider',
			},
			{
				amount: 0,
				actions: ['gravityToggle'],
				options: {
					timeOut: 2000,
					gravityFree: 50,
					resultText: true,
				},
				text: {
					en: "IGNORE THIS TEXT",
					de: "IGNORE THIS TEXT",
					fr: "IGNORE THIS TEXT"
				},
				uiElement: 'redbox',
			},
			{
				amount: 0,
				actions: [''],
				text: {
					en: "This makes Switzerland <span className='red'>the biggest trading hub </span>for grain.",
					de: "<span className='red'>Die kleine Schweiz</span> ist global <span className='red'>der wichtigste Handelsplatz</span> für Getreide.",
					fr: '<span className="red">La Suisse</span> est ainsi la <span className="red">première place mondiale </span> de négoce de céréales.'
				},
				options: {
					timeOut: 2500,
					backToStartButton: false,
				},
				uiElement: 'redbox',
			},
		]
	},
	{
		id: 'storyline2',
		elements: [
			{
				options: {
					content: 'traders',
					rows: 3,
					columns: 5,
					guessAmount: -1,
					nextButton: true,
					quiz: false,
					isDark: true,
					nextResult: ['ldc', 'olam', 'ecom', 'cofco', 'volcafe', 'hkg', 'sucafina'],
				},
				text: {
					en: 'Some of the most powerful agricultural traders are based in <span className="red">Switzerland</span>. Few people know these companies – <span className="red">do you</span>?',
					de: 'In der <span className="red">Schweiz</span> sind einige der mächtigsten globalen Agrarhändler beheimatet. <span className="red">Kaum jemand kennt diese Firmen</span> – und Sie?',
					fr: "Plusieurs négociants agricoles figurant parmi les plus puissants au monde sont domiciliés <span className='red'>en Suisse</span>. Mais beaucoup de gens l’ignorent. Et vous, <span className='red'>connaissez-vous les noms de ces sociétés?</span>"
				},
				uiElement: 'grid',
			},
			{
				options: {
					content: 'traders',
					rows: 3,
					columns: 5,
					guessAmount: 2,
					nextButton: false,
					quiz: true,
					nextResult: ['ldc', 'olam', 'cargil', 'cofco', 'glencore', 'bunge', 'adm', 'ecom'],
				},
				text: {
					en: 'Do you know which of these 15 powerful companies trade large quantities of grains? <span className="red blink-1">Select two firms and click on continue</span>.',
					de: 'Wissen Sie, welche dieser 15 mächtigen Unternehmen in grossem Stil mit Getreide handeln? <span className="red blink-1">Wählen Sie zwei Firmen aus</span> und klicken Sie auf Weiter.',
					fr: "Savez-vous lesquelles de ces 15 sociétés sont actives dans le négoce de céréales? <span className='red blink-1'>Choisissez deux</span> sociétés et cliquez sur «suivant»."
				},
				uiElement: 'grid',
			},
			{
				actions: ['stopGuessListener'],
				options: {
					content: 'traders',
					rows: 3,
					columns: 5,
					nextButton: true,
					guessAmount: -1,
					resultText: true,
					timeOut: 0
				},
				text: {
					en: 'IGNORE THIS TEXT',
					de: 'IGNORE THIS TEXT',
					fr: 'IGNORE THIS TEXT'
				},
				uiElement: 'grid',
			},
			{
				options: {
					nextButton: true,
					infographic: 'adm.png',
					fadeIn: true
				},
				text: {
					en: 'These companies dominate not only the global trade – their influence over the<span className="red"> entire value chain </span>is constantly growing. Let’s look at <span className="red">Archer Daniels Midland (ADM)</span> as an example.',
					de: 'Diese Firmen dominieren nicht nur den globalen Handel - ihr Einfluss auf die <span className="red"> gesamte Wertschöpfungskette</span> wächst laufend. Nehmen wir zum Beispiel <span className="red">Archer Daniels Midland (ADM)</span>.',
					fr: "Ces sociétés ne dominent pas seulement le négoce mondial de matières premières agricoles: leur influence s’étend sur <span className='red'>l’ensemble de la chaîne de création de valeur</span>. Prenons par exemple <span className='red'>Archer Daniels Midland (ADM)</span>."
				},
				uiElement: 'graphic',
			},
			{
				actions: ['clearWorld'],
				options: {
					infographic: 'grains.svg',
					nextButton: true
				},
				text: {
					en: '<span class Name="red">ADM</span> owns 100,000 ha of farmland in Ukraine. That equates to <span className="red"> the area of ​​the Canton of Thurgau</span>. ADM also owns 250 processing plants and 200 warehouses, mostly for corn and oilseeds.',
					de: '<span class Name="red">ADM</span> besitzt in der Ukraine 100\'000 ha Ackerfläche. Das entspricht <span class Name="red">der Fläche des Kantons Thurgau</span>. ADM gehören zudem über 250 Verarbeitungsanlagen und 200 Lagerhäuser, vor allem für Mais und Ölsaaten.',
					fr: '<span className="red">ADM</span> est propriétaire de 100\'000 hectares de terres agricoles. Cela correspond <span className="red"> à la surface du canton de Thurgovie</span>. ADM détient en outre plus de 250 usines de transformation et 200 entrepôts, surtout pour le maïs et les oléagineux.'
				},
				uiElement: 'graphic',
			},
			{
				options: {
					goToProblems: true,
					whatMattersText: true,
					color: COLOURS.red,
					timeOut: 2500,
					companyIcon: 'adm.png',
					elements: [
						{
							icon: 'boat',
							amount: 10,
							color: COLOURS.red
						},
						{
							icon: 'bigboat',
							amount: 3,
							color: COLOURS.blue
						},
						{
							icon: 'waggon',
							amount: 8,
							color: COLOURS.mer
						},
						{
							icon: 'truck',
							amount: 4,
							color: COLOURS.jaune
						},
						{
							icon: 'trailer',
							amount: 3,
							color: COLOURS.jaune
						},
					]
				},
				actions: ['addIcons'],
				text: {
					en: 'In addition to this, ADM has 1,800 barges, 12,000 railway carriages, 360 trucks, 1,200 trailers, 100 boats and 10 seagoing vessels – one of the biggest transport networks of all the traders.',
					de: 'Hinzu kommt mit 1800 Lastkähnen, 12\'000 Zugwaggons, 360 Lastwagen, 1200 Anhängern, 100 Booten und 10 Hochseeschiffen eines der grössten Transportnetzwerke aller Händler.',
					fr: 'ADM dispose aussi de 1\'800 péniches, 12\'000 wagons, 360 camions, 1\'200 remorques, 100 bateaux et 10 paquebots, soit le plus grand réseau de transport parmi les négociants.'
				},
				uiElement: 'redbox',
			},
		]
	},
]
},
{
	commodity: 'Sugar',
		chapter: 2,
	pages: [
	{
		actions: ['clearWorld'],
		elements: [
			{
				options: {
					content: 'commodities',
					columns: 4,
					rows: 2,
					nextButton: true,
					guessAmount: 1,
				},
				text: {
					de: 'Wählen Sie einen Agrarrohstoff.',
					en: 'Choose a soft commodity.',
					fr: 'Choisissez une matière première agricole.'
				},
				uiElement: 'grid',
			},
			{
				text: {
					en: "<span className='red'>Thailand</span> is one of the world’s biggest sugar exporters. Do you know where Thailand is? Click on the right country.",
					de: "<span className='red'>Thailand</span> ist einer der grössten Zucker-Exporteure weltweit. Thailand? Wissen Sie wo das liegt? Klicken Sie auf das Land, um Ihren Tipp abzugeben.",
					fr: '<span className="red">La Thaïlande</span> est l’un des plus grands exportateurs de sucre au monde. Où se situe la Thaïlande? Cliquez sur le bon pays.'
				},
				actions: ['nextElement'],
				options: {
					center: [0, 20],
					zoom: 1,
					disableZoom: true,
					disablePanning: true,
					timeOut: 3200
				},
				uiElement: 'map'
			},
			{
				text: {
					en: "<span className='red'>Thailand</span> is one of the world’s biggest sugar exporters. Do you know where Thailand is? <span className='red blink-1'>Click on the right country.</span>",
					de: "<span className='red'>Thailand</span> ist einer der grössten Zucker-Exporteure weltweit. Thailand? Wissen Sie wo das liegt? <span className='red blink-1'>Klicken Sie auf das Land, um Ihren Tipp abzugeben.</span>",
					fr: '<span className="red">La Thaïlande</span> est l’un des plus grands exportateurs de sucre au monde. Où se situe la Thaïlande?<span className="red blink-1"> Cliquez sur le bon pays.</span>'
				},
				ations: ['clearWorld'],
				options: {
					countryCode: 'THA',
					zoom: 4,
					center: [79, 23],
					disableZoom: true,
					disablePanning: true,
					disableOptimization: true,
					nextButton: true,
					userInteraction: true,
				},
				uiElement: 'map'
			},
			{
				text: {
					en: "IGNORE THIS TEXT",
					de: "IGNORE THIS TEXT",
					fr: "IGNORE THIS TEXT"
				},
				options: {
					resultText: true,
					timeOut: 2000,
					disableZoom: false,
					disablePanning: false,
					countryCode: 'THA',
					zoom: 4,
					center: [85, 21],
					annotations: [
						{
							delta: [-60, -20],
							latlng: [101, 16],
							textA: {de: 'Thailand', en: 'Thailand', fr: 'Thaïlande'},
							className: 'small',
							result: true,
							fontSize: [20, 10],
							dataSrc: -1,
						},
					],
					results: true,
					nextButton: true,
				},
				uiElement: 'map'
			},
			{
				options: {
					amount: 32,
					color: COLOURS.brown,
					timeOut: 3200,
					icon: 'sugar-brown'
				},
				actions: ['clearWorld', 'addCircles'],
				text: {
					en: 'Every year <span className="text-brown">Thailand</span> exports approximately 7.9 million tonnes of sugar. Each of these 32 canes represents 250,000 tonnes of sugar.',
					de: "<span className='text-brown'>Thailand</span> exportiert jährlich rund 7,9 Millionen Tonnen Zucker. Jedes dieser 32 Zuckerrohre steht somit für rund 250'000 Tonnen des süssen Rohstoffs.",
					fr: '<span className="text-brown">La Thaïlande</span> exporte chaque année environ 7,9 millions de tonnes de sucre. Chacune de ces 32 cannes à sucre représente environ 250\'000 tonnes de sucre.'
				},
				uiElement: 'redbox',
			},
			{
				options: {
					amount: 85,
					color: COLOURS.jaune,
					timeOut: 2500,
					icon: 'sugar-jaune'
				},
				actions: ['addCircles'],
				text: {
					en: "<span className='text-jaune'>Brazil</span> exports nearly three times more sugar, namely 21.3 million tonnes.",
					de: "Noch mehr Zucker exportiert nur <span className='text-jaune'>Brasilien</span>: mit 21,3 Millionen Tonnen fast dreimal so viel.",
					fr: "Seul <span className='text-jaune'>le Brésil</span> en exporte davantage: 21,3 millions de tonnes du sucre, soit près de trois fois plus."
				},
				uiElement: 'redbox',
			},
			{
				options: {
					amount: 13,
					color: COLOURS.blue,
					timeOut: 1850,
					icon: 'sugar-blue'
				},
				actions: ['addCircles'],
				text: {
					en: "The third largest exporter is <span className='text-blue'>France</span>, with 3.2 million tonnes of sugar.",
					de: "Drittgrösster Exporteur ist <span className='text-blue'>Frankreich</span> mit 3,2 Millionen Tonnen Zucker.",
					fr: "<span className='text-blue'>La France</span> est le troisième plus grand exportateur de sucre, avec 3,2 millions de tonnes."
				},
				uiElement: 'redbox',
			},
			{
				options: {
					amount: 95,
					color: COLOURS.grey,
					timeOut: 3000,
					icon: 'sugar-vert'
				},
				actions: ['addCircles'],
				text: {
					en: "In total, 56.2 million tonnes of sugar are traded on the global market every year. That quantity would fill a freight train reaching <span className='red'>from Berne to Shanghai</span>.",
					de: "Insgesamt werden jedes Jahr 56,2 Millionen Tonnen Zucker auf dem Weltmarkt gehandelt. Ein Güterzug mit dieser Ladung würde <span className='red'>von Bern bis Shanghai</span> reichen.",
					fr: "Chaque année, 56,2 millions de tonnes de sucre sont négociées sur le marché mondial. Un train de marchandises transportant une telle cargaison serait aussi long que la distance qui sépare <span className='red'>Berne de Shanghai</span>."
				},
				uiElement: 'redbox',
			},
			{
				options: {
					timeOut: 2500,
					colours: [
						{
							colour: COLOURS.red,
							value: 100
						},
					],
					icon: 'sugar-red',
					direction: 'toIcon'
				},
				actions: ['iconToggle'],
				text: {
					en: "<span className='red'>What does that have to do with Switzerland? </span> <br />Switzerland is heavily involved in global agricultural trading.",
					de: "<span className='red'>Was hat die Schweiz damit zu tun?</span> <br />Die Schweiz mischt im globalen Agrarhandel kräftig mit.",
					fr: "<span className='red'>Quel est le rapport avec la Suisse?</span> <br />La Suisse joue un rôle central dans le négoce mondial de matières premières agricoles."
				},
				uiElement: 'redbox',
			},
			{
				amount: 0,
				options: {
					nextResult: 40,
					nextButton: true,
					solutionButton: true
				},
				text: {
					en: "What do you think? <span className='red'>What ist Switzerland’s market share</span> in global sugar trade?",
					de: "Was denken Sie: Welcher Anteil des globalen Zuckerhandels wird <span className='red'>über die Schweiz</span> abgewickelt?",
					fr: "À votre avis, quel pourcentage du négoce mondial de café est <span className='red'>réalisé via la Suisse</span>?"
				},
				uiElement: 'slider',
			},
			{
				amount: 0,
				actions: ['gravityToggle'],
				options: {
					timeOut: 2000,
					gravityFree: 40,
					resultText: true,
				},
				text: {
					en: "IGNORE THIS TEXT",
					de: "IGNORE THIS TEXT",
					fr: 'IGNORE THIS TEXT'
				},
				uiElement: 'redbox',
			},
			{
				amount: 0,
				actions: ['fadeButton'],
				text: {
					en: "This makes Switzerland <span className='red'>one of the biggest</span> – if not the biggest – trading hub for sugar.",
					de: "<span className='red'>Die kleine Schweiz</span> ist somit global einer der wichtigsten, wenn nicht gar <span className='red'>der wichtigste Handelsplatz </span>für Zucker.",
					fr: "<span className='red'>La Suisse</span> est ainsi l’une des principales places mondiales de négoce de sucre, si ce n'est pas <span className='red'>la plus importante</span>."
				},
				options: {
					timeOut: 1500,
					backToStartButton: false,
				},
				uiElement: 'redbox',
			},
		]
	},
	{
		id: 'storyline2',
		elements: [
			{
				options: {
					content: 'traders',
					rows: 3,
					columns: 5,
					guessAmount: -1,
					nextButton: true,
					quiz: false,
					isDark: true,
					nextResult: ['ldc', 'olam', 'ecom', 'cofco', 'volcafe', 'hkg', 'sucafina'],
				},
				text: {
					en: 'Some of the most powerful agricultural traders are based in <span className="red">Switzerland</span>. Few people know these companies – <span className="red">do you</span>?',
					de: 'In der <span className="red">Schweiz</span> sind einige der mächtigsten globalen Agrarhändler beheimatet. <span className="red">Kaum jemand kennt diese Firmen</span> – und Sie?',
					fr: "Plusieurs négociants agricoles figurant parmi les plus puissants au monde sont domiciliés <span className='red'>en Suisse</span>. Mais beaucoup de gens l’ignorent. Et vous, <span className='red'>connaissez-vous les noms de ces sociétés?</span>"
				},
				uiElement: 'grid',
			},
			{
				options: {
					content: 'traders',
					rows: 3,
					columns: 5,
					guessAmount: 2,
					nextButton: false,
					quiz: true,
					nextResult: ['ldc', 'olam', 'cargil', 'ecom', 'cofco', 'glencore', 'bunge', 'alvean'],
				},
				text: {
					en: 'Do you know which of these 15 powerful companies trade large quantities of sugar? <span className="red">Select two firms and click on continue</span>.',
					de: 'Wissen Sie, welche dieser 15 mächtigen Unternehmen in grossem Stil mit <span className=\'red\'>Zucker</span> handeln? Wählen Sie <span className=\'red\'>zwei Firmen </span>aus und klicken Sie auf Weiter.',
					fr: "Savez-vous lesquelles de ces 15 sociétés sont actives dans le négoce de sucre? <span className='red'>Choisissez deux sociétés </span> et cliquez sur «suivant»."
				},
				uiElement: 'grid',
			},
			{
				actions: ['stopGuessListener'],
				options: {
					content: 'traders',
					rows: 3,
					columns: 5,
					nextButton: true,
					guessAmount: -1,
					resultText: true,
					timeOut: 0
				},
				text: {
					en: 'IGNORE THIS TEXT',
					de: 'IGNORE THIS TEXT',
					fr: 'IGNORE THIS TEXT'
				},
				uiElement: 'grid',
			},
			{
				options: {
					nextButton: true,
					infographic: 'ldc.png',
					fadeIn: true
				},
				text: {
					en: 'These companies dominate not only the global trade – their influence over the<span className="red"> entire value chain </span>is constantly growing. <br/>Let’s look at Louis Dreyfus Company as an example.',
					de: 'Diese Firmen dominieren nicht nur den globalen Handel - ihr Einfluss auf die gesamte Wertschöpfungskette wächst laufend. <br/>Nehmen wir zum Beispiel die Louis Dreyfus Company',
					fr: "Ces sociétés ne dominent pas seulement le négoce mondial de matières premières agricoles: leur influence s’étend sur l’ensemble de la chaîne de création de valeur – et elle ne cesse de croître. <br/>Prenons l'exemple du groupe <span className='red'>Louis Dreyfus</span>."
				},
				uiElement: 'graphic',

			},
			{
				actions: ['clearWorld'],
				options: {
					infographic: 'sugar.svg',
					nextButton: true,
				},
				text: {
					en: 'Louis Dreyfus’ sugar plantations cover a greater surface area than that of<span className="red"> all the arable land </span>in Switzerland.',
					de: 'Die Zuckerrohrplantagen von Louis Dreyfus sind grösser als die <span className="red">gesamte Ackerfläche der Schweiz</span>.',
					fr: "Les plantations de cannes à sucre de Louis Dreyfus sont plus vastes que <span className='red'>l’ensemble du territoire suisse</span>."
				},
				uiElement: 'graphic',
			},
			{
				options: {
					goToProblems: true,
					color: COLOURS.red,
					timeOut: 2500,
					companyIcon: 'ldc.png',
					elements: [
						{
							icon: 'factory',
							amount: 2,
							color: COLOURS.red
						},
						{
							icon: 'bigboat',
							amount: 200,
							color: COLOURS.blue
						},
					],
				},
				actions: ['addIcons'],
				text: {
					en: 'In addition, Louis Dreyfus has two sugar refineries and over 200 ships.',
					de: 'Daneben stehen Louis Dreyfus auch zwei Zuckerraffinerien und über 200 Schiffe zur Verfügung.',
					fr: 'Louis Dreyfus possède également deux raffineries de sucre et une flotte de plus de 200 navires.'
				},
				uiElement: 'redbox',
			},
		]
	},
]
},
{
	commodity: 'Cocoa',
		chapter: 0,
	pages: [
	{
		actions: ['clearWorld'],
		elements: [
			{
				options: {
					content: 'commodities',
					columns: 4,
					rows: 2,
					nextButton: true,
					guessAmount: 1,
				},
				text: {
					de: 'Wählen Sie einen Agrarrohstoff.',
					en: 'Choose a soft commodity.',
					fr: 'Choisissez une matière première agricole.'
				},
				uiElement: 'grid',
			},
			{
				text: {
					en: "<span className='red'>Côte d'Ivoire</span> is the world’s biggest cocoa exporter. Do you know where Côte d'Ivoire is? Click on the right country.</span>",
					de: "<span className='red'>Côte d'Ivoire</span> ist der grösste Kakao-Exporteur weltweit. Côte d'Ivoire? Wissen Sie wo das liegt? Klicken Sie auf das Land, um Ihren Tipp abzugeben.</span>",
					fr: "La <span className='red'>Côte d’Ivoire</span> est le premier exportateur mondial de cacao. Où se situe la Côte d'Ivoire? Cliquez sur le bon pays."
				},
				actions: ['nextElement'],
				options: {
					center: [0, 20],
					zoom: 1,
					disableZoom: true,
					disablePanning: true,
					timeOut: 3200
				},
				uiElement: 'map'
			},
			{
				text: {
					en: "<span className='red'>Côte d'Ivoire</span> is the world’s biggest cocoa exporter. Do you know where Côte d'Ivoire is? <span className='red blink-1'> Click on the right country.</span>",
					de: "<span className='red'>Côte d'Ivoire</span> ist der grösste Kakao-Exporteur weltweit. Côte d'Ivoire? Wissen Sie wo das liegt? <span className='red blink-1'>Klicken Sie auf das Land, um Ihren Tipp abzugeben.</span>",
					fr: "La <span className='red'>Côte d’Ivoire</span> est le premier exportateur mondial de cacao. Où se situe la Côte d'Ivoire? <span className='red blink-1'>Cliquez sur le bon pays.</span>"
				},
				ations: ['clearWorld'],
				options: {
					countryCode: 'CIV',
					zoom: 4,
					center: [12, 0],
					disableZoom: true,
					disablePanning: true,
					disableOptimization: true,
					nextButton: true,
					userInteraction: true,
				},
				uiElement: 'map'
			},
			{
				text: {
					en: "IGNORE THIS TEXT",
					de: "IGNORE THIS TEXT",
					fr: "IGNORE THIS TEXT"
				},
// actions: ['nextElement'],
				options: {
					resultText: true,
					timeOut: 2000,
					disableZoom: false,
					disablePanning: false,
					countryCode: 'CIV',
					zoom: 4,
					center: [12, 0],
					annotations: [
						{
							delta: [60, 0],
							latlng: [-6, 7.5],
							textA: {de: 'Côte d\'Ivoire', en: 'Côte d\'Ivoire', fr: 'Côte d\'Ivoire'},
							className: 'small',
							result: true,
							fontSize: [20, 10],
							dataSrc: -1,
						},
					],
					results: true,
					nextButton: true,
				},
				uiElement: 'map'
			},
			{
				options: {
					amount: 76,
					color: COLOURS.mer,
					timeOut: 3200,
					icon: 'cocoa-mer'
				},
				actions: ['clearWorld', 'addCircles'],
				text: {
					en: 'Every year <span className="text-mer">Côte d\'Ivoire</span> exports 1.5 million tonnes of cocoa. Each of these 76 beans represents approximately 20,000 tonnes of cocoa.',
					de: "<span className='text-mer'>Côte d'Ivoire</span> exportiert jährlich rund 1,5 Millionen Tonnen Kakao. Jede dieser 76 Bohnen steht somit für rund 20'000 Tonnen Kakao. ",
					fr: "La <span className='text-mer'>Côte d'Ivoire</span> exporte chaque année 1,5 million de tonnes de cacao environ. Chacun de ces 76 grains représente environ 20'000 tonnes de cacao."
				},
				uiElement: 'redbox',
			},
			{
				options: {
					amount: 42,
					color: COLOURS.jaune,
					timeOut: 3200,
					icon: 'cocoa-jaune'
				},
				actions: ['addCircles'],
				text: {
					en: "The second biggest exporter is <span className='text-jaune'>Ghana</span>, with 844,000 tonnes of cocoa.",
					de: "Zweitgrösster Exporteur ist <span className='text-jaune'>Ghana</span> mit 844'000 Tonnen Kakao.",
					fr: "Le <span className='text-jaune'>Ghana</span> est le deuxième plus grand exportateur de cacao, avec 844'000 tonnes."
				},
				uiElement: 'redbox',
			},
			{
				options: {
					amount: 15,
					color: COLOURS.violet,
					timeOut: 2000,
					icon: 'cocoa-brown'
				},
				actions: ['addCircles'],
				text: {
					en: "<span className='text-violet'>Ecuador</span> follows in third place with nearly 300,000 tonnes of cocoa.",
					de: "Drittgrösster Exporteur ist <span className='text-violet'>Ecuador</span> mit knapp 300'000 Tonnen Kakao.",
					fr: "<span className='text-violet'>L'Équateur</span> est en troisième place, avec près de 300'000 tonnes de cacao."
				},
				uiElement: 'redbox',
			},
			{
				options: {
					amount: 69,
					color: COLOURS.grey,
					timeOut: 2500,
					icon: 'cocoa-blue'
				},
				actions: ['addCircles'],
				text: {
					en: "In total, a good 4 million tonnes of cocoa are traded on the global market every year. That quantity would fill a freight train reaching from <span className='red'>Geneva to Seville.</span>",
					de: "Insgesamt werden jedes Jahr gut 4 Millionen Tonnen Kakao auf dem Weltmarkt gehandelt. Ein Güterzug mit dieser Ladung würde von <span className='red'>Genf bis Sevilla</span> reichen.",
					fr: "En tout, 4 millions de tonnes de cacao sont négociées chaque année sur le marché mondial. Un train de marchandises transportant une telle cargaison serait aussi long que la distance qui sépare <span className='red'>Genève de Séville</span>."
				},
				uiElement: 'redbox',
			},
			{
				options: {
					timeOut: 2000,
					colours: [
						{
							colour: COLOURS.red,
							value: 100
						}
					],
					direction: 'toIcon',
					icon: 'cocoa-red'
				},
				actions: ['iconToggle'],
				text: {
					en: "<span className='red'>What does that have to do with Switzerland? </span> <br />Switzerland is heavily involved in global agricultural trading.",
					de: "<span className='red'>Was hat die Schweiz damit zu tun?</span> <br />Die Schweiz mischt im globalen Agrarhandel kräftig mit.",
					fr: "<span className='red'>Quel est le rapport avec la Suisse?</span> <br />La Suisse joue un rôle central dans le négoce mondial de matières premières agricoles."
				},
				uiElement: 'redbox',
			},
			{
				amount: 0,
				options: {
					nextResult: 30,
					nextButton: true,
					solutionButton: true
				},
				text: {
					en: "What do you think? <span className='red'>What is Switzerland’s market share</span> in global cocoa trade?",
					de: "Was denken Sie: <span className='red'>Welcher Anteil </span>des globalen Kakaohandels wird über die Schweiz abgewickelt?",
					fr: "À votre avis, <span className='red'>quel pourcentage </span>du négoce mondial de cacao est réalisé via la Suisse?"
				},
				uiElement: 'slider',
			},
			{
				amount: 0,
				actions: ['gravityToggle'],
				options: {
					timeOut: 2000,
					gravityFree: 30,
					resultText: true,
				},
				text: {
					en: "IGNORE",
					de: "IGNORE",
					fr: "IGNORE"
				},
				uiElement: 'redbox',
			},
			{
				amount: 0,
				actions: [''],
				text: {
					en: "This makes Switzerland <span className='red'>one of the biggest</span> – if not the biggest – trading hub for cocoa.",
					de: "Somit ist <span className='red'>die kleine Schweiz</span> global einer der wichtigsten, wenn nicht gar <span className='red'>der wichtigste Handelsplatz </span>für Kakao.",
					fr: "<span className='red'>La Suisse</span> est ainsi l’une des principales places de négoce de cacao au monde, si ce n'est pas <span className='red'>la plus importante</span>."
				},
				options: {
					timeOut: 2500,
					backToStartButton: false,
				},
				uiElement: 'redbox',
			},
		]
	},
	{
		id: 'storyline2',
		elements: [
			{
				options: {
					content: 'traders',
					rows: 3,
					columns: 5,
					guessAmount: -1,
					nextButton: true,
					quiz: false,
					isDark: true,
					nextResult: ['ldc', 'olam', 'ecom', 'cofco', 'volcafe', 'hkg', 'sucafina'],
				},
				text: {
					en: 'Some of the most powerful agricultural traders are based in <span className="red">Switzerland</span>. Few people know these companies – <span className="red">do you</span>?',
					de: 'In der <span className="red">Schweiz</span> sind einige der mächtigsten globalen Agrarhändler beheimatet. <span className="red">Kaum jemand kennt diese Firmen</span> – und Sie?',
					fr: "Plusieurs négociants agricoles figurant parmi les plus puissants au monde ont leur siège <span className='red'>en Suisse</span>. Mais beaucoup de gens l'ignorent. Et vous, <span className='red'>connaissez-vous les noms de ces sociétés?</span>"
				},
				uiElement: 'grid',
			},
			{
				options: {
					content: 'traders',
					rows: 3,
					columns: 5,
					guessAmount: 2,
					nextButton: false,
					quiz: true,
					nextResult: ['olam', 'cargil', 'ecom'],
				},
				text: {
					en: 'Do you know which of these 15 powerful companies trade large quantities of <span className="red">cocoa</span>? Select<span className="red"> two firms</span> and click on continue.',
					de: 'Wissen Sie, welche dieser 15 mächtigen Unternehmen in grossem Stil mit <span className="red">Kakao</span> handeln? Wählen Sie <span className="red">zwei Firmen</span> aus und klicken Sie auf Weiter</span>, um die Auflösung zu sehen.',
					fr: "Parmi les 15 sociétés suivantes, lesquelles sont actives dans le négoce de <span className='red'>cacao</span>? Choisissez <span className='red'>deux sociétés </span> et cliquez sur «suivant»."
				},
				uiElement: 'grid',
			},
			{
				actions: ['stopGuessListener'],
				options: {
					content: 'traders',
					rows: 3,
					columns: 5,
					nextButton: true,
					guessAmount: -1,
					resultText: true,
					timeOut: 0
				},
				text: {
					en: 'IGNORE THIS TEXT',
					de: 'IGNORE THIS TEXT',
					fr: "IGNORE THIS TEXT"
				},
				uiElement: 'grid',
			},
			{
				options: {
					nextButton: true,
					infographic: 'cargill.png',
					fadeIn: true
				},
				text: {
					en: 'These companies dominate not only the global trade – their influence over the<span className="red"> entire value chain </span>is constantly growing. <br/>Let’s look at <span className="fat">Cargill</span> as an example.',
					de: 'Diese <span className="red">Schweizer Firmen</span> dominieren nicht nur den globalen Handel – auch ihr Einfluss auf die <span className="red">gesamte Wertschöpfungskette</span> wächst laufend. Nehmen wir zum Beispiel <span className="fat">Cargill</span>.',
					fr: 'Ces sociétés ne dominent pas seulement le négoce mondial de matières premières agricoles: leur influence s’étend sur <span className="red">l’ensemble de la chaîne de création de valeur</span> – et elle ne cesse de croître. Prenons l\'exemple de <span className="red">Cargill</span>.'
				},
				uiElement: 'graphic',
			},
			{
				actions: ['clearWorld'],
				options: {
					infographic: 'cocoa.png',
					nextButton: true,
				},
				text: {
					en: 'Cargill is the <span className="red">second largest</span> cocoa trader and processor in the world – it has a 20% market share.',
					de: 'Cargill ist der <span className="red">weltweit zweitgrösste Kakaohändler</span> und -verarbeiter mit einem Marktanteil von ca. 20%.',
					fr: "Cargill est le <span className='red'>deuxième négociant et transformateur de cacao au monde</span>, avec environ 20% de part de marché."
				},
				uiElement: 'graphic',
			},
			{
				options: {
					goToProblems: true,
					color: COLOURS.red,
					timeOut: 2500,
					companyIcon: 'cargill.png',
					elements: [
						{
							icon: 'truck',
							amount: 7,
							color: COLOURS.red
						},
						{
							icon: 'factory',
							amount: 3,
							color: COLOURS.blue
						},
						{
							icon: 'container',
							amount: 8,
							color: COLOURS.mer
						},
						{
							icon: 'warehouse',
							amount: 4,
							color: COLOURS.jaune
						},
					],
				},
				actions: ['addIcons'],
				text: {
					en: 'In addition, <span className="red">Cargill </span>owns its own purchasing company for cocoa in Ghana, which – unlike many other firms – enables the company to buy cocoa directly from farmers.',
					de: 'Zudem besitzt<span className="red"> Cargill </span>eine eigene Einkaufsgesellschaft für Kakao in Ghana, die es der Firma – im Gegensatz zu vielen anderen – ermöglicht, direkt bei Kakaobauern einzukaufen.',
					fr: "<span className='red'> Cargill </span>possède également sa propre société d’achat de cacao au Ghana, ce qui lui permet, contrairement à de nombreuses autres entreprises, de se fournir directement auprès des cacaoculteurs et cacaocultrices."
				},
				uiElement: 'redbox',
			},
		]
	},
]
},
{
	commodity: 'Cotton',
		chapter: 0,
	pages: [
	{
		actions: ['clearWorld'],
		elements: [
			{
				options: {
					content: 'commodities',
					columns: 4,
					rows: 2,
					nextButton: true,
					guessAmount: 1,
				},
				text: {
					de: 'Wählen Sie einen Agrarrohstoff.',
					en: 'Choose a soft commodity.',
					fr: 'Choisissez une matière première agricole.'
				},
				uiElement: 'grid',
			},
			{
				text: {
					en: "<span className='red'>Brazil</span> is one of the world’s biggest cotton producers. Do you know where Brazil is? Click on the right country.</span>",
					de: "<span className='red'>Brasilien</span> ist einer der grössten Baumwoll-Exporteure weltweit. Wissen Sie wo Brasilien liegt? <span className='red blink-1'>Klicken Sie auf das Land, um Ihren Tipp abzugeben.</span>",
					fr: "<span className='red'>Le Brésil</span> est l’un des plus grands exportateurs de coton au monde. Où se situe le Brésil? Cliquez sur le bon pays."
				},
				actions: ['nextElement'],
				options: {
					center: [0, 20],
					zoom: 1,
					disableZoom: true,
					disablePanning: true,
					timeOut: 3200
				},
				uiElement: 'map'
			},
			{
				text: {
					en: "<span className='red'>Brazil</span> is one of the world’s biggest cotton producers. Do you know where Brazil is? <span className='red blink-1'> Click on the right country.</span>",
					de: "<span className='red'>Brasilien</span> ist einer der grössten Baumwoll-Exporteure weltweit. Wissen Sie wo Brasilien liegt? <span className='red blink-1'>Klicken Sie auf das Land, um Ihren Tipp abzugeben.</span>",
					fr: "<span className='red'>Le Brésil</span> est l’un des plus grands exportateurs de coton au monde. Où se situe le Brésil? <span className='red blink-1'>Cliquez sur le bon pays.</span>"
				},
				ations: ['clearWorld'],
				options: {
					countryCode: 'BRA',
					zoom: 1.8,
					center: [-56, -16],
					disableZoom: true,
					disablePanning: true,
					disableOptimization: true,
					nextButton: true,
					userInteraction: true,
				},
				uiElement: 'map'
			},
			{
				text: {
					en: "IGNORE THIS TEXT",
					de: "IGNORE THIS TEXT",
					fr: "IGNORE THIS TEXT"
				},
// actions: ['nextElement'],
				options: {
					resultText: true,
					timeOut: 2000,
					disableZoom: false,
					disablePanning: false,
					countryCode: 'BRA',
					zoom: 1.8,
					center: [-56, -16],
					annotations: [
						{
							delta: [60, 0],
							latlng: [-52, -9],
							textA: {de: 'Brasilien', en: 'Brazil', fr: 'Le Brésil'},
							className: 'big',
							result: true,
							fontSize: [40, 20],
							dataSrc: -1,
						},
					],
					results: true,
					nextButton: true,
				},
				uiElement: 'map'
			},
			{
				options: {
					amount: 32,
					color: COLOURS.jaune,
					timeOut: 3200,
					icon: 'cotton-jaune'
				},
				actions: ['clearWorld', 'addCircles'],
				text: {
					en: 'Every year <span className="text-jaune">Brazil</span> exports 800,000 tonnes of cotton. Each of these symbols represents approximately 25,000 tonnes of cotton.',
					de: "<span className='text-jaune'>Brasilien</span> exportiert jährlich rund 800'000 Tonnen Baumwolle. Jede dieser 32 Kapseln steht somit für rund 25'000 Tonnen Baumwolle. ",
					fr: "<span className='text-jaune'>Le Brésil</span> exporte chaque année 800'000 de tonnes de coton environ. Chacun de ces 32 symboles représente environ 25'000 tonnes de coton."
				},
				uiElement: 'redbox',
			},
			{
				options: {
					amount: 35,
					color: COLOURS.mer,
					timeOut: 2000,
					icon: 'cotton-mer'
				},
				actions: ['addCircles'],
				text: {
					en: "Slightly more is exported by <span className='text-mer'>India</span>, namely 870,000 tonnes.",
					de: "Noch etwas mehr exportiert <span className='text-mer'>Indien</span>, nämlich 870'000 Tonnen.",
					fr: "<span className='text-mer'>L'Inde</span> en exporte un peu plus: 870'000 tonnes."
				},
				uiElement: 'redbox',
			},
			{
				options: {
					amount: 99,
					color: COLOURS.blue,
					timeOut: 3400,
					icon: 'cotton-blue'
				},
				actions: ['addCircles'],
				text: {
					en: "By far the biggest cotton exporter is <span className='text-blue'>the US</span> with 2.5 million tonnes.",
					de: "Der weltgrösste Exporteur sind die <span className='text-blue'>USA</span> mit knapp 2,5 Millionen Tonnen.",
					fr: "<span className='text-blue'>Les États-Unis</span> sont en troisième position, avec 2,5 millions de tonnes de coton environ."
				},
				uiElement: 'redbox',
			},
			{
				options: {
					amount: 109,
					color: COLOURS.grey,
					timeOut: 4000,
					icon: 'cotton-brown'
				},
				actions: ['addCircles'],
				text: {
					en: "In total, <span className='red'>6.9 million tonnes of cotton</span> were traded on the global market .",
					de: "Insgesamt wurden <span className='red'>6,9 Millionen Tonnen Baumwolle</span>  auf dem Weltmarkt gehandelt.",
					fr: "En tout, <span className='red'>6,9 millions de tonnes de coton</span> ont été négociées sur le marché mondial en 2016."
				},
				uiElement: 'redbox',
			},
			{
				options: {
					timeOut: 3000,
					colours: [
						{
							colour: COLOURS.red,
							value: 100
						}
					],
					direction: 'toIcon',
					icon: 'cotton-red'
				},
				actions: ['iconToggle'],
				text: {
					en: "<span className='red'>What does that have to do with Switzerland? </span> <br />Switzerland is heavily involved in global agricultural trading.",
					de: "<span className='red'>Was hat die Schweiz damit zu tun?</span> <br />Die Schweiz mischt im globalen Agrarhandel kräftig mit.",
					fr: "<span className='red'>Quel est le rapport avec la Suisse?</span> <br />La Suisse joue un rôle central dans le négoce mondial de matières premières agricoles."
				},
				uiElement: 'redbox',
			},
			{
				amount: 0,
				options: {
					nextResult: 25,
					nextButton: true,
					solutionButton: true
				},
				text: {
					en: "What do you think? <span className='red'>What is Switzerland’s market share</span> in global cotton trade?",
					de: "Was denken Sie: <span className='red'>Welcher Anteil </span>des globalen Baumwoll-Handels wird über die Schweiz abgewickelt?",
					fr: "À votre avis, <span className='red'>quel pourcentage </span>du négoce mondial de coton est réalisé via la Suisse?"
				},
				uiElement: 'slider',
			},
			{
				amount: 0,
				actions: ['gravityToggle'],
				options: {
					timeOut: 2000,
					gravityFree: 25,
					resultText: true,
				},
				text: {
					en: "IGNORE",
					de: "IGNORE",
					fr: "IGNORE"
				},
				uiElement: 'redbox',
			},
			{
				amount: 0,
				actions: [''],
				text: {
					en: "This makes Switzerland <span className='red'>one of the biggest</span> trading hubs for cotton.",
					de: "<span className='red'>Die Schweiz</span> ist somit global  <span className='red'>einer der wichtigsten Handelsplätze</span> für Baumwolle. ",
					fr: "<span className='red'>La Suisse</span> est ainsi l’une des principales places de négoce de coton au monde."
				},
				options: {
					timeOut: 2500,
					backToStartButton: false,
					nextButton: true
				},
				uiElement: 'redbox',
			},
		]
	},
	{
		id: 'storyline2',
		elements: [
			{
				options: {
					content: 'traders',
					rows: 3,
					columns: 5,
					guessAmount: -1,
					nextButton: true,
					quiz: false,
					isDark: true,
					nextResult: ['ldc', 'olam', 'cargil', 'ecom', 'cofco', 'glencore', 'reinhart'],
				},
				text: {
					en: 'Some of the most powerful agricultural traders are based in <span className="red">Switzerland</span>. Few people know these companies – <span className="red">do you</span>?',
					de: 'In der <span className="red">Schweiz</span> sind einige der mächtigsten globalen Agrarhändler beheimatet. <span className="red">Kaum jemand kennt diese Firmen</span> – und Sie?',
					fr: "Plusieurs négociants de matières premières agricoles figurant parmi les plus puissants au monde ont leur siège <span className='red'>en Suisse</span>. Mais beaucoup de gens l'ignorent. Et vous, <span className='red'>connaissez-vous les noms de ces sociétés?</span>"
				},
				uiElement: 'grid',
			},
			{
				options: {
					content: 'traders',
					rows: 3,
					columns: 5,
					guessAmount: 2,
					nextButton: false,
					quiz: true,
					nextResult: ['ldc', 'olam', 'cargil', 'ecom', 'cofco', 'glencore', 'reinhart'],
				},
				text: {
					en: 'Do you know which of these 15 powerful companies trade large quantities of <span className="red">cotton</span>? Select<span className="red"> two firms</span> and click on continue.',
					de: 'Wissen Sie, welche dieser 15 mächtigen Unternehmen in grossem Stil mit <span className="red">Baumwolle</span> handeln? Wählen Sie <span className="red">zwei Firmen</span> aus und klicken Sie auf Weiter</span>, um die Auflösung zu sehen.',
					fr: "Parmi les 15 sociétés suivantes, lesquelles sont actives dans le négoce de <span className='red'>coton</span>? Choisissez <span className='red'>deux sociétés </span> et cliquez sur «suivant»."
				},
				uiElement: 'grid',
			},
			{
				actions: ['stopGuessListener'],
				options: {
					content: 'traders',
					rows: 3,
					columns: 5,
					nextButton: true,
					guessAmount: -1,
					resultText: true,
					timeOut: 0
				},
				text: {
					en: 'IGNORE THIS TEXT',
					de: 'IGNORE THIS TEXT',
					fr: "IGNORE THIS TEXT"
				},
				uiElement: 'grid',
			},
			{
				options: {
					nextButton: true,
					infographic: 'olam.png',
					fadeIn: true
				},
				text: {
					en: 'These companies dominate not only the global trade – their influence over the<span className="red"> entire value chain </span>is constantly growing. <br/>Let’s look at <span className="fat">Olam</span> as an example.',
					de: 'Diese <span className="red">Schweizer Firmen</span> dominieren nicht nur den globalen Handel – auch ihr Einfluss auf die <span className="red">gesamte Wertschöpfungskette</span> wächst laufend. Nehmen wir zum Beispiel <span className="fat">Olam</span>.',
					fr: "Ces sociétés ne dominent pas seulement le négoce mondial de matières premières agricoles: leur influence s'étend sur <span className='red'>l’ensemble de la chaînede création de valeur</span> – et elle ne cesse de croître. Prenons l'exemple de la société <span className='red'>Olam</span>."
				},
				uiElement: 'graphic',
			},
			{
				actions: ['clearWorld'],
				options: {
					infographic: 'cotton.png',
					nextButton: true,
				},
				text: {
					en: 'Olam – <span className="red">one of the biggest cotton traders</span> – is involved in the entire value chain, from plantation to spinning to risk management.',
					de: 'Olam – <span className="red">einer der wichtigsten Akteure im Baumwollhandel</span> – mischt in der ganzen Wertschöpfungskette mit, vom Anbau über das Spinnen bis zum Riskmanagement.',
					fr: "Olam est <span className='red'>l’un des principaux acteurs dans le négoce de coton</span>. Il intervient sur toute la chaîne de création de valeur, de la culture au tissage, en passant même par la gestion des risques."
				},
				uiElement: 'graphic',
			},
			{
				options: {
					goToProblems: true,
					color: COLOURS.red,
					timeOut: 2500,
					companyIcon: 'olam.png',
					elements: [
						{
							icon: 'warehouse',
							amount: 25,
							color: COLOURS.red
						},
					],
				},
				actions: ['addIcons'],
				text: {
					en: 'Olam also <span className="red">owns storage infrastructure</span> in the US, Australia, Côte d\'Ivoire, Mozambique and Tanzania.',
					de: 'Olam hält auch <span className="red">Lagerinfrastruktur</span> in den USA, Australien, Côte d\'Ivoire, Mosambik und Tansania.',
					fr: "Olam possède également <span className='red'>des entrepôts</span> aux États-Unis, en Australie, en Côte d'Ivoire, au Mozambique et en Tanzanie."
				},
				uiElement: 'redbox',
			},
		]
	},
]
},
{
	commodity: 'Soy',
		chapter: 0,
	pages: [
	{
		actions: ['clearWorld'],
		elements: [
			{
				options: {
					content: 'commodities',
					columns: 4,
					rows: 2,
					nextButton: true,
					guessAmount: 1,
				},
				text: {
					de: 'Wählen Sie einen Agrarrohstoff.',
					en: 'Choose a soft commodity.',
					fr: 'Choisissez une matière première agricole.'
				},
				uiElement: 'grid',
			},
			{
				text: {
					en: "<span className='red'>Paraguay</span> is one of the world’s biggest soy exporters. Do you know where Paraguay is? Click on the right country.</span>",
					de: "<span className='red'>Paraguay</span> ist einer der grössten Soja-Exporteure weltweit. Paraguay? Wissen Sie wo das liegt? Klicken Sie auf das Land, um Ihren Tipp abzugeben.</span>",
					fr: "<span className='red'>Le Paraguay</span> est l’un des plus grands exportateurs de soja au monde. Où se situe le Paraguay? Cliquez sur le bon pays."
				},
				actions: ['nextElement'],
				options: {
					center: [0, 20],
					zoom: 1,
					disableZoom: true,
					disablePanning: true,
					timeOut: 3200
				},
				uiElement: 'map'
			},
			{
				text: {
					en: "<span className='red'>Paraguay</span> is one of the world’s biggest soy exporters. Do you know where Paraguay is? <span className='red blink-1'> Click on the right country.</span>",
					de: "<span className='red'>Paraguay</span> ist einer der grössten Soja-Exporteure weltweit. Paraguay? Wissen Sie wo das liegt? <span className='red blink-1'>Klicken Sie auf das Land, um Ihren Tipp abzugeben.</span>",
					fr: "<span className='red'>Le Paraguay</span> est l’un des plus grands exportateurs de soja au monde. Où se situe le Paraguay? <span className='red blink-1'>Cliquez sur le bon pays.</span>"
				},
				ations: ['clearWorld'],
				options: {
					countryCode: 'PRY',
					zoom: 2.5,
					center: [-56, -16],
					disableZoom: true,
					disablePanning: true,
					disableOptimization: true,
					nextButton: true,
					userInteraction: true,
				},
				uiElement: 'map'
			},
			{
				text: {
					en: "IGNORE THIS TEXT",
					de: "IGNORE THIS TEXT",
					fr: "IGNORE THIS TEXT"
				},
// actions: ['nextElement'],
				options: {
					resultText: true,
					timeOut: 2000,
					disableZoom: false,
					disablePanning: false,
					countryCode: 'PRY',
					zoom: 2.5,
					center: [-56, -16],
					annotations: [
						{
							delta: [60, 0],
							latlng: [-58.2, -22],
							textA: {de: 'Paraguay', en: 'Paraguay', fr: 'Le Paraguay'},
							className: 'big',
							result: true,
							fontSize: [20, 10],
							dataSrc: -1,
						},
					],
					results: true,
					nextButton: true,
				},
				uiElement: 'map'
			},
			{
				options: {
					amount: 6,
					color: COLOURS.mer,
					timeOut: 1500,
					icon: 'soy-mer'
				},
				actions: ['clearWorld', 'addCircles'],
				text: {
					en: 'Every year <span className="text-mer">Paraguay</span> exports 6 million tonnes of soy. Each of these symbols represents approx. 1 million tonnes of soy.',
					de: "<span className='text-mer'>Paraguay</span> exportiert jährlich rund 6 Millionen Tonnen Soja. Jede dieser Bohnen steht somit für rund 1 Million Tonnen Soja. ",
					fr: "<span className='text-mer'>Le Paraguay</span> exporte chaque année 6 millions de tonnes de soja environ. Chacun de ces grains représente environ 1 million de tonnes de soja."
				},
				uiElement: 'redbox',
			},
			{
				options: {
					amount: 46,
					color: COLOURS.jaune,
					timeOut: 2000,
					icon: 'soy-jaune'
				},
				actions: ['addCircles'],
				text: {
					en: "Two countries export even more, a lot more: <span className='text-jaune'>the US</span> exports 46 million tonnes...",
					de: "Zwei Länder exportieren aber noch mehr - deutlich mehr: <span className='text-jaune'>Die USA</span> exportieren 46 Millionen Tonnen...",
					fr: "Deux pays en exportent encore plus. Et même nettement plus! <span className='text-jaune'>Les États-Unis</span> exportent 46 millions de tonnes..."
				},
				uiElement: 'redbox',
			},
			{
				options: {
					amount: 84,
					color: COLOURS.violet,
					timeOut: 2500,
					icon: 'soy-brown'
				},
				actions: ['addCircles'],
				text: {
					en: "….and <span className='text-violet'>Brazil</span> exports as much as 84 million tonnes.",
					de: "... und <span className='text-violet'>Brasilien</span> sogar 84 Millionen Tonnen.",
					fr: "… et <span className='text-violet'>le Brésil</span> 84 millions de tonnes!"
				},
				uiElement: 'redbox',
			},
			{
				options: {
					amount: 16,
					color: COLOURS.grey,
					timeOut: 1800,
					icon: 'soy-blue'
				},
				actions: ['addCircles'],
				text: {
					en: "In total, 150 million tonnes of soy were traded on the global market in 2018. That quantity would fill <span className='red'>a freight train reaching around the world.</span>",
					de: "Insgesamt wurden 2018 über 150 Millionen Tonnen Soja auf dem Weltmarkt gehandelt. Ein Güterzug mit dieser Ladung würde <span className='red'>rund um die Erde</span> reichen.",
					fr: "En tout, 150 millions de tonnes de soja ont été négociées sur le marché mondial en 2018. Un train de marchandises transportant une telle cargaison ferait <span className='red'>le tour de la terre</span>!"
				},
				uiElement: 'redbox',
			},
			{
				options: {
					timeOut: 2000,
					colours: [
						{
							colour: COLOURS.red,
							value: 100
						}
					],
					direction: 'toIcon',
					icon: 'soy-red'
				},
				actions: ['iconToggle'],
				text: {
					en: "<span className='red'>What does that have to do with Switzerland? </span> <br />Switzerland is heavily involved in global agricultural trading.",
					de: "<span className='red'>Was hat die Schweiz damit zu tun?</span> <br />Die Schweiz mischt im globalen Agrarhandel kräftig mit.",
					fr: "<span className='red'>Quel est le rapport avec la Suisse?</span> <br />La Suisse joue un rôle central dans le négoce mondial de matières premières agricoles."
				},
				uiElement: 'redbox',
			},
			{
				amount: 0,
				options: {
					nextResult: 30,
					nextButton: true,
					solutionButton: true
				},
				text: {
					en: "What do you think? <span className='red'>What is Switzerland’s market share</span> in global soy trade?",
					de: "Was denken Sie: <span className='red'>Welcher Anteil </span>des globalen Sojahandels wird über die Schweiz abgewickelt?",
					fr: "À votre avis, <span className='red'>quel pourcentage </span>du négoce mondial de soja est réalisé via la Suisse?"
				},
				uiElement: 'slider',
			},
			{
				amount: 0,
				actions: ['gravityToggle', 'specialCase'],
				options: {
					timeOut: 2000,
					nextButton: true,
					gravityFree: 0,
					resultText: false,
				},
				text: {
					en: "<span className='red'>You might be right</span> – as the agricultural sector is so opaque, we were unable to find out precisely what percentage of global transactions are processed in Switzerland...",
					de: "<span className='red'>Vielleicht haben Sie recht</span> - da der Agrarhandelssektor äusserst intransparent ist, konnten wir zu diesem Produkt den Schweizer Anteil am globalen Handel nicht eruieren.",
					fr: "<span className='red'>Peut-être bien...</span> Le secteur du négoce agricole est si opaque que nous n’avons pas pu déterminer le pourcentage précis de soja négocié depuis la Suisse."
				},
				uiElement: 'redbox',
			},
			{
				amount: 0,
				actions: [''],
				text: {
					en: "... even though soy is the most-traded soft commodity worldwide in terms of value.",
					de: "Dies trotz der Tatsache, dass Soja der gobal wichtigste Agrarrohstoff ist (gemessen am Handelswert).",
					fr: "Et pourtant, le soja est la matière première agricole la plus négociée au monde (en valeur)."
				},
				options: {
					timeOut: 2500,
					backToStartButton: false,
					nextButton: true
				},
				uiElement: 'redbox',
			},
		]
	},
	{
		id: 'storyline2',
		elements: [
			{
				options: {
					content: 'traders',
					rows: 3,
					columns: 5,
					guessAmount: -1,
					nextButton: true,
					quiz: false,
					isDark: true,
					nextResult: ['ldc', 'olam', 'ecom', 'cofco', 'volcafe', 'hkg', 'sucafina'],
				},
				text: {
					en: 'Some of the most powerful agricultural traders are based in <span className="red">Switzerland</span>. Few people know these companies – <span className="red">do you</span>?',
					de: 'In der <span className="red">Schweiz</span> sind einige der mächtigsten globalen Agrarhändler beheimatet. <span className="red">Kaum jemand kennt diese Firmen</span> – und Sie?',
					fr: "Plusieurs négociants de matières premières agricoles figurant parmi les plus puissants au monde ont leur siège <span className='red'>en Suisse</span>. Mais beaucoup de gens l'ignorent. Et vous, <span className='red'>connaissez-vous les noms de ces sociétés?</span>"
				},
				uiElement: 'grid',
			},
			{
				options: {
					content: 'traders',
					rows: 3,
					columns: 5,
					guessAmount: 2,
					nextButton: false,
					quiz: true,
					nextResult: ['ldc', 'olam', 'cargil', 'cofco', 'glencore', 'bunge', 'adm', 'cutrale'],
				},
				text: {
					en: 'Do you know which of these 15 powerful companies trade large quantities of <span className="red">soy</span>? Select<span className="red"> two firms</span> and click on continue.',
					de: 'Wissen Sie, welche dieser 15 mächtigen Unternehmen in grossem Stil mit <span className="red">Soja</span> handeln? Wählen Sie <span className="red">zwei Firmen</span> aus und klicken Sie auf Weiter</span>, um die Auflösung zu sehen.',
					fr: "Parmi les 15 sociétés suivantes, lesquelles sont actives dans le négoce de <span className='red'>soja</span>? Choisissez <span className='red'>deux sociétés </span> et cliquez sur «suivant»."
				},
				uiElement: 'grid',
			},
			{
				actions: ['stopGuessListener'],
				options: {
					content: 'traders',
					rows: 3,
					columns: 5,
					nextButton: true,
					guessAmount: -1,
					resultText: true,
					timeOut: 0
				},
				text: {
					en: 'IGNORE THIS TEXT',
					de: 'IGNORE THIS TEXT',
					fr: "IGNORE THIS TEXT"
				},
				uiElement: 'grid',
			},
			{
				options: {
					nextButton: true,
					infographic: 'cofco.png',
					fadeIn: true
				},
				text: {
					en: 'These companies dominate not only the global trade – their influence over the<span className="red"> entire value chain </span>is constantly growing. <br/>Let’s look at <span className="red">COFCO International</span> as an example.',
					de: "Diese <span className='red'>Schweizer Firmen</span> dominieren nicht nur den globalen Handel – auch ihr Einfluss auf die <span className='red'>gesamte Wertschöpfungskette</span> wächst laufend. Nehmen wir zum Beispiel <span className='red'>COFCO International</span>",
					fr: "Ces sociétés ne dominent pas seulement le négoce mondial de matières premières agricoles: leur influence s'étend sur <span className='red'>l’ensemble de la chaîne de création de valeur</span> – et elle ne cesse de croître. Prenons l'exemple de <span className='red'>COFCO International</span>."
				},
				uiElement: 'graphic',
			},
			{
				actions: ['clearWorld'],
				options: {
					infographic: 'soy.png',
					nextButton: true,
				},
				text: {
					en: 'COFCO leases <span className="red">22,000 ha of land</span> in Argentina and runs contract soy farms in South Africa, covering a surface area as big as the canton of Geneva.',
					de: 'COFCO pachtet <span className="red">22\'000 ha Land</span> in Argentinien und betreibt in Südafrika Vertragsanbau für Soja auf einer Fläche von der Grösse des Kantons Genf.',
					fr: "COFCO loue <span className='red'>22'000 hectares</span> de terres en Argentine. Elle exploite également des cultures de soja en Afrique du Sud, sur une surface de la taille du canton de Genève."
				},
				uiElement: 'graphic',
			},
			{
				options: {
					goToProblems: true,
					color: COLOURS.red,
					timeOut: 2500,
					companyIcon: 'cofco.png',
					elements: [
						{
							icon: 'boat',
							amount: 200,
							color: COLOURS.mer
						}
					],
				},
				actions: ['addIcons'],
				text: {
					en: 'With 200 ships travelling at the same time, <span className="red">COFCO </span> operates one of the biggest fleets of all agricultural traders.',
					de: 'Mit 200 Schiffen, die jeweils gleichzeitig unterwegs sind, betreibt <span className="red">COFCO</span> zudem eine der grössten Flotten aller Agrarhändler.',
					fr: "Avec 200 bateaux constamment en mer, <span className='red'> COFCO</span> possède l'une des plus grandes flottes de tous les négociants agricoles."
				},
				uiElement: 'redbox',
			},
		]
	},
]
},
{
	commodity: 'Bananas',
		chapter: 0,
	pages: [
	{
		actions: ['clearWorld'],
		elements: [
			{
				options: {
					content: 'commodities',
					columns: 4,
					rows: 2,
					nextButton: true,
					guessAmount: 1,
				},
				text: {
					de: 'Wählen Sie einen Agrarrohstoff.',
					en: 'Choose a soft commodity.',
					fr: 'Choisissez une matière première agricole.'
				},
				uiElement: 'grid',
			},
			{
				text: {
					en: "<span className='red'>Ecuador</span> is the world’s biggest banana exporter. Do you know where Ecuador is? Click on the right country.</span>",
					de: "<span className='red'>Ecuador</span> ist der grösste Bananen-Exporteur weltweit. Ecuador? Wissen Sie wo das liegt? Klicken Sie auf das Land, um Ihren Tipp abzugeben.</span>",
					fr: "<span className='red'>L’Équateur</span> est le premier exportateur de bananes au monde. Où se situe l’Équateur? Cliquez sur le bon pays."
				},
				actions: ['nextElement'],
				options: {
					center: [0, 20],
					zoom: 1,
					disableZoom: true,
					disablePanning: true,
					timeOut: 3200
				},
				uiElement: 'map'
			},
			{
				text: {
					en: "<span className='red'>Ecuador</span> is the world’s biggest banana exporter. Do you know where Ecuador is? <span className='red blink-1'> Click on the right country.</span>",
					de: "<span className='red'>Ecuador</span> ist der grösste Bananen-Exporteur weltweit. Ecuador? Wissen Sie wo das liegt? <span className='red blink-1'>Klicken Sie auf das Land, um Ihren Tipp abzugeben.</span>",
					fr: "<span className='red'>L’Équateur</span> est le premier exportateur de bananes au monde. Où se situe l’Équateur? <span className='red blink-1'>Cliquez sur le bon pays.</span>"
				},
				ations: ['clearWorld'],
				options: {
					countryCode: 'ECU',
					zoom: 2.5,
					center: [-56, -16],
					disableZoom: true,
					disablePanning: true,
					disableOptimization: true,
					nextButton: true,
					userInteraction: true,
				},
				uiElement: 'map'
			},
			{
				text: {
					en: "IGNORE THIS TEXT",
					de: "IGNORE THIS TEXT",
					fr: "IGNORE THIS TEXT"
				},
// actions: ['nextElement'],
				options: {
					resultText: true,
					timeOut: 2000,
					disableZoom: false,
					disablePanning: false,
					countryCode: 'ECU',
					zoom: 2.5,
					center: [-56, -16],
					annotations: [
						{
							delta: [60, 0],
							latlng: [-78.75, -1.35],
							textA: {de: 'Ecuador', en: 'Ecuador', fr: 'L’Équateur'},
							className: 'big',
							result: true,
							fontSize: [20, 10],
							dataSrc: -1,
						},
					],
					results: true,
					nextButton: true,
				},
				uiElement: 'map'
			},
			{
				options: {
					amount: 66,
					color: COLOURS.mer,
					timeOut: 2800,
					icon: 'banana-mer'
				},
				actions: ['clearWorld', 'addCircles'],
				text: {
					en: 'Every year <span className="text-mer">Ecuador</span> exports 6.6 million tonnes of bananas. Each of these 66 bananas represents approximately 100,000 tonnes.',
					de: "<span className='text-mer'>Ecuador</span> exportiert jährlich rund 6,6 Millionen Tonnen Bananen. Jede dieser 66 Bananen steht somit für rund 100'000 Tonnen. ",
					fr: "<span className='text-mer'>L’Équateur</span> exporte chaque année 6,6 millions de tonnes de bananes environ. Chacune de ces 66 bananes représente environ 100'000 tonnes de bananes."
				},
				uiElement: 'redbox',
			},
			{
				options: {
					amount: 34,
					color: COLOURS.jaune,
					timeOut: 2000,
					icon: 'banana-jaune'
				},
				actions: ['addCircles'],
				text: {
					en: "<span className='text-jaune'>The Philippines</span> exports some 3.4 million tonnes of bananas a year...",
					de: "<span className='text-jaune'>Die Philippinen</span> exportieren rund 3,4 Millionen Tonnen Bananen pro Jahr...",
					fr: "<span className='text-jaune'>Les Philippines</span> exportent 3,4 millions de tonnes de bananes par an..."
				},
				uiElement: 'redbox',
			},
			{
				options: {
					amount: 25,
					color: COLOURS.violet,
					timeOut: 2000,
					icon: 'banana-brown'
				},
				actions: ['addCircles'],
				text: {
					en: "... and <span className='text-violet'>Costa Rica</span> some 2.5 million tonnes.",
					de: "... und <span className='text-violet'>Costa Rica</span> etwa 2,5 Millionen Tonnen. ",
					fr: "... et <span className='text-violet'>le Costa Rica</span> 2,5 millions de tonnes environ."
				},
				uiElement: 'redbox',
			},
			{
				options: {
					amount: 119,
					color: COLOURS.grey,
					timeOut: 3500,
					icon: 'banana-vert'
				},
				actions: ['addCircles'],
				text: {
					en: "In total, a good 24 million tonnes of bananas were traded on the global market in 2018. That quantity would fill a freight train reaching from <span className='red'>St. Gallen to Mumbai</span>.",
					de: "Insgesamt wurden 2018 gut 24 Millionen Tonnen Bananen auf dem Weltmarkt gehandelt. Ein Güterzug mit dieser Ladung würde von <span className='red'>St. Gallen bis Mumbai</span> reichen.",
					fr: "En tout, 24 millions de tonnes de bananes ont été négociées sur le marché mondial en 2018. Un train de marchandises transportant une telle cargaison serait aussi long que la distance qui sépare <span className='red'>Saint-Gall de Mumbai</span>."
				},
				uiElement: 'redbox',
			},
			{
				options: {
					timeOut: 2000,
					colours: [
						{
							colour: COLOURS.red,
							value: 100
						}
					],
					direction: 'toIcon',
					icon: 'banana-red'
				},
				actions: ['iconToggle'],
				text: {
					en: "<span className='red'>What does that have to do with Switzerland? </span> <br />Switzerland is heavily involved in global agricultural trading.",
					de: "<span className='red'>Was hat die Schweiz damit zu tun?</span> <br />Die Schweiz mischt im globalen Agrarhandel kräftig mit.",
					fr: "<span className='red'>Quel est le rapport avec la Suisse?</span> <br />La Suisse joue un rôle central dans le négoce mondial de matières premières agricoles."
				},
				uiElement: 'redbox',
			},
			{
				amount: 0,
				options: {
					nextResult: 30,
					nextButton: true,
					solutionButton: true
				},
				text: {
					en: "What do you think? <span className='red'>What is Switzerland’s market share</span> in global banana trade?",
					de: "Was denken Sie: <span className='red'>Welcher Anteil </span>des globalen Bananenhandels wird über die Schweiz abgewickelt?",
					fr: "À votre avis, <span className='red'>quel pourcentage </span>du négoce mondial de bananes est réalisé via la Suisse?"
				},
				uiElement: 'slider',
			},
			{
				amount: 0,
				actions: ['gravityToggle', 'specialCase'],
				options: {
					timeOut: 2000,
					nextButton: true,
					gravityFree: 0,
					resultText: false,
				},
				text: {
					en: "<span className='red'>You might be right</span> – as the agricultural sector is so opaque we were unable to find out what percentage of global transactions are processed in Switzerland...",
					de: "<span className='red'>Vielleicht haben Sie recht</span> - da der Agrarhandelssektor äusserst intransparent ist, konnten wir zu diesem Produkt den Schweizer Anteil am globalen Handel nicht eruieren.",
					fr: "<span className='red'>Peut-être bien...</span> Le secteur du négoce des matières premières agricoles est si opaque que nous n’avons pas pu déterminer le pourcentage précis de bananes négocié depuis la Suisse."
				},
				uiElement: 'redbox',
			},
			{
				amount: 0,
				actions: [''],
				text: {
					en: "We assume that the percentage is high, because one of the biggest, if not the biggest banana trader in the world conducts part of its <span className='fat'>trading in Switzerland</span>.",
					de: "Wir gehen davon aus, dass der Anteil beträchtlich ist, denn einer der grössten (wenn nicht der grösste) Bananenhändler weltweit ist <span className='fat'>in der Schweiz</span> beheimatet.",
					fr: "Mais tout porte à croire que ce pourcentage est considérable: l’un des principaux négociants de bananes au monde (si ce n'est pas le plus grand) est <span className='fat'>domicilié en Suisse</span>."
				},
				options: {
					timeOut: 2500,
					backToStartButton: false,
					nextButton: true
				},
				uiElement: 'redbox',
			},
		]
	},
	{
		id: 'storyline2',
		elements: [
			{
				options: {
					content: 'traders',
					rows: 3,
					columns: 5,
					guessAmount: -1,
					nextButton: true,
					quiz: false,
					isDark: true,
					nextResult: ['ldc', 'olam', 'ecom', 'cofco', 'volcafe', 'hkg', 'sucafina'],
				},
				text: {
					en: 'Many of the world\'s most powerful agricultural traders are based in <span className="red">Switzerland</span>. Few people know these companies – <span className="red">do you</span>?',
					de: 'Die <span className="red">Schweiz</span> beherbergt einige der mächtigsten globalen Agrarhändler. <span className="red">Kaum jemand kennt diese Firmen</span> – und Sie?',
					fr: "Plusieurs négociants de matières premières agricoles figurant parmi les plus puissants au monde ont leur siège <span className='red'>en Suisse</span>. Mais beaucoup de gens l'ignorent. Et vous, <span className='red'>connaissez-vous les noms de ces sociétés?</span>"
				},
				uiElement: 'grid',
			},
			{
				options: {
					content: 'traders',
					rows: 3,
					columns: 5,
					guessAmount: 1,
					nextButton: false,
					quiz: true,
					nextResult: ['chiquita'],
				},
				text: {
					en: 'Do you know which of these 15 powerful companies trade large quantities of <span className="red">bananas</span>? Select<span className="red"> a firm</span> and click on continue.',
					de: 'Wissen Sie, welches dieser 15 mächtigen Unternehmen in grossem Stil mit <span className="red">Bananen</span> handeln? Wählen Sie <span className="red">eine Firma</span> aus und klicken Sie auf Weiter</span>, um die Auflösung zu sehen.',
					fr: "Parmi les 15 sociétés suivantes, laquelle est active dans le négoce de <span className='red'>bananes</span>? Choisissez <span className='red'>une entreprise</span> et cliquez sur «suivant»."
				},
				uiElement: 'grid',
			},
			{
				actions: ['stopGuessListener'],
				options: {
					content: 'traders',
					rows: 3,
					columns: 5,
					nextButton: true,
					guessAmount: -1,
					resultText: true,
					timeOut: 0
				},
				text: {
					en: 'IGNORE THIS TEXT',
					de: 'IGNORE THIS TEXT',
					fr: "IGNORE THIS TEXT"
				},
				uiElement: 'grid',
			},
			{
				options: {
					nextButton: true,
					infographic: 'chiquita.png',
					fadeIn: true
				},
				text: {
					en: 'Chiquita not only dominates the global trade, its influence over the entire value chain is constantly growing.',
					de: 'Chiquita dominiert nicht nur den globalen Handel - das Unternehmen gewinnt immer mehr Einfluss auf die gesamte Wertschöpfungskette. ',
					fr: 'Chiquita ne domine pas seulement le négoce mondial de bananes: son influence s\'étend sur l’ensemble de la chaîne de création de valeur – et elle ne cesse de croître.'
				},
				uiElement: 'graphic',
			},
			{
				actions: ['clearWorld'],
				options: {
					infographic: 'bananas.png',
					nextButton: true,
				},
				text: {
					en: 'Chiquita owns and leases land to cultivate bananas in many of the largest export regions.',
					de: 'Chiquita besitzt und pachtet Land für den Bananenanbau in vielen der wichtigsten Exportregionen.',
					fr: "Chiquita possède des plantations et loue des terres pour la culture de bananes dans les principales régions d'exportation."
				},
				uiElement: 'graphic',
			},
			{
				options: {
					goToProblems: true,
					color: COLOURS.red,
					timeOut: 2500,
					companyIcon: 'chiquita.png',
					elements: [
						{
							icon: 'boat',
							amount: 7,
							color: COLOURS.red
						},
						{
							icon: 'factory',
							amount: 3,
							color: COLOURS.blue
						},
						{
							icon: 'container',
							amount: 8,
							color: COLOURS.mer
						},
						{
							icon: 'warehouse',
							amount: 4,
							color: COLOURS.jaune
						},
					],
				},
				actions: ['addIcons'],
				text: {
					en: 'Chiquita also has storage facilities, warehouse capacity and port loading stations – as well as 10 chartered seagoing vessels.',
					de: 'Chiquita verfügt zudem über Verpackungsstationen, Lagerkapazitäten sowie Verladestationen an Häfen und hat 10 Hochseeschiffe unter Vertrag.',
					fr: "Chiquita possède également des centres d’emballage, des entrepôts, des stations de chargement dans des ports et 10 paquebots sous contrat."
				},
				uiElement: 'redbox',
			},
		]
	},
]
},
{
	commodity: 'Juice',
		chapter: 0,
	pages: [
	{
		actions: ['clearWorld'],
		elements: [
			{
				options: {
					content: 'commodities',
					columns: 4,
					rows: 2,
					nextButton: true,
					guessAmount: 1,
				},
				text: {
					de: 'Wählen Sie einen Agrarrohstoff.',
					en: 'Choose a soft commodity.',
					fr: 'Choisissez une matière première agricole.'
				},
				uiElement: 'grid',
			},
			{
				text: {
					en: "<span className='red'>Brazil</span>  is by far the world’s biggest orange juice exporter. Do you know where Brazil is? Click on the right country.</span>",
					de: "<span className='red'>Brasilien</span>  ist der mit Abstand grösste Exporteur von Orangensaft weltweit. Wissen Sie wo Brasilien liegt? Klicken Sie auf das Land, um Ihren Tipp abzugeben.</span>",
					fr: "<span className='red'>Le Brésil</span> est de loin le premier exportateur de jus d’orange au monde. Où se situe le Brésil? Cliquez sur le bon pays."
				},
				actions: ['nextElement'],
				options: {
					center: [0, 20],
					zoom: 1,
					disableZoom: true,
					disablePanning: true,
					timeOut: 3200
				},
				uiElement: 'map'
			},
			{
				text: {
					en: "<span className='red'>Brazil</span>  is by far the world’s biggest orange juice exporter. Do you know where Brazil is? <span className='red blink-1'> Click on the right country.</span>",
					de: "<span className='red'>Brasilien</span>  ist der mit Abstand grösste Exporteur von Orangensaft weltweit. Wissen Sie wo Brasilien liegt? <span className='red blink-1'>Klicken Sie auf das Land, um Ihren Tipp abzugeben.</span>",
					fr: "<span className='red'>Le Brésil</span> est de loin le premier exportateur de jus d’orange au monde. Où se situe le Brésil? <span className='red blink-1'>Cliquez sur le bon pays.</span>"
				},
				ations: ['clearWorld'],
				options: {
					countryCode: 'BRA',
					zoom: 1.8,
					center: [-56, -16],
					disableZoom: true,
					disablePanning: true,
					disableOptimization: true,
					nextButton: true,
					userInteraction: true,
				},
				uiElement: 'map'
			},
			{
				text: {
					en: "IGNORE THIS TEXT",
					de: "IGNORE THIS TEXT",
					fr: "IGNORE THIS TEXT"
				},
// actions: ['nextElement'],
				options: {
					resultText: true,
					timeOut: 2000,
					disableZoom: false,
					disablePanning: false,
					countryCode: 'BRA',
					zoom: 1.8,
					center: [-56, -16],
					annotations: [
						{
							delta: [60, 0],
							latlng: [-52, -9],
							textA: {de: 'Brasilien', en: 'Brazil', fr: 'Le Brésil'},
							className: 'big',
							result: true,
							fontSize: [20, 10],
							dataSrc: -1,
						},
					],
					results: true,
					nextButton: true,
				},
				uiElement: 'map'
			},
			{
				options: {
					amount: 260,
					color: COLOURS.mer,
					timeOut: 7000,
					icon: 'juice-mer'
				},
				actions: ['clearWorld', 'addCircles'],
				text: {
					en: 'Every year <span className="text-mer">Brazil</span> exports 1.3 million tonnes of orange juice concentrate. Each of these 260 bottles represents approximately 5,000 tonnes – enough to make 7 billion litres of orange juice.',
					de: "<span className='text-mer'>Brasilien</span> exportiert jährlich knapp 1,3 Millionen Tonnen Orangensaft-Konzentrat. Jede dieser 260 Flaschen steht für rund 5000 Tonnen Konzentrat - daraus lassen sich über 7 Milliarden Liter trinkfertiger Orangensaft herstellen. ",
					fr: "<span className='text-mer'>Le Brésil</span> exporte chaque année 1,3 million de tonnes de concentré de jus d’orange environ. Chacune de ces 260 bouteilles représente environ 5000 tonnes de concentré. De quoi produire 7 milliards de litres de jus d’orange!"
				},
				uiElement: 'redbox',
			},
			{
				options: {
					amount: 37,
					color: COLOURS.jaune,
					timeOut: 2000,
					icon: 'juice-jaune'
				},
				actions: ['addCircles'],
				text: {
					en: "The second biggest exporter is <span className='text-jaune'>Mexico</span>, with 184,000 tonnes of concentrate...",
					de: "Zweitgrösster Exporteur ist <span className='text-jaune'>Mexiko</span> mit 184'000 Tonnen Konzentrat...",
					fr: "<span className='text-jaune'>Le Mexique</span> est le deuxième plus grand exportateur de concentré, avec 184'000 tonnes..."
				},
				uiElement: 'redbox',
			},
			{
				options: {
					amount: 12,
					color: COLOURS.violet,
					timeOut: 2000,
					icon: 'juice-brown'
				},
				actions: ['addCircles'],
				text: {
					en: "... and <span className='text-violet'>the European Union</span> with 60,000 tonnes.",
					de: "... und <span className='text-violet'>die Europäische Union</span> mit 60'000 Tonnen Orangensaft-Konzentrat.",
					fr: "... puis vient <span className='text-violet'>l'Union européenne</span>, avec 60'000 tonnes de concentré."
				},
				uiElement: 'redbox',
			},
			{
				options: {
					amount: 11,
					color: COLOURS.blue,
					timeOut: 2500,
					icon: 'juice-blue'
				},
				actions: ['addCircles'],
				text: {
					en: "In total, <span className='red'>over 1.6 million tonnes</span> of orange juice concentrate were traded on the global market during 2018/2019.",
					de: "Insgesamt wurden im Erntejahr 2018/2019 <span className='red'>1,6 Millionen Tonnen</span> Orangensaft-Konzentrat auf dem Weltmarkt gehandelt. ",
					fr: "En tout, <span className='red'>1,6 million de tonnes</span> de concentré de jus d’orange ont été négociées sur le marché mondial durant la récolte de 2018/2019."
				},
				uiElement: 'redbox',
			},
			{
				options: {
					timeOut: 2000,
					colours: [
						{
							colour: COLOURS.red,
							value: 100
						}
					],
					direction: 'toIcon',
					icon: 'juice-red'
				},
				actions: ['iconToggle'],
				text: {
					en: "<span className='red'>What does that have to do with Switzerland? </span> <br />Switzerland is heavily involved in global agricultural trading.",
					de: "<span className='red'>Was hat die Schweiz damit zu tun?</span> <br />Die Schweiz mischt im globalen Agrarhandel kräftig mit.",
					fr: "<span className='red'>Quel est le rapport avec la Suisse?</span> <br />La Suisse joue un rôle central dans le négoce mondial de matières premières agricoles."
				},
				uiElement: 'redbox',
			},
			{
				amount: 0,
				options: {
					nextResult: 30,
					nextButton: true,
					solutionButton: true
				},
				text: {
					en: "What do you think? <span className='red'>What is Switzerland’s market share</span> in global orange juice trade?",
					de: "Was denken Sie: <span className='red'>Welcher Anteil </span>des globalen Orangensaft-Handels wird über die Schweiz abgewickelt?",
					fr: "À votre avis, <span className='red'>quel pourcentage </span>du négoce mondial de jus d'orange est réalisé via la Suisse?"
				},
				uiElement: 'slider',
			},
			{
				amount: 0,
				actions: ['gravityToggle', 'specialCase'],
				options: {
					timeOut: 2000,
					gravityFree: 0,
					nextButton: true,
					resultText: false,
				},
				text: {
					de: "<span className='red'>Vielleicht haben Sie recht</span> - da der Agrarhandelssektor äusserst intransparent ist, konnten wir zu diesem Produkt den Schweizer Anteil am globalen Handel nicht genau eruieren.",
					en: "<span className='red'>You might be right</span> – as the agricultural sector is so opaque, we were unable to find out precisely what percentage of global transactions are processed in Switzerland...",
					fr: "<span className='red'>Peut-être bien...</span> Le secteur du négoce des matières premières agricoles est si opaque que nous n’avons pas pu déterminer le pourcentage précis de jus d’orange négocié en Suisse."
				},
				uiElement: 'redbox',
			},
			{
				amount: 0,
				actions: [''],
				text: {
					en: "<span className='red'>We assume that the percentage is high</span>, because two of the three biggest orange juice traders in the world carry out their juice business from Switzerland or have important trading branches here.",
					de: "Wir gehen aber davon aus, dass der Anteil beträchtlich ist, denn <span className='red'>zwei der drei weltgrössten Orangensafthändler wickeln ihr Saftgeschäft über die Schweiz ab </span>oder haben zumindest wichtige Handelsniederlassungen hier.",
					fr: "Mais tout porte à croire <span className='red'>que ce pourcentage est considérable</span>: deux des trois plus grands négociants de jus d’orange au monde ont leur siège ou un important département de négoce en Suisse."
				},
				options: {
					timeOut: 2500,
					backToStartButton: false,
					nextButton: true
				},
				uiElement: 'redbox',
			},
		]
	},
	{
		id: 'storyline2',
		elements: [
			{
				options: {
					content: 'traders',
					rows: 3,
					columns: 5,
					guessAmount: -1,
					nextButton: true,
					quiz: false,
					isDark: true,
					nextResult: ['ldc', 'cutrale'],
				},
				text: {
					en: 'Many of the most powerful agricultural traders are based in <span className="red">Switzerland</span>. Few people know these companies – <span className="red">what about you</span>?',
					de: 'In der <span className="red">Schweiz</span> sind einige der mächtigsten globalen Agrarhändler beheimatet. <span className="red">Doch kaum jemand kennt diese Firmen</span> – und Sie?',
					fr: "Plusieurs négociants agricoles figurant parmi les plus puissants au monde sont domiciliés <span className='red'>en Suisse</span>. Mais beaucoup de gens l'ignorent. Et vous, <span className='red'>connaissez-vous les noms de ces sociétés?</span>"
				},
				uiElement: 'grid',
			},
			{
				options: {
					content: 'traders',
					rows: 3,
					columns: 5,
					guessAmount: 2,
					nextButton: false,
					quiz: true,
					nextResult: ['ldc', 'cutrale'],
				},
				text: {
					en: 'Do you know which of these 15 powerful companies trade large quantities of <span className="red">orange juice</span>? Select<span className="red"> two firms</span> and click on continue.',
					de: 'Wissen Sie, welche dieser 15 mächtigen Unternehmen in grossem Stil mit <span className="red">Orangensaft</span> handeln? Wählen Sie <span className="red">zwei Firmen</span> aus und klicken Sie auf Weiter</span>, um die Auflösung zu sehen.',
					fr: "Parmi ces 15 sociétés, lesquelles sont actives dans le négoce de <span className='red'>jus d'orange</span>? Choisissez <span className='red'>deux sociétés </span> et cliquez sur «suivant»."
				},
				uiElement: 'grid',
			},
			{
				actions: ['stopGuessListener'],
				options: {
					content: 'traders',
					rows: 3,
					columns: 5,
					nextButton: true,
					guessAmount: -1,
					resultText: true,
					timeOut: 0
				},
				text: {
					en: 'IGNORE THIS TEXT',
					de: 'IGNORE THIS TEXT',
					fr: "IGNORE THIS TEXT"
				},
				uiElement: 'grid',
			},
			{
				options: {
					nextButton: true,
					infographic: 'ldc.png',
					fadeIn: true
				},
				text: {
					en: 'These companies dominate not only the global trade – their influence over the<span className="red"> entire value chain </span>is constantly growing. <br/>Let’s look at <span className="red">Louis Dreyfus Company</span> as an example.',
					de: 'Diese <span className="red">Schweizer Firmen</span> dominieren nicht nur den globalen Handel – auch ihr Einfluss auf die <span className="red">gesamte Wertschöpfungskette</span> wächst laufend. Nehmen wir zum Beispiel <span className="red">Louis Dreyfus Company</span>.',
					fr: 'Ces sociétés ne dominent pas seulement le négoce mondial de matières premières agricoles: leur influence s\'étend sur <span className="red">l’ensemble de la chaîne de création de valeur</span> – et elle ne cesse de croître. Prenons par exemple <span className="red">le groupe Louis Dreyfus</span>.'
				},
				uiElement: 'graphic',
			},
			{
				actions: ['clearWorld'],
				options: {
					infographic: 'juice.png',
					nextButton: true,
				},
				text: {
					en: 'LDC is among the three largest orange juice traders worldwide. In Brazil, LDC owns <span className="red">38 plantations covering over 25\'000 hectares.</span> This equals the size of the canton of Geneva.',
					de: 'LDC gehört zu den drei grössten Orangensafthändlern der Welt. In Brasilien besitzt die Firma <span className="red">38 Plantagen mit einer Gesamtfläche von über 25\'000 Hektaren.</span> Das entspricht knapp der Fläche des Kantons Genf.',
					fr: "Louis Dreyfus est l'un des trois plus grands négociants de jus d’orange au monde. Le groupe détient <span className='red'>38 plantations au Brésil, couvrant plus de 25'000 hectares</span>. C'est l'équivalent du canton de Genève."
				},
				uiElement: 'graphic',
			},
			{
				options: {
					goToProblems: true,
					color: COLOURS.red,
					timeOut: 2500,
					companyIcon: 'ldc.png',
					elements: [
						{
							icon: 'factory',
							amount: 3,
							color: COLOURS.blue
						},
						{
							icon: 'boat',
							amount: 3,
							color: COLOURS.red
						},
						{
							icon: 'container',
							amount: 2,
							color: COLOURS.mer
						},
					],
				},
				actions: ['addIcons'],
				text: {
					en: 'Moreover, <span className="red">LDC</span> owns 3 processing plants in Brazil, 2 port terminals for storage and 3 ocean-going vessels for transporting juice.',
					de: '<span className="red">LDC</span> unterhält zudem 3 eigene Verarbeitungsanlagen in Brasilien, 2 Hafenterminals zur Lagerung sowie 3 Safttanker für den Übersee-Transport.',
					fr: "<span className='red'>Le groupe Louis Dreyfus</span> possède également 3 usines de transformation au Brésil, 2 entrepôts portuaires et 3 paquebots pour le transport maritime."
				},
				uiElement: 'redbox',
			},
		]
	},
]
},
{
	commodity: 'Problems',
		chapter: 7,
	pages: [
	{
		problem: 'Problems, Flipcard View',
		elements: [
			{
				options: {
					nextButton: false,
					rows: 3,
					columns: 3,
				},
				text: {
					de: 'Die Agrarhändler haben <span className="red">immer mehr Einfluss</span> auf die Produktionsbedingungen von Agrarrohstoffen. Sie sind heute mitverantwortlich für<span className="red"> Menschenrechtsverletzungen, Umweltzerstörung und Korruption</span> in diesem Sektor. ',
					en: 'These companies wield<span className="red"> increasing influence</span> over the conditions under which agricultural commodities are produced. They are increasingly responsible for <span className="red">human rights violations, environmental damage and corruption</span> in the sector.',
					fr: "Les négociants agricoles ont <span className='red'>de plus en plus d'influence </span>sur les conditions de production des matières premières agricoles. De ce fait, ils se rendent co-responsables des <span className='red'>violations de droits humains, des atteintes environnementales et de la corruption </span> qui gangrènent le secteur."
				},
				uiElement: 'flipcards',
			},
		]
	},
]
},
{
	commodity: 'Finish',
		chapter: 8,
	pages: [
	{
		elements: [
			{
				options: {
					nextButton: false,
					rows: 3,
					columns: 3,
				},
				text: {
					de: `<p>Vielen Dank für Ihre Aufmerksamkeit. Möchten Sie...</p><ul><li ref='backToStart'>...mit einem <span className="red link back-to-start-button"><i className='icon long arrow right'></i>anderen Rohstoff </span>neu starten?</li><li>...<a className="red link" href='https://www.publiceye.ch/agrarhandel' target='_blank'><i className='icon long arrow right'></i>mehr zum Thema </a>erfahren?</li><li>...unsere Recherchen <a className="red link" href='https://www.publiceye.ch/de/newsletter' target='_blank'><i className='icon long arrow right'></i>per E-Mail </a>erhalten?</li></ul>`,
					fr: `<p>Merci d’avoir participé! Souhaitez-vous....</p><ul><li ref='backToStart'>... recommencer avec une <span className="red link back-to-start-button"><i className='icon long arrow right'></i>autre matière première ?</span></li><li>... en savoir plus <a className="red link" href='https://www.publiceye.ch/fr/thematiques/negoce-agricole' target='_blank'><i className='icon long arrow right'></i>sur le négoce agricole?</a></li><li>... recevoir nos prochaines enquêtes <a className="red link" href='https://www.publiceye.ch/fr/newsletter' target='_blank'><i className='icon long arrow right'></i>par courrier électronique ?</a></li></ul>`,
					en: `<p>Thank you for your attention. Would you like to…</p><ul><li ref='backToStart'>… start again with <span className="red link back-to-start-button"><i className='icon long arrow right'></i>a different commodity?</span></li><li>… learn <a className="red link" href='https://www.publiceye.ch/en/topics/soft-commodity-trading' target='_blank'><i className='icon long arrow right'></i>more about the topic?</a></li><li>… receive our research <a className="red link" href='https://www.publiceye.ch/en/newsletter' target='_blank'><i className='icon long arrow right'></i>by email?</a></li></ul>`,
				},
				uiElement: 'flipcards',
			},
		]
	}
]
}
]

export default STORY