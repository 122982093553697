import React from 'react';
// import ReactDOM from 'react-dom';
import { hydrate, render } from "react-dom";
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import './fonts/fonts.scss';
import './scss/main.scss';
import * as serviceWorker from './serviceWorker';

// Import Firebase
import Firebase, { FirebaseContext } from './components/firebase'

// Import mobX
import Store from './store';
import { Provider } from 'mobx-react';
// import DevTools from 'mobx-react-devtools';

const store = new Store();

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {


	hydrate (
		<FirebaseContext.Provider value={new Firebase()}>
			<Provider store={store}>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</Provider>
		</FirebaseContext.Provider>,
		rootElement
	);
} else {
	render(
		<FirebaseContext.Provider value={new Firebase()}>
			<Provider store={store}>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</Provider>
		</FirebaseContext.Provider>,
		rootElement
	);
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
